// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.connectWalletModalWrapper .wallet-adapter-button {
  background: #FFFFFF;
  border: none;
  box-sizing: border-box;
  box-shadow: inset 0px 2px px #FFFFFF, inset 0px -4px 4px rgba(0, 48, 73, 0.25), inset 0px 0px 25px #FFFFFF;
  border-radius: 8px;
  font-family: Chakra Petch;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  font-size: 16px;
  padding: 10px 20px 10px 20px;
  height: unset;
  line-height: 22px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  text-transform: uppercase;
  color: #003049;
  margin-top: 2rem; }
  .connectWalletModalWrapper .wallet-adapter-button:hover {
    color: #48CAE4 !important;
    background: #FFFFFF !important; }
`, "",{"version":3,"sources":["webpack://./src/screens/Vault/VaultWallet.scss"],"names":[],"mappings":"AAAA;EACC,mBAAmB;EACnB,YAAY;EACZ,sBAAsB;EACtB,0GAA0G;EAC1G,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,4BAA4B;EAC5B,aAAa;EACb,iBAAiB;EACjB,2CAA2C;EAC3C,yBAAyB;EACzB,cAAc;EAKX,gBAAgB,EAAA;EAtBpB;IAmBE,yBAAyB;IACzB,8BAA8B,EAAA","sourcesContent":[".connectWalletModalWrapper .wallet-adapter-button {\r\n\tbackground: #FFFFFF;\r\n\tborder: none;\r\n\tbox-sizing: border-box;\r\n\tbox-shadow: inset 0px 2px px #FFFFFF, inset 0px -4px 4px rgba(0, 48, 73, 0.25), inset 0px 0px 25px #FFFFFF;\r\n\tborder-radius: 8px;\r\n\tfont-family: Chakra Petch;\r\n\tfont-style: normal;\r\n\tfont-weight: bold;\r\n\tfont-size: 18px;\r\n\ttext-align: center;\r\n\tfont-size: 16px;\r\n\tpadding: 10px 20px 10px 20px;\r\n\theight: unset;\r\n\tline-height: 22px;\r\n\tfont-feature-settings: 'pnum' on, 'lnum' on;\r\n\ttext-transform: uppercase;\r\n\tcolor: #003049;\r\n\t&:hover {\r\n\t\tcolor: #48CAE4 !important;\r\n\t\tbackground: #FFFFFF !important;\r\n\t}\r\n    margin-top: 2rem;\r\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
