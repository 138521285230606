import * as helpers from "./helper";
import moment from "moment/moment";
import BigNumber from "bignumber.js";
import {setShowCreateIdoAccount, setShowCreateAccount} from "../Components/Header/statistics";

import toast from "react-hot-toast";
import {errorToast, successToast} from "./toastCSS";
import {BN, web3} from "@project-serum/anchor";
import {SystemProgram, SYSVAR_RENT_PUBKEY} from "@solana/web3.js";
import {hasValue} from "./helperFunctions";
import {PRODUCTION, TBA_UNIX_DATE} from "../constants/constants";
import {Buffer} from "buffer";
import {ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID} from "@solana/spl-token";
import {getErrorMessage} from "../utils/utils";
import {getHydrazineMintAddress, getNodeIdoProgramId, getUsdcMintAddress} from "./helper";
import {IDO_NODE_NAME, PROGRAM_NAME} from "../screens/IdoDetails/IdoNodeSaleDetails";
import {NODE_FCFS_SALE_STAGE, NODE_OG_SALE_STAGE, NODE_STAKERS_SALE_STAGE} from "../screens/IdoDetails/IdoDetails";
import React from "react";

const EMPTY_POOL_HEX = "0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0";

export const IDO_NODE_SALE = 'Node Sale';
export const IDO_STAGE_UPCOMING = 'Upcoming';
export const IDO_STAGE_HYDRAZINE_CONTRIBUTION = 'Hydrazine Contribution';
export const IDO_STAGE_SETTING_ALLOCATION = 'Setting Allocations';
export const IDO_STAGE_USDC_CONTRIBUTION = 'USDC Contribution';
export const IDO_STAGE_SETTING_FCFS_ALLOCATION = 'Setting FCFS Allocations';
export const IDO_STAGE_FCFS_SALE = 'FCFS Sale';
export const IDO_STAGE_COMPLETED = 'Completed';
export const IDO_STAGE_CLAIM_OPEN = 'Claims Open';
export const IDO_STAGE_REFUNDS_OPEN = 'REFUNDS_OPEN';
export const IDO_STAGE_CLOSED = 'Closed';


export const getIdoTime = (stage, idoInfo) => {
  if (!hasValue(idoInfo)) return { start: 0, end: 0 };

  if (stage === IDO_STAGE_HYDRAZINE_CONTRIBUTION || stage === IDO_STAGE_UPCOMING) {
    return {
      start: idoInfo.registrationStartTime,
      end: idoInfo.registrationEndTime,
    }
  } else if (stage === IDO_STAGE_SETTING_ALLOCATION) {
    return {
      start: idoInfo.registrationStartTime,
      end: idoInfo.salesStartTime,
    }
  } else if (stage === IDO_STAGE_USDC_CONTRIBUTION) {
    return {
      start: idoInfo.salesStartTime,
      end: idoInfo.salesEndTime,
    }
  } else if (stage === IDO_STAGE_SETTING_FCFS_ALLOCATION) {
    return {
      start: idoInfo.salesEndTime,
      end: idoInfo.openSaleStartTime,
    }
  } else {
    return {
      start: hasValue(idoInfo?.openSaleStartTime) ? idoInfo.openSaleStartTime : idoInfo.salesStartTime ,
      end: hasValue(idoInfo?.openSaleEndTime) ? idoInfo.openSaleEndTime : idoInfo.salesEndTime,
    }
  }
}


export const getIdoTimesFromContract = (idoTimes, stage, idoInfo) => {
  //if (!hasValue(idoTimes) || TBA_UNIX_DATE > idoTimes?.registrationStart.toNumber()) {
  if (!hasValue(idoInfo)) return { start: 0, end: 0 };

  if (stage === IDO_STAGE_HYDRAZINE_CONTRIBUTION || stage === IDO_STAGE_UPCOMING) {
    return {
      start: idoInfo.registrationStartTime,
      end: idoInfo.registrationEndTime,
    }
  } else if (stage === IDO_STAGE_SETTING_ALLOCATION) {
    return {
      start: idoInfo.registrationStartTime,
      end: idoInfo.salesStartTime,
    }
  } else if (stage === IDO_STAGE_USDC_CONTRIBUTION) {
    return {
      start: idoInfo.salesStartTime,
      end: idoInfo.salesEndTime,
    }
  } else if (stage === IDO_STAGE_SETTING_FCFS_ALLOCATION) {
    return {
      start: idoInfo.salesEndTime,
      end: idoInfo.openSaleStartTime,
    }
  } else {
    return {
      start: hasValue(idoInfo?.openSaleStartTime) ? idoInfo.openSaleStartTime : idoInfo.salesStartTime ,
      end: hasValue(idoInfo?.openSaleEndTime) ? idoInfo.openSaleEndTime : idoInfo.salesEndTime,
    }
  }

//  }

  if (stage === IDO_STAGE_HYDRAZINE_CONTRIBUTION || stage === IDO_STAGE_UPCOMING) {
    return {
      start: idoTimes.registrationStart.toNumber(),
      end: idoTimes.registrationEnd.toNumber(),
    }
  } else if (stage === IDO_STAGE_SETTING_ALLOCATION) {
    return {
      start: idoTimes.registrationEnd.toNumber(),
      end: idoTimes.saleStart.toNumber(),
    }
  } else if (stage === IDO_STAGE_USDC_CONTRIBUTION) {
    return {
      start: idoTimes.saleStart.toNumber(),
      end: idoTimes.saleEnd.toNumber(),
    }
  } else if (stage === IDO_STAGE_SETTING_FCFS_ALLOCATION || stage === IDO_STAGE_FCFS_SALE) {
    return {
      start: idoTimes.saleEnd.toNumber(),
      end: idoTimes.openSaleStart.toNumber(),
    }
  } else {
    return {
      start: idoTimes.saleEnd.toNumber(),
      end: idoTimes.openSaleStart.toNumber(),
    }
  }
}

export const hasSaleEnded = ({ poolInfo, idoInfo}) => {
  if (!poolInfo && !idoInfo) return false;

  const curTime = moment().unix();
  let endTime;

  if (idoInfo?.isNodeSale) {
    endTime = Number(idoInfo?.nodeTiers?.fcfsTier?.endTime);
  } else {
    endTime = hasValue(poolInfo?.idoTimes?.saleEnd) ?
      poolInfo?.idoTimes?.saleEnd.toNumber() :
      idoInfo?.salesEndTime;
  }

  return curTime > endTime
}

export const getIdoNodeTime = (idoInfo, stage) => {
  if (!hasValue(idoInfo)) return { start: 0, end: 0};

  //TEMP Adding this until its properly added to the DB
  if (stage === NODE_OG_SALE_STAGE) {
    return {
      start: hasValue(idoInfo?.nodeTiers?.ogTier?.startTime) ? Number(idoInfo?.nodeTiers?.ogTier?.startTime) : 0,
      end: hasValue(idoInfo?.nodeTiers?.ogTier?.endTime) ? Number(idoInfo?.nodeTiers?.ogTier?.endTime) : 0,
    }
  }

  if (stage === NODE_STAKERS_SALE_STAGE) {
    return {
      start: hasValue(idoInfo?.nodeTiers?.stakerTier?.startTime) ? Number(idoInfo?.nodeTiers?.stakerTier?.startTime) : 0,
      end: hasValue(idoInfo?.nodeTiers?.stakerTier?.endTime) ? Number(idoInfo?.nodeTiers?.stakerTier?.endTime) : 0,
    }
  }

  if (stage === NODE_FCFS_SALE_STAGE) {
    return {
      start: hasValue(idoInfo?.nodeTiers?.fcfsTier?.startTime) ? Number(idoInfo?.nodeTiers?.fcfsTier?.startTime) : 0,
      end: hasValue(idoInfo?.nodeTiers?.fcfsTier?.endTime) ? Number(idoInfo?.nodeTiers?.fcfsTier?.endTime) : 0,
    }
  }

  return {
    start: hasValue(idoInfo.salesStartTime) ? Number(idoInfo.salesStartTime) : 0,
    end: hasValue(idoInfo.salesEndTime) ? Number(idoInfo.salesEndTime) : 0,
  }
}

export const getProgressBarInformation = (stage, poolInfo, idoInfo) => {
  if (stage === IDO_STAGE_HYDRAZINE_CONTRIBUTION) {
    const totalContributedHydrazine = getTotalHydrazineContributed(poolInfo, idoInfo);
    const maxHydrazine = getMaxHydrazine(poolInfo);
    //Due to rounding errors in the contract, added a check to se if 99.9% or more has been filled, if so return the max amount
    return {
      percentage: totalContributedHydrazine > maxHydrazine * 0.999 ?
        100 :
        (totalContributedHydrazine / maxHydrazine) * 100,
      label: 'TOTAL CONTRIBUTED HYDRAZINE',
      value: totalContributedHydrazine > (maxHydrazine * 0.999) ? maxHydrazine : totalContributedHydrazine,
      max: maxHydrazine,
    }
  } else if (stage === IDO_STAGE_SETTING_ALLOCATION) {
    return {
      percentage: parseFloat(new BigNumber(poolInfo.totalUsdc.toString()).times(new BigNumber(100)).div(new BigNumber(poolInfo.usdcAmount.toString())).toFormat(0)),
      label: 'POOL PURCHASED',
      value: parseFloat(new BigNumber(poolInfo.totalUsdc.toString()).div(new BigNumber(1000000)).toFixed(2)).toLocaleString(),
      max: parseFloat(new BigNumber(poolInfo.usdcAmount.toString()).div(new BigNumber(1000000)).toFixed(2)).toLocaleString(),
    }
  } else if (
    stage === IDO_STAGE_USDC_CONTRIBUTION || stage === IDO_STAGE_FCFS_SALE ||
    stage === IDO_STAGE_SETTING_FCFS_ALLOCATION || stage === IDO_STAGE_COMPLETED) {
    const totalContributedUSDC = getTotalContributedUSDC(poolInfo);
    const maxContributedUSDC = getMaxContributedUSDC(poolInfo);
    return {
      percentage: (totalContributedUSDC / maxContributedUSDC) * 100,
      label: 'POOL PURCHASED (USDC)',
      value: totalContributedUSDC > maxContributedUSDC * 0.999 ?
        maxContributedUSDC.toLocaleString() :
        totalContributedUSDC.toLocaleString(),
      max: maxContributedUSDC.toLocaleString(),
    }
  }

}

export const getNodeSaleStage = (idoInfo) => {
  const curTime = moment().unix();

  const ogStartTime = hasValue(idoInfo?.nodeTiers?.ogTier?.startTime) ? Number(idoInfo?.nodeTiers?.ogTier?.startTime) : 0;
  const ogEndTime = hasValue(idoInfo?.nodeTiers?.ogTier?.endTime) ? Number(idoInfo?.nodeTiers?.ogTier?.endTime) : 0;
  const stakerStartTime = hasValue(idoInfo?.nodeTiers?.stakerTier?.startTime) ? Number(idoInfo?.nodeTiers?.stakerTier?.startTime) : 0;
  const stakerEndTime = hasValue(idoInfo?.nodeTiers?.stakerTier?.endTime) ? Number(idoInfo?.nodeTiers?.stakerTier?.endTime) : 0
  const fcfsStartTime = hasValue(idoInfo?.nodeTiers?.fcfsTier?.startTime) ? Number(idoInfo?.nodeTiers?.fcfsTier?.startTime) : 0;
  const fcfsEndTime = hasValue(idoInfo?.nodeTiers?.fcfsTier?.endTime) ? Number(idoInfo?.nodeTiers?.fcfsTier?.endTime) : 0

  if (curTime > ogStartTime && curTime < ogEndTime) {
    return NODE_OG_SALE_STAGE;
  }
  if (curTime > stakerStartTime && curTime < stakerEndTime) {
    return NODE_STAKERS_SALE_STAGE;
  }
  if (curTime > fcfsStartTime && curTime < fcfsEndTime) {
    return NODE_FCFS_SALE_STAGE;
  }

  return '';
}

const getSaleStage = (poolInfo) => {
  const curTime = moment().unix();

  if (curTime < poolInfo.idoTimes.registrationStart.toNumber()) {
    return IDO_STAGE_UPCOMING;
  } else if (curTime >= poolInfo.idoTimes.registrationStart.toNumber() && curTime < poolInfo.idoTimes.registrationEnd.toNumber()) {
    return IDO_STAGE_HYDRAZINE_CONTRIBUTION;
  } else if (curTime >= poolInfo.idoTimes.registrationEnd.toNumber() && curTime < poolInfo.idoTimes.saleStart.toNumber()) {
    return IDO_STAGE_SETTING_ALLOCATION;
  } else if (curTime >= poolInfo.idoTimes.saleStart.toNumber() && curTime < poolInfo.idoTimes.saleEnd.toNumber()) {
    return IDO_STAGE_USDC_CONTRIBUTION;
  } else if (curTime >= poolInfo.idoTimes.saleEnd.toNumber() && curTime < poolInfo.idoTimes.openSaleStart.toNumber() && poolInfo.state === 2) {
    return IDO_STAGE_SETTING_FCFS_ALLOCATION;
  } else if (curTime >= poolInfo.idoTimes.saleEnd.toNumber() && curTime >= poolInfo.idoTimes.openSaleStart.toNumber() && poolInfo.state === 2) {
    return IDO_STAGE_FCFS_SALE;
  } else if (poolInfo.state === 3) {
    return IDO_STAGE_COMPLETED;
  } else if (poolInfo.state === 4) {
    return IDO_STAGE_CLAIM_OPEN;
  } else if (poolInfo.state === 6) {
    return IDO_STAGE_REFUNDS_OPEN;
  } else {
    return IDO_STAGE_CLOSED
  }
}

export const createCadetAccount = async (provider, connection, dispatch) => {
  try {
    const accts = await helpers.getAddys(provider);
    const cadetProgram = await helpers.getCadetProgram(provider);
    const transaction = new web3.Transaction();

    const [registrationAccount, registrationBump] = await web3.PublicKey.findProgramAddress(
      [
        helpers.getCadetSeedBuffer()
      ],
       helpers.getCadetProgramId()
     );

    transaction.add(
      cadetProgram.instruction.registerCadet({
        accounts: {
          userAuthority: provider.wallet.publicKey,
          cadetAccount: accts.cadetAcct,
          registrationAccount: registrationAccount,
          systemProgram: SystemProgram.programId,
          rent: SYSVAR_RENT_PUBKEY,
        },
      })
    );
    const signature = await provider.wallet.sendTransaction(transaction, connection);
    await connection.confirmTransaction(signature, 'processed');
    toast("Cadet Account Initiated", successToast);

    dispatch(setShowCreateAccount(false));
  
  } catch(e) {
    console.warn("Failed", e);
    toast(e.message, errorToast);
  }
}

export const isSaleStageOpen = (stage) => {
  if (stage === IDO_STAGE_HYDRAZINE_CONTRIBUTION) return true;
  if (stage === IDO_STAGE_SETTING_ALLOCATION) return true;
  if (stage === IDO_STAGE_USDC_CONTRIBUTION) return true;
  if (stage === IDO_STAGE_SETTING_FCFS_ALLOCATION) return true;
  if (stage === IDO_STAGE_FCFS_SALE) return true;

  return false;
}

export const getAccountData = async (provider, idoName, connection, hasCadetAccount, dispatch) => {
  const accts = await helpers.getAddys(provider, idoName);
  const idoProgram = await helpers.getIdoProgram(provider);
  const mainData = await idoProgram.account.mainData.fetch(accts.mainData);
  const poolInfo = await idoProgram.account.pool.fetch(accts.pool);

  const API_URL = helpers.getApiUrl();

  let participantInfo = null;
  let vestedAmount = null;
  let refundParticipantInfo = null;
  let saleKYC = null;
  let initialPurchase = null;
  let maxPurchase = null;
  let allocationInfo = null;

  const curTime = moment().unix();
  const stage = getSaleStage(poolInfo);

  if (hasCadetAccount) {
    const idoAccountInit = await connection.getBalance(accts.idoAcct);
    if (idoAccountInit > 0) {

      // Participant Info
      const pptInit = await connection.getBalance(accts.ppt);
      if (pptInit > 0) {

        participantInfo = await idoProgram.account.participant.fetch(accts.ppt);

        if (new BigNumber(curTime).gt(poolInfo.idoTimes.vestingStart.toNumber())) {
          let tranche = new BigNumber(curTime).minus(new BigNumber(poolInfo.idoTimes.vestingStart.toNumber())).idiv(new BigNumber(poolInfo.trancheLength.toNumber()));

          if (tranche.gt(0)) {
            if (tranche.gt(new BigNumber(poolInfo.tranches.toNumber()))) { tranche = new BigNumber(poolInfo.tranches.toNumber()); }
            let toVest = new BigNumber(participantInfo.bought.toString()).minus(new BigNumber(participantInfo.initialUnlocked.toString()))
              .times(tranche).idiv(new BigNumber(poolInfo.tranches.toNumber()));
            if (toVest.gt(0) && toVest.gt(new BigNumber(participantInfo.claimed.toString()))) {
              let pendingTokens = toVest.minus(new BigNumber(participantInfo.claimed.toString()));
              vestedAmount = pendingTokens;
            }
          }
        }

      }

      // Refund Participant Info
      const repptInit = await connection.getBalance(accts.refund_ppt);
      if (repptInit > 0) {
        refundParticipantInfo = await idoProgram.account.refundParticipant.fetch(accts.refund_ppt);
      }
    } else {
      dispatch(setShowCreateIdoAccount(true));
    }
  }

  if (stage === IDO_STAGE_HYDRAZINE_CONTRIBUTION) {
    try {
      await fetch(API_URL + "/salekyc/", {
        "method": "POST",
        "headers": { "content-type": "application/json", "accept": "application/json" },
        "body": JSON.stringify({ wallet: provider.wallet.publicKey.toBase58(), name: idoName })
      }).then(res => res.json())
        .then((result) => {
          saleKYC = result.data.kyc;
        });
    } catch (e) {
      console.warn("Failed", e);
      toast("Unable to fetch your kyc", errorToast);
    }
  } else if (stage === IDO_STAGE_FCFS_SALE || stage === IDO_STAGE_SETTING_FCFS_ALLOCATION) {
    try {
      await fetch(API_URL + "/fcfs-allocation/", {
        "method": "POST",
        "headers": { "content-type": "application/json", "accept": "application/json" },
        "body": JSON.stringify({
          wallet: provider.wallet.publicKey.toBase58(),
          name: idoName
        })
      }).then(res => res.json())
        .then((result) => {
          initialPurchase = result.data.bought;
          maxPurchase = result.data.maxBuy;
        });
    } catch (e) {
      console.warn("Failed", e);
      toast("Unable to fetch your kyc", errorToast);
    }
  }

  if (poolInfo.poolRoot.toString("hex") !== EMPTY_POOL_HEX) {
    try {
      await fetch(API_URL + "/allocation/", {
        "method": "POST",
        "headers": { "content-type": "application/json", "accept": "application/json" },
        "body": JSON.stringify({ wallet: provider.wallet.publicKey.toBase58(), name: idoName })
      }).then(res => res.json())
        .then((result) => {
          allocationInfo = ({
            allocation: result.data.allocation,
            index: result.data.index,
            amount: result.data.amount,
            proof: result.data.proof,
          });

        });
    } catch (e) {
      console.warn("Failed", e);
      toast("Unable to fetch your allocation", errorToast);
    }
  }

  return {
    mainData,
    poolInfo,
    stage,
    participantInfo,
    vestedAmount,
    refundParticipantInfo,
    saleKYC,
    initialPurchase,
    maxPurchase,
    allocationInfo,
  }
}

export const createIdoAccount = async (provider, idoName, connection, dispatch) => {
  try {
    const accts = await helpers.getAddys(provider, idoName);

    const idoProgram = helpers.getIdoProgram(provider);
    const transaction = new web3.Transaction();

    transaction.add(
      idoProgram.instruction.createUser(
        accts.idoAcctBump, {
          accounts: {
            userAuthority: provider.wallet.publicKey,
            idoUser: accts.idoAcct,
            mainData: accts.mainData,
            systemProgram: SystemProgram.programId,
            rent: SYSVAR_RENT_PUBKEY,
          },
        })
    );
    // }
    const signature = await provider.wallet.sendTransaction(transaction, connection);
    await connection.confirmTransaction(signature, 'processed');
    toast("IDO Account Created", successToast);
    dispatch(setShowCreateIdoAccount(false));
  } catch (e) {
    console.warn("Failed", e);
    toast(e.message, errorToast);
  }
}

export const createNodeIdoAccount = async (provider, connection, name) => {
  const nodeIdoProgram = helpers.getNodeProgram(provider);
  const accts = await helpers.getAddys(provider, name, true);

  const transaction = new web3.Transaction();
  transaction.add(
    nodeIdoProgram.instruction.createUser(
      accts.nodeIdoAcctBump,
      {
        accounts: {
          userAuthority: provider.wallet.publicKey,
          idoUser: accts.nodeIdoAcct,
          mainData: accts.mainData,
          systemProgram: SystemProgram.programId,
          rent: SYSVAR_RENT_PUBKEY,
        }
      })
  );

  const signature = await provider.wallet.sendTransaction(transaction, connection);
  await connection.confirmTransaction(signature, 'processed');
}

export const createNodeParticipantAccount = async (provider, connection, name) => {
  const nodeIdoProgramID = getNodeIdoProgramId();

  const [pool, poolBump] = web3.PublicKey.findProgramAddressSync(
    [Buffer.from(name)],
    nodeIdoProgramID
  );

  const [idoUser, idoUserBump] = web3.PublicKey.findProgramAddressSync(
    [provider.wallet.publicKey.toBuffer(), Buffer.from(PROGRAM_NAME), Buffer.from("pool_user")],
    nodeIdoProgramID
  );
  const [participant, participantBump] = web3.PublicKey.findProgramAddressSync(
    [provider.wallet.publicKey.toBuffer(), Buffer.from(name), Buffer.from("pool_participant")],
    nodeIdoProgramID
  );

  const nodeIdoProgram = helpers.getNodeProgram(provider);
  const transaction = new web3.Transaction();

  transaction.add(
    nodeIdoProgram.instruction.createParticipant(
      participantBump,
      {
        accounts: {
          userAuthority: provider.wallet.publicKey,
          idoUser: idoUser,
          participant: participant,
          pool: pool,
          systemProgram: SystemProgram.programId,
          rent: SYSVAR_RENT_PUBKEY,
        }
      })
  );

  const signature = await provider.wallet.sendTransaction(transaction, connection);
  await connection.confirmTransaction(signature, 'processed');
}

export const purchaseNode = async ({ provider, connection, stage, poolname, merkelProof, nodePrice, nodeAmountQuantity }) => {
  const nodeIdoProgramID = getNodeIdoProgramId();
  const nodeIdoProgram = helpers.getNodeProgram(provider);

  const usdcMint = getUsdcMintAddress();
  const hydrazineMint = getHydrazineMintAddress();

  const proof = stage === NODE_OG_SALE_STAGE ? merkelProof.proof.map((p) => toBytes32Array(Buffer.from(p, "hex"))) : [];

  const totalAmount = stage === NODE_OG_SALE_STAGE ? new BN(merkelProof.amount) : new BN(0);
  const tier = new BN(nodePrice.tier)

  const totalQuantity = new BN(nodeAmountQuantity);
  const accts = await helpers.getAddys(provider, poolname, true);

  const hydrazine_amount = new BN(nodePrice.hydrazine);
  const usdc_amount = new BN(nodePrice.usdc);

  const [mainData, mainDataBump] = await web3.PublicKey.findProgramAddress(
    [Buffer.from(PROGRAM_NAME)],
    nodeIdoProgramID
  );

  const [idoUser, idoUserBump] = web3.PublicKey.findProgramAddressSync(
    [provider.wallet.publicKey.toBuffer(), Buffer.from(PROGRAM_NAME), Buffer.from("pool_user")],
    nodeIdoProgramID
  );

  const [usdcVault, usdcBump] = await web3.PublicKey.findProgramAddress(
    [Buffer.from(poolname), Buffer.from("usdc_vault")],
    nodeIdoProgramID
  );

  const [hydrazineVault, hydrazineBump] = await web3.PublicKey.findProgramAddress(
    [Buffer.from(poolname), Buffer.from("hydrazine_vault")],
    nodeIdoProgramID
  );

  const [userUsdc, userUsdcBump] = await web3.PublicKey.findProgramAddress([
      provider.wallet.publicKey.toBuffer(),
      TOKEN_PROGRAM_ID.toBuffer(),
      usdcMint.toBuffer()
    ],
    ASSOCIATED_TOKEN_PROGRAM_ID);
  const [userHydrazine, userHydrazineBump] = await web3.PublicKey.findProgramAddress([
      provider.wallet.publicKey.toBuffer(),
      TOKEN_PROGRAM_ID.toBuffer(),
      hydrazineMint.toBuffer()
    ],
    ASSOCIATED_TOKEN_PROGRAM_ID);
  const transaction = new web3.Transaction();

  const index = stage === NODE_OG_SALE_STAGE ? new BN(merkelProof.index) : new BN(0);

  transaction.add(
    nodeIdoProgram.instruction.purchaseNode(
      index,
      totalAmount,
      proof,
      hydrazine_amount,
      usdc_amount,
      tier,
      totalQuantity,
      {
        accounts: {
          userAuthority: provider.wallet.publicKey,
          idoUser: idoUser,
          mainData: mainData,
          participant: accts.nodePpt,
          pool: accts.nodePool,
          usdcMint: usdcMint,
          userUsdc: userUsdc,
          usdcVault: usdcVault,
          systemProgram: SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
        }
      })
  );
  const signature = await provider.wallet.sendTransaction(transaction, connection);
  await connection.confirmTransaction(signature, 'processed');
  toast("NODE Successfully purchased!", successToast);
}

export const getTotalHydrazineContributed = (poolInfo, idoInfo) => {
  if (!poolInfo || !idoInfo || !poolInfo?.totalHydrazine) return 0;
  return Math.round(parseFloat(new BigNumber(poolInfo.totalHydrazine.toString()).div(new BigNumber(1000000)).div(new BigNumber(idoInfo?.factor))));
}

export const getMaxHydrazine = (poolInfo) => {
  if (!poolInfo) return 0;
  return parseFloat(new BigNumber(poolInfo.maxHydrazine.toString()).div(new BigNumber(1000000)));
}

export const getAllocationPerHydrazine = (poolInfo, idoInfo) => {
  if (!poolInfo?.usdcAmount) return 0;

  const totalHydrazineSpent = getTotalHydrazineContributed(poolInfo, idoInfo);
  const raiseAmount = parseFloat(new BigNumber(poolInfo.usdcAmount.toString()).div(new BigNumber(1000000)).toFixed(2));
  return (raiseAmount / totalHydrazineSpent);
}

export const getTotalUserContributedHydrazine = (participantInfo) => {
  return participantInfo && participantInfo?.burned ? parseFloat(new BigNumber(participantInfo?.burned?.toString()).div(new BigNumber(1000000))) : 0;
}
export const getTotalContributedUSDC = (poolInfo) => {
  return poolInfo ? parseFloat(new BigNumber(poolInfo.totalUsdc.toString()).div(new BigNumber(1000000)).toFixed(2)) : 0;
}

export const getMaxContributedUSDC = (poolInfo) => {
  return poolInfo ? parseFloat(new BigNumber(poolInfo.usdcAmount.toString()).div(new BigNumber(1000000)).toFixed(2)) : 0;
}
export const getEstimatedAllocation = ({ poolInfo, idoInfo, participantInfo, userHydrazineInputValue}) => {
  if (!poolInfo || !idoInfo || idoInfo.isNodeSale) {
    return 0;
  }

  const totalHydrazineBurned = parseFloat(new BigNumber(poolInfo.totalHydrazine.toString()).div(new BigNumber(1000000)).div(new BigNumber(idoInfo?.factor)))
  const raiseAmount = parseFloat(new BigNumber(poolInfo.usdcAmount.toString()).div(new BigNumber(1000000)).toFixed(2));
  const userParticipationHydrazine = getTotalUserContributedHydrazine(participantInfo);
  const estimatedAllocation = (raiseAmount / (totalHydrazineBurned > 1 ? totalHydrazineBurned : 1)) * ((hasValue(userHydrazineInputValue) ? parseFloat(userHydrazineInputValue) : 0) + userParticipationHydrazine);

  if (estimatedAllocation > (raiseAmount / 20)) {
    return raiseAmount / 20;
  }

  return estimatedAllocation;
}

export const getUserAllocation = (allocationInfo, userUSDCContribution, estimatedAllocation) => {
  if (userUSDCContribution && userUSDCContribution > 0) {
    if (estimatedAllocation &&estimatedAllocation > userUSDCContribution) {
      return estimatedAllocation;
    } else {
      return userUSDCContribution;
    }
  }
  return allocationInfo?.amount ? parseFloat(new BigNumber(allocationInfo.amount).div(new BigNumber(1000000)).toFixed(3, 1)) : 0;
}

export const getUserUSDCContribution = (participantInfo, poolInfo) => {
  if (participantInfo == null || !poolInfo) return 0;

  const totalUSDC = getUserTotalUSDCContribution(participantInfo, poolInfo)
  const fcfsUSDC = getUserFCFSContribution(participantInfo, poolInfo)

  return totalUSDC - fcfsUSDC;
}

export const getUserFCFSContribution = (participantInfo, poolInfo) => {
  if (!participantInfo || !poolInfo) return 0;
  return parseFloat(new BigNumber(participantInfo.boughtOpen.toString()).times(new BigNumber(poolInfo.price.toString())).div(new BigNumber(1000000000000)).toFixed(3, 1));
}

export const getUserTotalUSDCContribution = (participantInfo, poolInfo) => {
  if (participantInfo == null || !poolInfo) return 0;
  return parseFloat(new BigNumber(participantInfo.bought.toString()).times(new BigNumber(poolInfo.price.toString())).div(new BigNumber(1000000000000)).toFixed(3, 1));
}

export const toBytes32Array = (b) => {
  const buf = Buffer.alloc(32);
  b.copy(buf, 32 - b.length);
  return Array.from(buf);
};

export const burnHydrazine = async ({ provider, idoName, connection, allocationInfo, burnAmount }) => {
  const idoProgram = helpers.getIdoProgram(provider);
  const accts = await helpers.getAddys(provider, idoName);

  const hydrazineMint = getHydrazineMintAddress();
  const idoProgramID = helpers.getIdoProgramId();

  try {
    const pptInit = new BN(await connection.getBalance(accts.ppt));

    if (pptInit.isZero()) {
      const transaction = new web3.Transaction();

      // if(helpers.getEnvironment() === PRODUCTION) {
      transaction.add(
        idoProgram.instruction.initParticipant(
          accts.pptBump, {
            accounts: {
              userAuthority: provider.wallet.publicKey,
              idoUser: accts.idoAcct,
              participant: accts.ppt,
              pool: accts.pool,
              systemProgram: SystemProgram.programId,
              rent: SYSVAR_RENT_PUBKEY,
            },
          })
      );

      const signature = await provider.wallet.sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'processed');
      toast("Participation Initiated", successToast);
    }

    const burnQty = new BN(new BigNumber(burnAmount).times(new BigNumber(1000000)).toFixed(0));

    const userHydrazine = await helpers.findATA(provider.wallet.publicKey, hydrazineMint);
    const [hydrazineVault, hydrazineBump] = await web3.PublicKey.findProgramAddress(
      [
        Buffer.from(idoName),
        Buffer.from("hydrazine_vault")
      ],
      idoProgramID
    );
 
    const transaction = new web3.Transaction();
    transaction.add(
      idoProgram.instruction.burnHydrazine(burnQty, {
        accounts: {
          userAuthority: provider.wallet.publicKey,
          idoUser: accts.idoAcct,
          mainData: accts.mainData,
          participant: accts.ppt,
          pool: accts.pool,
          hydrazineMint: hydrazineMint,
          userHydrazine: userHydrazine,
          hydrazineVault: hydrazineVault,
          systemProgram: SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
        },
      })
    );
    const signature = await provider.wallet.sendTransaction(transaction, connection);
    await connection.confirmTransaction(signature, 'processed');
    toast("Successfully burned N2H4", successToast);
    window.location.reload();
  } catch (e) {
    console.log(e)
    const errorMessage = getErrorMessage(e, idoProgram.idl);
    toast(errorMessage, errorToast);
  }
}
export const buyAllocation = async ({ provider, idoName, connection, allocationInfo, buyAmount }) => {

  const accts = await helpers.getAddys(provider, idoName);
  const idoProgram = helpers.getIdoProgram(provider);

  const usdcMint = getUsdcMintAddress();
  const idoProgramID = helpers.getIdoProgramId();

  try {

    const pptInit = new BN(await connection.getBalance(accts.ppt));
    if (pptInit.isZero()) {
      const transaction = new web3.Transaction();
      if(helpers.getEnvironment() === PRODUCTION) {
        transaction.add(
          idoProgram.instruction.initParticipant(accts.pptBump, {
            accounts: {
              userAuthority: provider.wallet.publicKey,
              idoUser: accts.idoAcct,
              participant: accts.ppt,
              pool: accts.pool,
              systemProgram: SystemProgram.programId,
              rent: SYSVAR_RENT_PUBKEY,
            },
          })
        );
      } else {
        transaction.add(
          idoProgram.instruction.initParticipant({
            accounts: {
              userAuthority: provider.wallet.publicKey,
              idoUser: accts.idoAcct,
              participant: accts.ppt,
              pool: accts.pool,
              systemProgram: SystemProgram.programId,
              rent: SYSVAR_RENT_PUBKEY,
            },
          })
        );
      }
      const signature = await provider.wallet.sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'processed');
      toast("Participation Initiated", successToast);
    }
    const buyQty = new BN(new BigNumber(buyAmount).times(new BigNumber(1000000)).toFixed(0, 1));
    const index = new BN(allocationInfo.index);
    const amt = new BN(allocationInfo.amount);
    const userUsdc = await helpers.findATA(provider.wallet.publicKey, usdcMint);
    const [usdcVault, usdcBump] = await web3.PublicKey.findProgramAddress(
      [Buffer.from(idoName), Buffer.from("usdc_vault")],
      idoProgramID
    );

    // Transaction
    const transaction = new web3.Transaction();
    transaction.add(
      idoProgram.instruction.purchaseTokens(index, amt,
        allocationInfo.proof.map((p) => toBytes32Array(Buffer.from(p, "hex"))),
        buyQty,
        {
          accounts: {
            userAuthority: provider.wallet.publicKey,
            idoUser: accts.idoAcct,
            mainData: accts.mainData,
            participant: accts.ppt,
            pool: accts.pool,
            usdcMint: usdcMint,
            userUsdc: userUsdc,
            usdcVault: usdcVault,
            systemProgram: SystemProgram.programId,
            tokenProgram: TOKEN_PROGRAM_ID,
          },
        })
    );

    const signature = await provider.wallet.sendTransaction(transaction, connection);
    await connection.confirmTransaction(signature, 'processed');

    toast("Successfully bought allocation", successToast);
    window.location.reload();
    return {
      success: true,
    }
  } catch (e) {
    const message = e.toString();
    if (message.includes('TransactionExpiredTimeoutError')) {
      const regexPattern = /[1-9A-HJ-NP-Za-km-z]{87,88}/g;
      const txid = message.match(regexPattern);
      const url = 'https://solscan.io/tx/' + hasValue(txid) ? txid[0] : 'missing';
      return {
        success: false,
        errorMessage: 'Transaction was not confirmed in 30.00 seconds due to Solana congestion. In order to find out if it succeeded or not/failed, please verify the solscan transaction by pressing this text',
        url,
      }
    }
    const errorMessage = getErrorMessage(e, idoProgram.idl);
    toast(errorMessage, errorToast);
    return {
      success: false,
      errorMessage: errorMessage,
    }
  }
}

export const buyFCFSAllocation = async ({ provider, idoName, connection, allocationInfo, buyAmount }) => {
  const accts = await helpers.getAddys(provider, idoName);
  const idoProgram = helpers.getIdoProgram(provider);
  const usdcMint = getUsdcMintAddress();
  const idoProgramID = helpers.getIdoProgramId()

  try {
    const pptInit = new BN(await connection.getBalance(accts.ppt));
    if (pptInit.isZero()) {
      const transaction = new web3.Transaction();
      transaction.add(
        idoProgram.instruction.initParticipant(accts.pptBump, {
          accounts: {
            userAuthority: provider.wallet.publicKey,
            idoUser: accts.idoAcct,
            participant: accts.ppt,
            pool: accts.pool,
            systemProgram: SystemProgram.programId,
            rent: SYSVAR_RENT_PUBKEY,
          },
        })
      );
      const signature = await provider.wallet.sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'processed');
      toast("Participation Initiated", successToast);
    }
    const buyQty = new BN(new BigNumber(buyAmount).times(new BigNumber(1000000)).toFixed(0, 1));
    const index = new BN(allocationInfo.index);
    const amt = new BN(allocationInfo.amount);
    const userUsdc = await helpers.findATA(provider.wallet.publicKey, usdcMint);
    const [usdcVault, usdcBump] = await web3.PublicKey.findProgramAddress(
      [Buffer.from(idoName), Buffer.from("usdc_vault")],
      idoProgramID
    );
    const transaction = new web3.Transaction();
    transaction.add(
      idoProgram.instruction.purchaseOpenTokens(index, amt,
        allocationInfo.proof.map((p) => toBytes32Array(Buffer.from(p, "hex"))),
        buyQty,
        {
          accounts: {
            userAuthority: provider.wallet.publicKey,
            idoUser: accts.idoAcct,
            mainData: accts.mainData,
            participant: accts.ppt,
            pool: accts.pool,
            usdcMint: usdcMint,
            userUsdc: userUsdc,
            usdcVault: usdcVault,
            systemProgram: SystemProgram.programId,
            tokenProgram: TOKEN_PROGRAM_ID,
          },
        })
    );
    const signature = await provider.wallet.sendTransaction(transaction, connection);
    const test = await connection.confirmTransaction(signature, 'processed');
    console.log(test)
    toast("Successfully bought allocation", successToast);
    window.location.reload();
    return {
      success: true,
    }
  } catch (e) {
    const message = e.toString();
    if (message.includes('TransactionExpiredTimeoutError')) {
      const regexPattern = /[1-9A-HJ-NP-Za-km-z]{87,88}/g;
      const txid = message.match(regexPattern);
      const url = 'https://solscan.io/tx/' + hasValue(txid) ? txid[0] : 'missing';
      console.log(url)
      return {
        success: false,
        errorMessage: 'Transaction was not confirmed in 30.00 seconds due to Solana congestion. In order to find out if it succeeded or not/failed, please verify the solscan transaction by pressing this text',
        url,
      }
    }
    const errorMessage = getErrorMessage(e, idoProgram.idl);
    toast(errorMessage, errorToast);
    return {
      success: false,
      errorMessage: errorMessage,
    }
  }
}

export const getIndividualAllocation = (poolInfo, stage) => {
  if (!poolInfo) return 0;

  if (stage === NODE_OG_SALE_STAGE) {
    if (poolInfo?.individualLimitOg && poolInfo?.individualLimitOg.toString() !== '0') {
      return parseInt(poolInfo?.individualLimitOg.toString());
    }
  }
  if (stage === NODE_STAKERS_SALE_STAGE) {
    if (poolInfo?.individualLimitStaker && poolInfo?.individualLimitStaker.toString() !== '0') {
      return parseInt(poolInfo?.individualLimitStaker.toString());
    }
  }

  return parseInt(poolInfo?.individualLimit.toString());
}

export const isSaleOngoing = (idoInfo, currentStage) => {
  if (!idoInfo) return false

  const curTime = moment().unix();

  if (!idoInfo.isNodeSale) {
    if (!idoInfo.salesStartTime || !idoInfo.salesEndTime) return false;

    const hydrazineStart = idoInfo.registrationStartTime;
    const openSaleEndNum = hasValue(idoInfo?.openSaleEndTime) ? idoInfo.openSaleEndTime : idoInfo?.salesEndTime;

    const hasSalestarted =  curTime > hydrazineStart;
    const hasSaleEnded = curTime > openSaleEndNum;

    return hasSalestarted && !hasSaleEnded;
  }

  const saleStartNum = getNodeSaleStartTimeUnix(idoInfo);
  const saleEndNum = getNodeSaleEndUnix(idoInfo);

  const hasSaleStartedNode =  curTime > saleStartNum;
  const hasSaleEndedNode = curTime > saleEndNum;

  return hasSaleStartedNode && !hasSaleEndedNode;
}

export const getNodeSaleStartTimeUnix = (idoInfo) => {
  if (!hasValue(idoInfo)) return 0;

  const nodeTiers = idoInfo?.nodeTiers;
  const ogTier = nodeTiers?.ogTier;
  const stakerTier = nodeTiers?.stakerTier;
  const fcfsTier = nodeTiers?.fcfsTier;

  if (ogTier?.active) {
    return ogTier.startTime;
  }
  if (stakerTier?.active) {
    return stakerTier.startTime;
  }
  if (fcfsTier?.active) {
    return fcfsTier.startTime;
  }
}

export const getNodeSaleEndUnix = (idoInfo) => {
  if (!hasValue(idoInfo)) return 0;

  const nodeTiers = idoInfo?.nodeTiers;
  const ogTier = nodeTiers?.ogTier;
  const stakerTier = nodeTiers?.stakerTier;
  const fcfsTier = nodeTiers?.fcfsTier;

  if (fcfsTier?.active) {
    return fcfsTier.endTime;
  }
  if (stakerTier?.active) {
    return stakerTier.endTime;
  }
  if (ogTier?.active) {
    return ogTier.endTime;
  }
}

export const SALE_TYPE_PUBLIC = 'PUBLIC';
export const SALE_TYPE_PRIVATE = 'PRIVATE';
export const SALE_TYPE_LAUNCH_VOTE = 'LAUNCH_VOTE';
