import React, {useCallback, useEffect, useState, useRef, useMemo} from 'react';
import missionPageBackground from '../../images/missionDashboardBackground.webm'
import missionPageBackgroundMobile from '../../images/missionDashboardBackgroundMobile.webm'
import missionPageBackgroundMobileMp4 from '../../images/missionDashboardBackgroundMobile.mp4'
import useMobileDetect from 'use-mobile-detect-hook';

import "./Swap.scss";
import LoadingSpinner, {LOADING_SPINNER_MEDIUM} from '../../Components/LoadingSpinner/LoadingSpinner';
import {useConnection, useWallet} from "@solana/wallet-adapter-react";
import {LAMPORTS_PER_SOL, PublicKey, VersionedTransaction} from '@solana/web3.js';
import {calcAmountOut, getTokenAccountsByOwner} from '../../utils/index'
import {useSelector} from "react-redux";
import TextInput from "../../Components/TextInput/TextInput";
import Button, {BUTTON_TYPE_NO_STYLE} from "../../Components/Button/Button";

import SwapIcon from '../../images/svg/swap-icon.svg'
import SwapActiveIcon from '../../images/svg/swap-icon-active.svg'
import linkIcon from '../../images/linkIcon.png';

//import {getPriceInfo, swapToken} from "./index.ts";
import Icon from "../../Components/Icon/Icon";
import TokenModalSelect from "../../Components/TokenModalSelect/TokenModalSelect";
import { errorToast, successToast } from '../../Helpers/toastCSS';
import toast from "react-hot-toast";
import InfoCard from "../../Components/InfoCard/InfoCard";
import {connectWalletText, connectWalletTitle} from "../Vault/Vault";
import WalletNotConnectedIcon from "../../images/svg/walletNotConnected.svg";
import {hasValue, useDebouncedEffect, walletFormatting} from "../../Helpers/helperFunctions";
import {getApiUrl, openURL} from "../../Helpers/helper";
import { fetch as crossFetch } from 'cross-fetch';
import styles from "../IdoDetails/IdoDetails.module.scss";
import {Buffer} from "buffer";
import Property from "../../Components/Property/Property";
import {HEADER_VAULT_ID} from "../../Components/Vault/HeaderVault";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {
  availableFeeMints,
  NO_TOKENS_RESPONSE,
  officalTokens, referralAccountPubkey,
  solanaDefaultToken,
  starsDefaultToken, SWAP_TOOLTIP_ID
} from "./swapConstants";


function SwapPage(props) {
  const {
    provider
  } = props;

  const detectMobile = useMobileDetect();
  const wallet = useWallet();
  const { connection } = useConnection();

  const starsInWallet = useSelector((state) => state.statistics.walletStats.starsInWallet);
  
  const [isLoading, setIsLoading] = useState(true);
  const [poolLoading, setPoolLoading] = useState(false);
  const [isSendingTransaction, setIsSendingTransaction] = useState(false);
  const [tokenAccounts, setTokenAccounts] = useState();
  const [starsPrice, setStarsPrice] = useState();
  const [solPrice, setSolPrice] = useState();
  const [solanaBalance, setSolanaBalance] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const [exchangeRate, setExchangeRate] = useState({ tokenEx: null, dollarEx: null });
  const [minimumAmount, setMinimumAmount] = useState();
  const [priceImpact, setPriceImpact] = useState();
  const [slippage, setSlippage] = useState(1);
  const [hasSLFee, setHasSLFee] = useState(true);
  const [quoteOutdated, setQuoteOutdated] = useState(false);

  const [tokenToSwap, setTokenToSwap] = useState(solanaDefaultToken);
  const [tokenToSwapBalance, setTokenToSwapBalance] = useState(0);
  const [tokenToSwapAmount, setTokenToSwapAmount] = useState();
  const [tokenToReceive, setTokenToReceive] = useState(starsDefaultToken);
  const [tokenToReceiveBalance, setTokenToReceiveBalance] = useState(starsInWallet);
  const [tokenToReceiveAmount, setTokenToReceiveAmount] = useState();
  const [quoteTransaction, setQuoteTransaction] = useState();

  const [tokenList, setTokenList] = useState();

  const publicKey = provider?.wallet?.publicKey;
  const insufficientBalance = Number(tokenToSwapAmount) > Number(tokenToSwapBalance);
  const swapDisabled = insufficientBalance || !hasValue(quoteTransaction);
  const apiUrl = getApiUrl();

  useEffect(() => {
    const fetchInitalData = async () => {
      await fetchTokenInfo();

      setIsLoading(false);
      const solanaPriceResponse = await (
        await fetch(`https://price.jup.ag/v6/price?ids=SOL&vsToken=USDC`
        )
      ).json();

      setSolPrice(solanaPriceResponse.data.SOL.price)

      const starsPriceResponse = await (
        await fetch(`https://price.jup.ag/v6/price?ids=STARS&vsToken=USDC`
        )
      ).json();

      setStarsPrice(starsPriceResponse.data.STARS.price)

    }
    fetchInitalData();
  }, []);


  useEffect(() => {
    if (publicKey && connection) {
      fetchTokenData();
     //fetchWalletTransactionHistory();
    }
  }, [tokenToSwap, tokenToReceive, publicKey])

  useEffect(() => {
    if (quoteOutdated === true) {
      getInitialRate();
    }
  }, [quoteOutdated]);

  const fetchWalletTransactionHistory = async () => {
    let transactionList = await connection.getSignaturesForAddress(publicKey, {limit: 5});

    //Add this code
    let signatureList = transactionList.map(transaction=>transaction.signature);
    let transactionDetails = await connection.getParsedTransactions(signatureList, {maxSupportedTransactionVersion:0});
    const mappedTranDetails = transactionDetails.map(tran => {
      console.log(tran)
      const preTokenBalances = tran.meta.preTokenBalances;
      const postTokenBalances = tran.meta.postTokenBalances;
      let result = [];

      postTokenBalances.forEach(token => {
         const preToken = preTokenBalances.find(tkn => tkn.accountIndex === token.accountIndex);
         if (preToken.uiTokenAmount.amount !== token.uiTokenAmount.amount) {
           result.push({
             mint: token.mint,
             currentBalance: token.uiTokenAmount.uiAmount,
             changes: token.uiTokenAmount.amount - preToken.uiTokenAmount.amount,
             decimals: token.uiTokenAmount.decimals,
           })
         }
      })

      return {
        tokenBalanceChanges: result,
      }
    })
    console.log(mappedTranDetails)
  }

  const fetchTokenData = async () => {
    const tokenToSwapExists = tokenToSwap !== null;
    const tokenToReceiveExists = tokenToReceive !== null;

    if (tokenToSwapExists) {
      if (!availableFeeMints.includes(tokenToSwap?.value)) {
        setHasSLFee(false);
      }
      await fetchTokenBalance(tokenToSwap, setTokenToSwapBalance)
    }
    if (tokenToReceiveExists) {
      await fetchTokenBalance(tokenToReceive, setTokenToReceiveBalance)
    }
    await getInitialRate();
  }
  
  const fetchTokenBalance = async (token, setTokenFunc) => {
    if (token.value === solanaDefaultToken.value) {
      return await fetchSolBalance(setTokenFunc);
    }

    const tokenAccs = hasValue(tokenAccounts) ? tokenAccounts : await getTokenAccountsByOwner(connection, publicKey);
    if (!tokenAccounts) {
      setTokenAccounts(tokenAccs);
    }

    const tokenFromWallet = tokenAccs.filter(acc => acc.accountInfo.mint.toBase58() === token.value)

    if (tokenFromWallet === null || tokenFromWallet.length === 0) {
      return setTokenFunc(0);
    }

    const accBalance = await connection.getTokenAccountBalance(tokenFromWallet[0].pubkey);

    const tokenBalance = accBalance.value.uiAmount || 0;
    setTokenFunc(tokenBalance);
  }

  const amountIn = useMemo(() => {
    if (tokenToSwap.value === solanaDefaultToken.value) {
      return (tokenToSwapAmount * LAMPORTS_PER_SOL).toFixed(0);
    } else {
     return (tokenToSwapAmount * (10 ** tokenToSwap.decimals)).toFixed(0)
    }
  }, [tokenToSwapAmount, tokenToSwap])

  const fetchTokenInfo = async () => {
    setTokenList({ official: officalTokens });
  }

  const fetchToken = async (value) => {
    return await fetch(`${apiUrl}/token/${value}`)
      .then(r => r.json())
      .then(response => {
        const result = response.data.map(token => {
          return {
            name: token.name,
            key: token.symbol,
            icon: token.icon,
            value:  token.mint,
            decimals: token.decimals,
          };
        })
        setTokenList(prev => {
          return (
            {official: prev?.official, searchedTokens: response.data.length !== 0 ? result : []}
          )
        })
        if (response.data.length === 0) return NO_TOKENS_RESPONSE;
      })
  }
  const fetchSolBalance = async (setTokenFunc) => {
    if (publicKey !== null) {
      const balance = await connection.getBalance(publicKey);
      setTokenFunc(balance / LAMPORTS_PER_SOL);
      setSolanaBalance(balance / LAMPORTS_PER_SOL)
    }
  }

  const getInitialRate = async () => {
    if (publicKey && tokenToSwapAmount > 0 && tokenToReceive && tokenToSwap && !poolLoading) {
      setPoolLoading(true);
      try {
        const quoteResponse = await (
          await fetch(
            `https://quote-api.jup.ag/v6/quote?inputMint=${tokenToSwap.value}&outputMint=${tokenToReceive.value}&amount=${amountIn}&slippageBps=${slippage * 10}${hasSLFee ? '&platformFeeBps=10' : ''}`
          )
        ).json();

        if (quoteResponse?.errorCode === 'TOKEN_NOT_TRADABLE') {
          return toast('ERROR: No Liquidity Pool Found', errorToast);
        }


        const {
          priceImpactPct,
          outAmount,
          otherAmountThreshold,
        } = quoteResponse;

        setQuoteTransaction(quoteResponse);
        setPriceImpact(Number(priceImpactPct) * 100)
        if (quoteOutdated === true) {
          setQuoteOutdated(false)
        }
        if (tokenToReceive.value === solanaDefaultToken.value) {
          const finalAmount = outAmount / LAMPORTS_PER_SOL;
          const dollarValue = await getDollarValue({ key: tokenToReceive.key, value: finalAmount });

          setTokenToReceiveAmount(finalAmount);
          setMinimumAmount(otherAmountThreshold / LAMPORTS_PER_SOL)
          setExchangeRate({ tokenEx: finalAmount / tokenToSwapAmount, dollarEx: dollarValue })
        } else {
          const finalAmount = outAmount / (10 ** tokenToReceive.decimals);
          const dollarValue = await getDollarValue({ key: tokenToReceive.key, value: finalAmount });

          setTokenToReceiveAmount(finalAmount);
          setMinimumAmount(otherAmountThreshold / (10 ** tokenToReceive.decimals))
          setExchangeRate({ tokenEx: finalAmount / tokenToSwapAmount, dollarEx: dollarValue })
        }

      } catch (e) {
        console.error(e);
        toast('Error fetching pool: ' + JSON.stringify(e), errorToast);
      } finally {
        setPoolLoading(false);
      }
    }
  }

  const getDollarValue = async ({ key, value }) => {
    try {
      const priceResponse = await (
        await fetch(`https://price.jup.ag/v6/price?ids=${key}&vsToken=USDC`
        )
      ).json();

      if (!priceResponse.data) return;

      const dollarPrice = priceResponse?.data[key].price;

      return (Number(dollarPrice) * value);
    } catch (err) {
      console.error(err);
      toast('Error fetching dollar price: ' + JSON.stringify(err), errorToast);
    }
  }

  const handleSwap = async () => {
    try {
      setIsSendingTransaction(true);

      if (errorMessage) {
        setErrorMessage('');
      }

      const mintPubKey = new PublicKey(tokenToSwap.value);

      const [feeAccount] = PublicKey.findProgramAddressSync(
        [
          Buffer.from("referral_ata"),
          referralAccountPubkey.toBuffer(),
          mintPubKey.toBuffer(),
        ],
        new PublicKey("REFER4ZgmyYx9c6He5XfaTMiGfdLwRnkV4RPp9t9iF3")
      );

      const requestBody = {
        quoteResponse: quoteTransaction,
        userPublicKey: wallet.publicKey.toString(),
        dynamicComputeUnitLimit: true,
      };

      if (hasSLFee) {
        requestBody.feeAccount = feeAccount;
      }

      const { swapTransaction  } = await (
        await crossFetch('https://quote-api.jup.ag/v6/swap', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestBody),
        })
      ).json();
      const swapTransactionBuf = Buffer.from(swapTransaction, 'base64');
      const transaction = VersionedTransaction.deserialize(swapTransactionBuf)

      const sign = await wallet.sendTransaction(transaction, connection);
      const test = await connection.confirmTransaction(sign,'processed');
      if (test.value.err === null) {
        toast('Swap Successful!', successToast);
      } else {
        console.log(test)
        toast('Error sending transaction: ' + 'UNKOWN ERROR OCCURED', errorToast);
      }
    } catch (err) {
      console.error('tx failed => ', err);
      const message = err.toString();
      if (message.includes('TransactionExpiredTimeoutError')) {
        const regexPattern = /[1-9A-HJ-NP-Za-km-z]{87,88}/g;
        const txid = message.match(regexPattern);
        const url = 'https://solscan.io/tx/' + (hasValue(txid) ? txid[0] : 'missing');
        setErrorMessage({
          errorMessage: 'Transaction was not confirmed in 30.00 seconds due to Solana congestion. In order to find out if it succeeded or failed, please verify the solscan transaction by pressing this text',
          url,
        });
      }
      toast('Error sending transaction: ' + 'SOLANA CONGESTION, PLEASE TRY AGAIN', errorToast);

    } finally {
      setTokenToSwapAmount('');
      setTokenToReceiveAmount('');
      setIsSendingTransaction(false);
      setMinimumAmount('');
      setPriceImpact(0)
      setQuoteTransaction('');

      setTimeout(async () => {
        const tokenToSwapExists = tokenToSwap !== null;
        const tokenToReceiveExists = tokenToReceive !== null;
        if (tokenToSwapExists) {
          await fetchTokenBalance(tokenToSwap, setTokenToSwapBalance)
        }
        if (tokenToReceiveExists) {
          await fetchTokenBalance(tokenToReceive, setTokenToReceiveBalance)
        }
      }, 1500)
    }
  }

  const priceImpactColorCalc = () => {
    if (!priceImpact) {
      return '';
    }

    const priceImpactNumber = Number(priceImpact);

    if (priceImpactNumber > 5) {
      return '#B00020';
    }

    if (priceImpactNumber > 1) {
      return '#F77F00';
    }
  }

  useDebouncedEffect(() => {
    getInitialRate();
  }, 2000, [tokenToSwapAmount]);

  useDebouncedEffect(() => {
    if (hasValue(quoteTransaction) && !isLoading && !isSendingTransaction && !poolLoading) {
      setQuoteOutdated(true);
    }
  }, 20000, [poolLoading]);


  const setMaxSwapToken = () => {
    if (tokenToSwapBalance != null && tokenToSwapBalance !== 0) {
      setTokenToSwapAmount(tokenToSwapBalance);
    }
  };

  const setHalfMaxSwapToken = () => {
    if (tokenToSwapBalance != null && tokenToSwapBalance !== 0) {
      setTokenToSwapAmount(tokenToSwapBalance / 2);
    }
  }

  const swapTokenPlace = useCallback(() => {
    if (tokenToReceive) {
      setTokenToSwap(tokenToReceive);
      if (tokenToReceiveAmount && Number(tokenToReceiveAmount) > 0) {
        setTokenToSwapAmount(tokenToReceiveAmount);
      }
    }
    if (tokenToSwap) {
      setTokenToReceive(tokenToSwap);
      if (tokenToSwapAmount && Number(tokenToSwapAmount) > 0) {
        setTokenToReceiveAmount(tokenToSwapAmount);
      }
    }
  }, [tokenToReceive, tokenToSwap, tokenToSwapAmount, tokenToReceiveAmount])

  const tokenInfoBox = (tokenObject) => {
    return (
      <div className="swapFooterTokenInfoBox">
        <img src={tokenObject.icon} alt="Solana" className="swapTokenInfoIcon"/>
        <div className="swapFooterTokenInfoColumn">
          <div className="swapFooterTokenAddressRow">
            <span>{tokenObject.key}</span>
            <div className="swapFooterTokenAddressBox"
                 onClick={() => openURL('https://solscan.io/token/' + tokenObject.value)}>
              <span>{walletFormatting(tokenObject.value, 4)}</span>
              <img src={linkIcon} alt="Link" className="swapFooterTokenLinkIcon"/>
            </div>
          </div>
          <span className="swapFooterTokenNameText">{tokenObject.name}</span>
        </div>
      </div>
    )
  }

  const priceImpactRender = () => {
    if (!hasValue(priceImpact)) {
      return <span>AWAITING INPUT</span>
    }

    return <span style={{color: priceImpactColorCalc()}}>{Number(priceImpact).toFixed(3)}%</span>;
  }

  return (
    <main
      className='swapMainContainer'>
      <div className='missionOverlay'></div>
      <ReactTooltip id={HEADER_VAULT_ID} effect="solid" place="top" />
      {isSendingTransaction && <div className={styles.transactionPendingOverlay}>
        <span
          className={styles.transactionPendingText}>blockchain transaction is pending, do not close this window</span>
        <LoadingSpinner/>
      </div>}
      {!wallet.publicKey && <InfoCard
        text={connectWalletText()}
        title={connectWalletTitle()}
        icon={WalletNotConnectedIcon}/>}
      {!isLoading && wallet.publicKey && <div className="swapContainerColumn">
        <div className="swapHeaderContainer">
          <h1 className="swapHeaderTitle">TOKEN SWAP</h1>
          <span className="swapHeaderTitleLabel">POWERED BY JUPITER</span>
          <div className="swapExchangeRateContainer">
            {starsPrice && <span className="swapExchangeRateText">STARS / USDC: ${starsPrice?.toFixed(5)}</span>}
            {solPrice && <span className="swapExchangeRateText">SOL / USDC: ${solPrice?.toFixed(2)}</span>}
            {!starsPrice && !solPrice && <div className="swapExchangeFetchingPriceContainer">
              <span className="swapExchangeRateText">FETCHING PRICES...</span>
            </div>}
          </div>
        </div>

        <div className='swapContainer'>
          <div>
            <div className="swapInputTitleRow">
              <span className='swapTitle'>YOU’RE PAYING</span>
              <div className="swapHelperOptionsRow">
                <span>{tokenToSwapBalance + ' ' + tokenToSwap.key}</span>
                 <Button text="HALF" buttonType={BUTTON_TYPE_NO_STYLE} onClick={setHalfMaxSwapToken}
                         className="swapHelperOptionItem"/>
                 <Button text="MAX" buttonType={BUTTON_TYPE_NO_STYLE} onClick={setMaxSwapToken}
                         className="swapHelperOptionItem"/>
              </div>
             </div>
             <div className='tokenToSwapContainer'>
               <div className='tokenToSwapInnerContainer'>
                 <TokenModalSelect
                   setValue={setTokenToSwap}
                   value={tokenToSwap}
                   tokenAccs={tokenAccounts}
                   solanaBalance={solanaBalance}
                   connection={connection}
                   fetchToken={fetchToken}
                   options={tokenList}/>
               </div>
               <div className='tokenToSwapInnerContainer'>
                 <TextInput
                   overrideContainerClassName={'textInputOverrideContainer'}
                   inputClassName="textInputOverride"
                   value={tokenToSwapAmount}
                   onBlur={getInitialRate}
                   placeholder={tokenToSwapBalance}
                   setValue={setTokenToSwapAmount}/>
               </div>
             </div>
             <br/>
            {poolLoading && <div className='localSwapSpinner'>
              <LoadingSpinner local size={LOADING_SPINNER_MEDIUM}/>
            </div>}
            <div className='swapLocalExchangeRate'>
              {exchangeRate.tokenEx && <h4 className='swapLocalExchangeRateText'>Exchange
                Rate: {'1 ' + tokenToSwap.key + ' ≈ ' + exchangeRate.tokenEx?.toFixed(6) + ' ' + tokenToReceive.key}</h4>}
              {exchangeRate.dollarEx &&
                <h4 className='swapLocalExchangeRateText'>Est. Dollar value: { '$' + exchangeRate.dollarEx?.toFixed(2)}</h4>}
            </div>
            <div className='swapTokensContainer'>
              <Icon Icon={SwapIcon} IconActive={SwapActiveIcon} className={'swapTokenIcon'} onClick={swapTokenPlace}/>
             </div>
             <div className="swapInputTitleRow">
               <h3 className='swapTitle'>TO RECEIVE</h3>
               <div className="swapHelperOptionsRow">
                 <span>{tokenToReceiveBalance + ' ' + tokenToReceive.key}</span>
               </div>
             </div>
             <div className='tokenToReceiveContainer'>
               <div className='tokenToSwapInnerContainer'>
                 <TokenModalSelect
                   setValue={setTokenToReceive}
                   value={tokenToReceive}
                   tokenAccs={tokenAccounts}
                   solanaBalance={solanaBalance}
                   connection={connection}
                   fetchToken={fetchToken}
                   options={tokenList}/>
               </div>
               <div className='tokenToSwapInnerContainer'>
                 <TextInput
                   disabled={true}
                   overrideContainerClassName={'textInputOverrideContainer'}
                   inputClassName="textInputOverride"
                   placeholder={tokenToReceiveBalance}
                   onBlur={getInitialRate}
                   value={tokenToReceiveAmount}
                   setValue={setTokenToReceiveAmount}/>
               </div>
             </div>
             <div className="swapSlippageContainer">
               <Property
                 title="Slippage"
                 className={'slippageText'}
                 helpTooltip={(
                   <span className="slippageTooltip">
                            Slippage is the difference between the expected price of a trade and the actual price at which the trade is executed.
                            <br/>
                            <br/>
                            Slippage occurs when the price of a token changes between the time a user submits a swap order <br/> and the time the transaction is executed on the blockchain.
                        </span>)}
                 dataTip={SWAP_TOOLTIP_ID} />
               <select
                 className="swapSlippageSelectInput"
                 value={slippage}
                 onChange={(e) => setSlippage(e.target.value)}>
                 <option value={0.3}>0.3%</option>
                 <option value={0.5}>0.5%</option>
                 <option value={1}>1%</option>
                 <option value={2}>2%</option>
                 <option value={3}>3%</option>
                 <option value={4}>4%</option>
                 <option value={5}>5%</option>
               </select>
             </div>
            <div className='swapButtonRow'>
               <Button
                 text={'Swap'}
                 buttonType={BUTTON_TYPE_NO_STYLE}
                 className={'swapPageSwapButton'}
                 disabled={swapDisabled}
                 onClick={handleSwap}/>
               {insufficientBalance && <h3 className='swapButtonInfoText'>{'Insufficent ' + tokenToSwap.key + ' Balance'}</h3>}
             </div>
            {hasValue(errorMessage?.url) &&
              <div className={'transactionTimedOutError'} onClick={() => openURL(errorMessage?.url)}>
                <span>{errorMessage?.errorMessage}</span>
              </div>}
             <br />
             <div className="swapInformationFooterRow">
               <div className="swapFooterTokenInfoContainer">
                 {hasValue(tokenToSwap) && tokenInfoBox(tokenToSwap)}
                 {hasValue(tokenToReceive) && tokenInfoBox(tokenToReceive)}
               </div>
               <div className="swapFooterCurrentSwapInfoContainer">
                 <div className="swapCurrentSwapInfoColumn">
                   <span>PRICE IMPACT</span>
                   <span>MINIMUM RECEIVED</span>
                   <span>SL FEE</span>
                 </div>
                 <div className="swapCurrentSwapInfoDivider" />
                 <div className="swapCurrentSwapInfoColumnLeft">
                   {priceImpactRender()}
                   <span>{hasValue(minimumAmount) ? minimumAmount?.toFixed(4) : 'AWAITING INPUT'}</span>
                   <span>{hasSLFee ? '0.1%' : 'NO FEE'}</span>
                 </div>
               </div>
             </div>
           </div>
         </div>
      </div>}
      {!detectMobile.isMobile() &&
        <video
          className='backgroundVideo'
          autoPlay
          loop
          playsInline

          muted>
          <source src={missionPageBackground} type='video/webm'/>
        </video>}
      {detectMobile.isMobile() && !detectMobile.isIos() &&
        <video
          className='backgroundVideo'
          autoPlay
          loop
          playsInline
          onLoadedData={() => setIsLoading(false)}

          muted>
          <source src={missionPageBackgroundMobile} type='video/webm' />
        <source src={missionPageBackgroundMobileMp4} type='video/mp4' />
      </video>}
      {detectMobile.isMobile() && detectMobile.isIos() &&
      <img className='backgroundVideo' src={missionPageBackgroundMobileMp4} alt="" />}
{/*       {videoOff && <img className='backgroundVideo' src={missionPageBackgroundImage} alt="" />} */}
      {isLoading && <LoadingSpinner />}
    </main>
  );
}



export default SwapPage;
