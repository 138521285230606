import classifiedBanner from '../../images/classified-banner.jpg';
import ProgressButtonHover from "../../Sounds/progress-button-hover.mp3"
import "./IdoCard.scss";
import { useSelector } from 'react-redux';
import TypeAnimation from 'react-type-animation';
import Button, { BUTTON_TYPE_PRIMARY_BRIGHT, ProgressButtonClickAudio } from '../Button/Button';
import React, {useRef, useState, useEffect, useCallback, useMemo} from 'react';
import moment from "moment";
import BigNumber from "bignumber.js";
import Countdown from "react-countdown";
import {PATH_IDO_DETAILS, PATH_NODE_SALE_DETAILS, PATH_PLAYER_DASHBOARD} from "../../App";
import {useHistory} from "react-router-dom";
import {IDO_STATUS_COMPLETED, IDO_STATUS_ONGOING, IDO_STATUS_UPCOMING, TBA_UNIX_DATE} from "../../constants/constants";
import LoadingSpinner, {LOADING_SPINNER_LARGE} from "../LoadingSpinner/LoadingSpinner";
import useMobileDetect from "use-mobile-detect-hook";
import {calculateBlockchain, hasValue, redirectToIdoDetails} from "../../Helpers/helperFunctions";
import {getIdoNodeTime, getNodeSaleStage} from "../../Helpers/idoHelper";
import {NODE_OG_SALE_STAGE} from "../../screens/IdoDetails/IdoDetails";

export const ProgressButtonHoverSound = new Audio(ProgressButtonHover);

function IdoCard(props) {
    const {
        idoInfo,
        onClick,
    } = props

    const detectMobile = useMobileDetect();
    const history = useHistory();

    const [isDecoded, setIsDecoded] = useState(!idoInfo.isMystery);
    const [decoding, setDecoding] = useState(false);
    const [currentStage, setCurrentStage] = useState('');
    const [showStaticBanner, setShowStaticBanner] = useState(true);

    const isMuted = useSelector((state) => state.sound.mute);

    const walletRef = useRef();
    const decodeButtonRef = useRef();

    const {
        name,
        description,
        bannerImages,
        bannerVideos,
        status,
        participants,
        idoTimes,
        poolstate,
        registrationStartTime,
        registrationEndTime,
        salesStartTime,
        salesEndTime,
        openSaleStartTime,
        isMystery = false,
        isNodeSale = false,
        isVotedProject = false,
        saleType,
        soldOut = false,
        hydrazinePrice,
        nodePrice,
    } = idoInfo;

    const swapRate = Number(idoInfo?.swaprate) !== 0 ? `${new BigNumber(1).div(new BigNumber(idoInfo?.swaprate)).toFormat(4)} ${idoInfo?.pair}` : 0;
    const poolCap = `${idoInfo.poolcap} ${idoInfo.symbol}`;
    const amount = `${idoInfo.dollarAmount} ${idoInfo.pair}`;
    const disabled = !idoInfo.enableDetailsPage || !hasValue(idoInfo.name);
    const blockchain = calculateBlockchain(idoInfo);

    const redirectToPlayerDashboard = useCallback((ido) => {
        const location = {
            pathname: `${PATH_PLAYER_DASHBOARD}`,
        }
        history.push(location);
    }, [history]);
    const _onClick = (event) => {
        if (walletRef.current && walletRef.current.contains(event.target)) {
            return redirectToPlayerDashboard();
        }

        if (decodeButtonRef.current && decodeButtonRef.current.contains(event.target)) {
            return decodeSignal();
        }

        if (!disabled) {
            if (!isMuted) {
                ProgressButtonClickAudio.play();
            }

            return onClick();
        }
    }

    const _onHover = () => {
/*        setShowStaticBanner(false);*/
        if (!isMuted) {
            ProgressButtonHoverSound.play();
        }
    }
    const decodeSignal = () => {
        setDecoding(true);
        setTimeout(() => {
            setDecoding(false);
            setIsDecoded(true);
        }, 5000)
    }

    const setIdoStatus = () => {
        let curTime = moment().unix();
        let stage;

        if (curTime < Number(registrationStartTime) ) {
            stage = status;
        } else if (curTime >= Number(registrationStartTime) && curTime < Number(registrationEndTime)) {
            stage = "Hydrazine Contribution";
        } else if (curTime >= Number(registrationEndTime) && curTime < Number(salesStartTime)) {
            stage = "Setting Allocations";
        } else if (curTime >= Number(salesStartTime) && curTime < Number(salesEndTime)) {
            stage = "USDC Contribution";
        } else if (curTime >= Number(salesEndTime) && curTime < Number(openSaleStartTime) && poolstate === 2) {
            stage = "Setting FCFS Allocations";
        } else if (curTime >= Number(openSaleStartTime) && poolstate === 2) {
            stage = "FCFS Sale";
        } else if (poolstate === 3) {
            stage = "Completed";
        } else if (poolstate === 4) {
            stage = "Claims Open";
        } else if (poolstate === 6) {
            stage = "REFUNDS_OPEN";
        } else if (status === IDO_STATUS_COMPLETED || isNodeSale) {
            stage = IDO_STATUS_COMPLETED;
        } else if (status === "Upcoming") {
            stage = "Upcoming";
        } else {
            stage = "Closed";
        }

        setCurrentStage(stage);
    }
    
    const setNodeIdoStatus = () => {
        const nodeStage = getNodeSaleStage(idoInfo);

        if (hasValue(nodeStage)) {
            return setCurrentStage(nodeStage);
        }

        setCurrentStage(status)
    }

    useEffect(() => {
        if (isNodeSale) {
            setNodeIdoStatus()
        } else {
            setIdoStatus();
        }
    }, []);

    const idoTimer = useCallback(() => {
            if (currentStage === "Ongoing") {
                return (
                    <span className='idoDetailsTimerCountdown'>
                        <Countdown date={new BigNumber(registrationStartTime.toString()).times(new BigNumber(1000)).toNumber(0)} />
                    </span>
                );
            }
            if (currentStage === "Upcoming") {
                return (
                    <span className='idoDetailsTimerCountdown'>
                        <Countdown date={new BigNumber(registrationStartTime.toString()).times(new BigNumber(1000)).toNumber(0)} />
                    </span>
                );
            }
            if (currentStage === "Hydrazine Contribution") {
                return (
                    <span className='idoDetailsTimerCountdown'>
                        <Countdown date={new BigNumber(registrationEndTime.toString()).times(new BigNumber(1000)).toNumber(0)} />
                    </span>
                );
            }
            if (currentStage === "Setting Allocations") {
                return (
                    <span className='idoDetailsTimerCountdown'>
                        <Countdown date={new BigNumber(salesStartTime.toString()).times(new BigNumber(1000)).toNumber(0)} />
                    </span>
                )
            }
            if (currentStage === "Setting FCFS Allocations") {
                return (
                    <span className='idoDetailsTimerCountdown'>
                        <Countdown date={new BigNumber(openSaleStartTime.toString()).times(new BigNumber(1000)).toNumber(0)} />
                    </span>
                )
            }
            if (currentStage === "Upcoming") {
                return (
                    <span className='idoDetailsTimerCountdown'>
                        <Countdown date={new BigNumber(registrationStartTime.toString()).times(new BigNumber(1000)).toNumber(0)} />
                    </span>
                );

            }
            if (currentStage === "USDC Contribution") {
                return (
                    <span className='idoDetailsTimerCountdown'>
                        <Countdown date={new BigNumber(salesEndTime.toString()).times(new BigNumber(1000)).toNumber(0)} />
                    </span>
                )
            }
            if (currentStage === "FCFS Sale") {
                return <span className='detailsValue'>Till Complete</span>;
            }
            if (currentStage === "Completed") {
                return <span className='detailsValue'>Till Claims Open</span>;
            }
            if (currentStage === "Claims Open") {
                return <span className='detailsValue'>Open Forever</span>;
            }
            if (currentStage === "REFUNDS_OPEN") {
                return <span className='detailsValue'>Refunds Open</span>;
            }

    }, [currentStage])

    const getTokenPrice =  useCallback(() => {
        if(name === "CLASSIFIED" || swapRate === 0) {
            return "CLASSIFIED"
        } else {
            return swapRate
        }
    }, [name, swapRate])

    const getDisplayDate =  useCallback(() => {
        if (isNodeSale) {
            const nodeSaleStart = getIdoNodeTime(idoInfo, NODE_OG_SALE_STAGE).start;
            if (!hasValue(nodeSaleStart)  || Number(nodeSaleStart) < TBA_UNIX_DATE) {
                return "TBA"
            } else {
                return  moment.unix(Number(nodeSaleStart)).format("MMMM Do YYYY")
            }
        }
        if (!hasValue(registrationStartTime)  || Number(registrationStartTime) < TBA_UNIX_DATE) {
            return "TBA"
        } else {
            return  moment.unix(Number(registrationStartTime)).format("MMMM Do YYYY")
        }
    }, [name, registrationStartTime])
 
    const idoFooterContent = () => {
        if (isNodeSale) {
            return (
              <div className="idoCardFooterDetailsContainer">
                  <div className="idoCardFooterDetailsTitleColumn">
                      <span>SALE TYPE</span>
                      {hasValue(hydrazinePrice) && <span>HYDRAZINE PRICE</span>}
                      <span>BLOCKCHAIN</span>
                      <span>Date:</span>
                      <span>NODE PRICE</span>
                  </div>
                  <div className="idoCardFooterDetailsDivider"/>
                  <div className="idoCardFooterDetailsValueColumn">
                      <span>NODE SALE</span>
                      {hasValue(hydrazinePrice) && <span>{hydrazinePrice}</span>}
                      <span>{blockchain}</span>
                      <span>{getDisplayDate()}</span>
                      <span>{nodePrice}</span>
                  </div>
              </div>
            )
        }

        return (
          <div className="idoCardFooterDetailsContainer">
              <div className="idoCardFooterDetailsTitleColumn">
                  <span>Raise amount:</span>
                  <span>Token price:</span>
                  <span>Chain:</span>
                  <span>Date:</span>
              </div>
              <div className="idoCardFooterDetailsDivider" />
              <div className="idoCardFooterDetailsValueColumn">
                  <span>{amount}</span>
                  <span>{getTokenPrice()}</span>
                  <span>{blockchain}</span>
                  <span>{getDisplayDate()}</span>
              </div>
          </div>
        );

        return (
          <>
              <div className='flex-column'>
                  <div className='valuePair'>
                      <span className='greyTitle'>RAISE AMOUNT</span>
                      <span className='value'>{amount}</span>
                  </div>
              </div>
              <div className='flex-column'>
                  <div className='valuePair'>
                      <span className='greyTitle'>TOKEN PRICE</span>
                      <span className='value'>{swapRate.includes('?') ? '-' : swapRate}</span>
                  </div>

              </div>
              <div className='flex-column'>
                  <div className='valuePair'>
                      <span className='greyTitle'>BLOCKCHAIN</span>
                      <span className='value'>{blockchain}</span>
                  </div>
              </div>
          </>
        )
    }

    const statusColorCalc = useMemo(() => {
        if (currentStage === 'Closed') {
            return '#F77F00';
        }
        if (status === IDO_STATUS_ONGOING) {
            return '#48CAE4';
        }
        if (status === IDO_STATUS_UPCOMING) {
            return '#62C370'
        }
        if (status === IDO_STATUS_COMPLETED) {
            return '#62C370'
        }
    }, [status, currentStage])

    const saleTypeHeader= () => {
        if (isVotedProject === true) {
            return (<div className="launchVoteSaleBox">COMMUNITY VOTED SALE</div>);
        }
        if (saleType?.toUpperCase() === 'PUBLIC') {
            return (<div className="publicSaleBox">PUBLIC ROUND</div>);
        }
        if (saleType?.toUpperCase() === 'PRIVATE') {
            return (<div className="privateSaleBox">PRIVATE ROUND</div>);
        }
        if (saleType?.toUpperCase() === 'LAUNCH_VOTE') {
            return (<div className="launchVoteSaleBox">PROJECT LAUNCH VOTE</div>);
        }
    }

    return (
      <div className={`idoCardContainer${disabled ? 'Disabled' : ''}`} onClick={_onClick} onMouseEnter={_onHover} onMouseLeave={() => setShowStaticBanner(true)}
           tabIndex={0} key={name}>
          {!isDecoded && <div className="idoCardHeaderDecode">
              <div className='decodingTextContainer'>
                  {decoding ?
                    <TypeAnimation
                      cursor={false}
                      sequence={['Decoding...', 500, '']}
                      wrapper="span"
                      repeat={Infinity}
                        />
                        : <TypeAnimation
                            cursor={false}
                            sequence={['Incoming Signal...', 100]}
                            wrapper="span"
                            repeat={1}
                        />}
                </div>
              <div ref={decodeButtonRef}>
                  <Button text="Decode Signal" onClick={decodeSignal} buttonType={BUTTON_TYPE_PRIMARY_BRIGHT}  />
              </div>
            </div>}
            {isDecoded && <>
                <div className="idoCardHeader">
                    {saleTypeHeader()}
                    {soldOut && <div className="soldOutBanner">SOLD OUT</div>}
                   <img  className={showStaticBanner ? 'idoCardBannerVideo' : 'idoCardBannerVideoOpacity' } src={bannerImages?.length > 0 ? bannerImages[0] : classifiedBanner} />
                    <div className="idoCardTitle">{name}</div>
                </div>
                <div className="idoCardBody">
                    {isMystery ?
                      <TypeAnimation
                        cursor={false}
                        sequence={[description, 1000]}
                        wrapper="span"
                        repeat={1}
                      />
                      : description}
                </div>
                <br/>
                <div>
                    {disabled && name !== 'CLASSIFIED' &&
                      <div className='valuePair'>
                                    <span className='idoDisabledText'>
                                        Details not yet available
                                    </span>
                      </div>
                    }
                </div>
                <div className="flexGrower" />
                <div className="idoStatusAndTimerContainer">
                    {(currentStage !== "Closed" && currentStage !== "Ongoing" && currentStage !== IDO_STATUS_COMPLETED && !isNodeSale && poolstate > 1 && status !== "Upcoming") && <div className="idoStatusContainer">
                        <span className="idoStatusTitle">WINDOW CLOSES IN:</span>
                        <span className="idoStatusValue">{idoTimer()}</span>
                    </div>}
                    <div className="idoStatusContainer">
                        <span className="idoStatusTitle">STATUS:</span>
                        <span className="idoStatusValue"
                              style={{color: statusColorCalc}}>{currentStage?.toUpperCase()}</span>
                    </div>
                </div>
                <div className="idoCardFooter">
                    {idoFooterContent()}
                </div>
            </>}
      </div>
    );
}

export default IdoCard;
