import React from 'react';
import TwoTokenModelIcon from '../../images/svg/twoTokenModel.svg';
import NoTiersIcon from '../../images/svg/noTiers.svg';
import AntiWhaleIcon from '../../images/svg/antiWhale.svg';
import FlexibleStakingIcon from '../../images/svg/flexibleStaking.svg';
import MinimumContributionIcon from '../../images/svg/minimumContribution.svg';
import FullUserControlIcon from '../../images/svg/fullUserControl.svg';
import TelegramAnnouncementIcon from '../../images/svg/telegramAnnouncement.svg';
import TelegramChatIcon from '../../images/svg/telegramChat.svg';
import MediumIcon from '../../images/svg/medium.svg';
import TwitterIcon from '../../images/svg/twitter.svg';
import DiscordIcon from '../../images/svg/discord.svg';
import GitBookIcon from '../../images/svg/gitbook.svg';
import BackIcon from '../../images/svg/caretBackwards.svg';
import useMobileDetect from 'use-mobile-detect-hook';
import "./HomeInformation.scss";
import Button, { ButtonClickAudio, BUTTON_TYPE_PRIMARY_BRIGHT, BUTTON_TYPE_SECONDARY, BUTTON_TYPE_PRIMARY } from '../../Components/Button/Button';
import PageSelector from '../../Components/PageSelector/PageSelector';
import { useState } from 'react';
import { useRef, useEffect } from 'react';
import { useCallback } from 'react';
import MobileMenuOption from '../../Components/MobileMenu/MobileMenuOption';
import { useDispatch, useSelector } from 'react-redux';
import { setInformationModalState, toggleShowApplyForm, toggleShowMobileMenu } from '../../Components/Leftnav/modal';
import Apply from '../../Components/Apply/Apply';
import { PROD_FRACTAL_URL } from '../../constants/constants';
import { hex } from '@project-serum/anchor/dist/cjs/utils/bytes';
import { getApiUrl, getFractalUrl } from '../../Helpers/helper';
import toast from 'react-hot-toast';
import { errorToast, successToast } from '../../Helpers/toastCSS';
import { createCadetAccount } from '../../Helpers/idoHelper';
import { PATH_PLAYER_DASHBOARD } from '../../App';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const PAGE_HOME = 'PAGE_HOME';

export const PAGE_UNIQUE_SELLING_POINT = 'PAGE_UNIQUE_SELLING_POINT';
export const PAGE_SOCIAL_MEDIA = 'PAGE_SOCIAL_MEDIA';
export const PAGE_FAQ = 'PAGE_FAQ';
export const PAGE_CADET_GUIDE = 'PAGE_CADET_GUIDE';
export const PAGE_KYC_INFO = 'PAGE_KYC_INFO'
export const PAGE_PROJECT_APPLY = 'PAGE_PROJECT_APPLY';
export const PAGE_TERMS_OF_USE = 'PAGE_TERMS_OF_USE';

const pageSelectorOptions = [
    {
        key: PAGE_UNIQUE_SELLING_POINT,
        label: 'About',
    },
    {
        key: PAGE_SOCIAL_MEDIA,
        label: 'Social Media',
    },
    {
        key: PAGE_FAQ,
        label: 'FAQ',
    },
    {
        key: PAGE_CADET_GUIDE,
        label: 'Cadet Guide',
    },
    {
        key: PAGE_KYC_INFO,
        label: 'KYC Info',
    },
    {
        key: PAGE_PROJECT_APPLY,
        label: 'Project Apply',
        onlyDesktop: true,
    },
    {
        key: PAGE_TERMS_OF_USE,
        label: 'Terms of Use',
    },
]

const openURL = (url) => {
    window.open(url);
}

function HomeInformation(props) {
   const {
        close,
        kyc,
        wallet
    } = props;

    const mobileDetect = useMobileDetect();
    const dispatch = useDispatch();

    const isMobile = mobileDetect.isMobile();
    const isMuted = useSelector((state) => state.sound.mute);
    const showApply = useSelector((state) => state.modal.showApplyForm);
    const startPage = useSelector((state) => state.modal.informationState.startPage);

    const calcStartPage = () => {
        if (startPage) {
            return startPage;
        }

        if (isMobile) {
            return PAGE_HOME;
        }

        return PAGE_UNIQUE_SELLING_POINT;
    }
 
    const [page, setPage] = useState(calcStartPage());

    const containerRef = useRef();
    const selectorRef = useRef();

    const hasHome = pageSelectorOptions.find(option => option.key === PAGE_HOME);

    if (isMobile && !hasHome) {
        pageSelectorOptions.push({
             key: PAGE_HOME,
             label: 'Home',
             visible: false,
         })
     }

    const backToMenu = useCallback(() => {
        dispatch(setInformationModalState({ show: false, startPage: null }));
        dispatch(toggleShowMobileMenu());
    }, [dispatch])

    const handleClickOutside = useCallback((event) => {
        if (containerRef.current && !containerRef.current.contains(event.target) && !showApply) {
            if (selectorRef.current && !selectorRef.current.contains(event.target)) {
                dispatch(setInformationModalState({ show: false, startPage: null }));
            }
        }
    }, [containerRef, selectorRef, showApply])

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showApply]);

    const currentPageObject = pageSelectorOptions.find(option => option.key === page);

    if (isMobile) {
        return (
        <div
            className='homeInformation'>
          
            <div className={`homeInformationContainer ` + currentPageObject?.label.replaceAll(' ', '') + 'Misc'} ref={containerRef}>
                {page === PAGE_HOME && 
                <>  
                    <>
                        {pageSelectorOptions.map(option => {
                            if (!option.onlyDesktop && option?.visible !== false) {
                                return (
                                    <MobileMenuOption 
                                        key={option.label} 
                                        label={option.label} 
                                        optionKey={option.key} 
                                        onClick={() => setPage(option.key)} />
                                )
                            }
                           
                        })}
                    </>
                    <div className='flex-divider' />
                    <div className='backButton' onClick={backToMenu}>
                        <BackIcon className='backButtonIcon'/>
                        <span>BACK TO MENU</span>
                    </div>
                </>}
                {page === PAGE_UNIQUE_SELLING_POINT && <UPSContent isMobile />}
                {page === PAGE_SOCIAL_MEDIA && <SocialMediaContent />}
                {page === PAGE_FAQ && <FAQContent />}
                {page === PAGE_CADET_GUIDE && <CadetGuideContent />}
                {page === PAGE_TERMS_OF_USE && <TermsOfUseContent /> }
            </div>
            <div className='flex-divider' />
            {page !== PAGE_HOME && 
                <div className='backButtonHome' onClick={() => setPage(PAGE_HOME)}>
                    <BackIcon className='backButtonIcon'/>
                    <span>BACK</span>
                </div>}
        </div>
        )
    }

  return (
      <div
        className='homeInformation'>
        <PageSelector value={page} setValue={setPage} options={pageSelectorOptions} selectorRef={selectorRef} />
        <div className={`homeInformationContainer ` + currentPageObject.label.replaceAll(' ', '') + 'Misc'} ref={containerRef}>
            {page === PAGE_UNIQUE_SELLING_POINT && <UPSContent />}
            {page === PAGE_SOCIAL_MEDIA && <SocialMediaContent />}
            {page === PAGE_FAQ && <FAQContent />}
            {page === PAGE_CADET_GUIDE && <CadetGuideContent />}
            {page === PAGE_KYC_INFO && <KYCInfoContent wallet={wallet}  kyc={kyc} />}
            {page === PAGE_PROJECT_APPLY && <ProjectApplyContent dispatch={dispatch} isMuted={isMuted} />}
            {page === PAGE_TERMS_OF_USE && <TermsOfUseContent /> }
        </div>
      </div>
  );
}

export default HomeInformation;

export function UPSContent() {

    const mobileDetect = useMobileDetect();

    if (mobileDetect.isMobile()) {
        return (
            <>
                <div className='UPSBox'>
                    <TwoTokenModelIcon className='UPSIcon' />
                    <span className='UPSText'>Unique 2 token model</span>
                </div>
                <div className='UPSBox'>
                    <FlexibleStakingIcon className='UPSIcon' />
                    <span className='UPSText'>Incredible staking flexibility</span>
                </div>
                <div className='UPSBox'>
                    <NoTiersIcon className='UPSIcon' />
                    <span className='UPSText'>No tiers</span>
                </div>
                <div className='UPSBox'>
                    <MinimumContributionIcon className='UPSIcon' />
                    <span className='UPSText'>No minimum contribution</span>
                </div>
                <div className='UPSBox'>
                    <AntiWhaleIcon className='UPSIcon' />
                    <span className='UPSText'>Anti-whale system</span>
                </div>
                <div className='UPSBox'>
                    <FullUserControlIcon className='UPSIcon' />
                    <span className='UPSText'>Full user control of project investments</span>
                </div>
            </>
        )
    }

    return (
        <>
             <div className='flex-column'>
                    <div className='flex-row'>
                        <TwoTokenModelIcon className='UPSIcon' />
                        <span className='UPSText'>Unique 2 token model</span>
                    </div>
                    <div className='flex-row'>
                        <NoTiersIcon className='UPSIcon' />
                        <span className='UPSText'>No tiers</span>
                    </div>
                    <div className='flex-row'>
                        <AntiWhaleIcon className='UPSIcon' />
                        <span className='UPSText'>Anti-whale system</span>
                    </div>
                </div>
                <div className='column'>
                    <div className='flex-row'>
                        <FlexibleStakingIcon className='UPSIcon' />
                        <span className='UPSText'>Incredible flexibility and choice for staking</span>
                    </div>
                    <div className='flex-row'>
                        <MinimumContributionIcon className='UPSIcon' />
                        <span className='UPSText'>No minimum contribution</span>
                    </div>
                    <div className='flex-row'>
                        <FullUserControlIcon className='UPSIcon' />
                        <span className='UPSText'>Full user control of project investments</span>
                    </div>
                </div>
        </>
    )
}

function SocialMediaContent() {

    return (
        <div className='socialMediaOuterContainer'>
            <span className='socialMediaTitle'>FOLLOW SOCIAL MEDIA FOR UPDATES</span>
            <div className='socialMediaContainer'>
                <div className='socialMediaBox' onClick={() => openURL('https://medium.com/@StarLaunch')}>
                    <MediumIcon className='socialMediaIcon' />
                    <span className='socialName'>MEDIUM</span>
                    <span className='socialTag'>@StarLaunch</span>
                </div>
                <div className='socialMediaBox' onClick={() => openURL('https://twitter.com/StarLaunchSOL')}>
                    <TwitterIcon className='socialMediaIcon' />
                    <span className='socialName'>TWITTER</span>
                    <span className='socialTag'>@StarLaunchSOL</span>
                </div>
                <div className='socialMediaBox' onClick={() => openURL('https://discord.gg/invite/StarlaunchSOL')}>
                    <DiscordIcon className='socialMediaIcon' />
                    <span className='socialName'>Discord</span>
                    <span className='socialTag'>starlaunch</span>
                </div>
            </div>
        </div>
    )
}

function FAQContent() {
    return (
        <div className='FAQContainer'>  
            <span className='CadetGuideTitle'>FAQ</span> 
            <div className='FAQContent'>
                <span className='FAQTitle'>1: What is StarLaunch?</span>
                <span className='FAQDescription'>StarLaunch is the first insured launchpad and project incubator for Solana. Our goal is to connect our community of backers with trusted and thoroughly vetted Solana blockchain projects.</span>
                <span className='FAQTitle'>2: Does StarLaunch have tiers?</span>
                <span className='FAQDescription'>
                    Please note that there are no tiers. For a more detailed explanation, see the <a className='FAQLink' href='https://bit.ly/3IZm4F6' target="_blank">Basic Training article. </a>
                    It provides a good overview of how users can participate in sales on StarLaunch.
                </span>
                <span className='FAQTitle'>3: Where can I buy the $STARS token?</span>
                <span className='FAQDescription'>
                    Our token $STARS is available on Raydium and PRISM.
                </span>
                <span className='FAQTitle'>4: Is KYC needed to participate in sales on StarLaunch?</span>
                <span className='FAQDescription'>
                    Yes. While staking STARS and producing $N2H4 doesn't require KYC, participating in sales on our platform does require KYC. 
                    KYC is handled by our partner Fractal and you can complete your KYC by following this <a className='FAQLink' href='https://bit.ly/3pFz8bp' target="_blank">link</a>
                    <br />
                    <br />
                    <strong>Residents of the following countries (and province) are prohibited from participating in StarLaunch IDOs: </strong>
                    <br/>
                    United States, Canada, Afghanistan, Central African Republic, 
                    Congo-Brazzaville, Congo-Kinshasa, Cuba, Eritrea, Guinea-Bissau, Iran,
                    Iraq, Lebanon, Libya, North Korea, Namibia, Mali, Somalia, South Sudan, 
                    Sudan, Venezuela, Yemen, Syria, Tajikistan
                </span>
                <span className='FAQTitle'>5: What are the different stages during a sale?</span>
                <span className='FAQDescription'>
                    Each sale consists of 3 phases:
                    <br/>
                    1) The $N2H4 Burn window - During this window, everyone that's KYC verified and on the whitelist, will be able to burn $N2H4. Your burned $N2H4 relative to the total amount of $N2H4 decides how many IDO tokens you're allowed to purchase during the whitelist sale.
                    <br/>
                    2) The whitelist sale - During this window, you can pay for your allocation that you secured by burning $N2H4 during the Burn window.
                    <br/>
                    3) The FCFS Sale. If there's any amount left unpaid for after the whitelist sale, all that's left will be sold on a FCFS basis. FCFS Sale is open to those that burned $N2H4 during the IDO. Each IDO has a FCFS cap per wallet and this can be found on the IDO page.
                </span>
                <span className='FAQTitle'>6: I want to buy STARS tokens on Raydium or Symmetry, but I do not have USDC in my Phantom wallet. What is the easiest way to acquire some USDC? </span>
                <span className='FAQDescription'>
                    If you have an account on Binance, Kucoin, or another CEX that supports bridging to Solana, you could acquire an amount of SOL there. 
                    Next, you would transfer your SOL to your Solana-native non-custodial wallet. We recommend using Phantom. Once you have done this, you can swap some of your SOL for USDC.
                    <br />
                    Note: it is important to keep a bit of SOL in your wallet at all times to cover tx fees.
                </span>
                <span className='FAQTitle'>7: How do I register a Cadet ID, and what is the Cadet dashboard for?</span>
                <span className='FAQDescription'>
                    First, navigate to the Player Dashboard. Connect your wallet, and then hit the 'Create Account' button.
                </span>
                <span className='FAQTitle'>8: Where can I find upcoming IDO's?</span>
                <span className='FAQDescription'>You can find all our upcoming IDO's in the "Upcoming" section in the IDO page</span>
                <span className='FAQTitle'>9: What do I need $N2H4 for?</span>
                <span className='FAQDescription'>
                    $N2H4 is a utility token that, when burned during a sale, determines how many tokens you're allowed to purchase in a whitelist sale. 
                    The more $N2H4 you burn relative to the total amount of $N2H4 burned, the more tokens you can purchase during the whitelist sale. 
                    The max that can be burned per wallet is 1% of the total amount.
                </span>
                <span className='FAQTitle'>10: How do I generate $N2H4 and how much do I get?</span>
                <span className='FAQDescription'>
                    You start generating $N2H4 when you create a Fusion vault and stake your stars. 
                    $N2H4 is emitted at 540 per hour and spread over all $STARS that are staked in the Fusion vault.
                </span>
                <span className='FAQTitle'>11: Can I buy/sell $N2H4?</span>
                <span className='FAQDescription'>
                    Yes, you can buy/sell $N2H4 on Raydium. While someone has added liquidity on Raydium, this is not an official pool. 
                    As with any financial decision, you should DYOR prior to using the unofficial Raydium pool to buy or sell Hydrazine.
                </span>
            </div>
        </div>
    )
}

function CadetGuideContent() {
    return (
        <div className='CadetGuideContainer'>  
            <span className='CadetGuideTitle'>CADET GUIDE</span> 
            <span className='CadetGuideText'>For a more indept and thourogh guide please visit our Gitbook</span>
            <Button text="View on" Icon={GitBookIcon} buttonType={BUTTON_TYPE_PRIMARY_BRIGHT} onClick={() => openURL('https://starlaunch.gitbook.io/starlaunch-a-cadets-guide/')} />
        </div>
    )
}

function kycStatus(wallet, kyc) {

    const API_URL = getApiUrl();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const history = useHistory();

    // Call backend URL using code
    const fetchData = async () => {
        try {
            const response = await fetch(API_URL + `/kyc/refresh?wallet=${wallet}`);
            const data = await response.json();

            // Process the data from the backend
            if(data?.data?.kyc) {
                toast("KYC Verified", successToast);
                window.location.reload();
            } else {
                toast("KYC Verification Pending", errorToast);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    switch(kyc) {
        case 0: 
            return  <>
                    <Button text="Start Verification" buttonType={BUTTON_TYPE_PRIMARY_BRIGHT} onClick={() => openURL(getFractalUrl())} />
                    <Button text="Refresh KYC" buttonType={BUTTON_TYPE_PRIMARY_BRIGHT} onClick={() => fetchData()} />
                </> 
        case 1:
            return <Button text="KYC Verified" className="kycSuccessButton" buttonType={BUTTON_TYPE_SECONDARY} onClick={() => {}} />
        case 2:
            return <>
                    <Button text="Start Verification" buttonType={BUTTON_TYPE_PRIMARY_BRIGHT} onClick={() => openURL(getFractalUrl())} />
                </>
        case 3:
            return <>
                    <Button text="Start Verification" buttonType={BUTTON_TYPE_PRIMARY_BRIGHT} onClick={() => openURL(getFractalUrl())} />
                    <Button text="Refresh KYC" buttonType={BUTTON_TYPE_PRIMARY_BRIGHT} onClick={() => fetchData()} />
                </>
        case 4:
            return <>
                    <Button text="Create Cadet Account"  buttonType={BUTTON_TYPE_PRIMARY_BRIGHT} onClick={() => history.push(PATH_PLAYER_DASHBOARD)} />
                </>
    }
}

function KYCInfoContent({ wallet, kyc }) {
    return (
        <div className='CadetGuideContainer'>  
            <span className='CadetGuideTitle'>KYC</span> 
            <span className='CadetGuideText'>
                To be able to participate in sales on our platform you need to verify your wallet at Fractal. 
                <br />
                If you have already done it you can also check the status of your KYC verifcation below.
                </span>                     
            <div className='kycButtonRow'>
                {
                    wallet && wallet.toString() ?
                    kycStatus(wallet, kyc) :
                    <Button text="Connect Wallet to See KYC Status" buttonType={BUTTON_TYPE_PRIMARY_BRIGHT} onClick={() => {}} />
                }
            </div>
        </div>
    )
}

function ProjectApplyContent(props) {
    const {
        isMuted,
        dispatch,
    } = props;

    const _toggleShowApply = useCallback(() => {
        if (!isMuted) {
          ButtonClickAudio.play();
        }
    
        dispatch(toggleShowApplyForm());
      }, [isMuted, ButtonClickAudio, dispatch]);

    return (
        <div className='CadetGuideContainer'>
            <span className='CadetGuideTitle'>APPLY YOUR SOLANA PROJECT</span> 
            <span className='CadetGuideText'>
            Are you developing a Solana project? 
            <br />
            Send us an email, we'd love to hear more about your project! 
            <br />
            <br />
            <a className="applyMailText" href="mailto:info@starlaunch.io">info@starlaunch.com</a>
            </span>

            {/*  <Button text="APPLY" buttonType={BUTTON_TYPE_PRIMARY_BRIGHT} onClick={_toggleShowApply} /> */}

        </div>
    )
}

export function TermsOfUseContent() {
    return (
        <div className='termsOfUseContainer'>
            <h2 className='title'>TERMS OF USE</h2>
            <pre className='text'>{`StarLaunch
User Agreement
November 8, 2021
            
OUR SERVICE ARE NOT OFFERED TO PERSONS OR ENTITIES WHO RESIDE IN,
ARE CITIZENS OF, ARE LOCATED IN, ARE INCORPORATED IN, OR HAVE A
REGISTERED OFFICE IN THE UNITED STATES OF AMERICA (COLLECTIVELY, “US
PERSONS”). MOREOVER, NONE OF OUR OTHER SERVICES ARE OFFERED TO
PERSONS OR ENTITIES WHO RESIDE IN, ARE CITIZENS OF, ARE LOCATED IN,
ARE INCORPORATED IN, OR HAVE A REGISTERED OFFICE IN ANY RESTRICTED
TERRITORY, AS DEFINED BELOW (ANY SUCH PERSON OR ENTITY FROM A
RESTRICTED TERRITORY, A “RESTRICTED PERSON”). WE DO NOT MAKE
EXCEPTIONS; THEREFORE, IF YOU ARE A US PERSON, THEN DO NOT ATTEMPT
TO USE OUR SERVICE OR SITE AND IF YOU ARE A RESTRICTED PERSON, THEN
DO NOT ATTEMPT TO USE ANY OF THE SERVICES, AS DEFINED BELOW.

United States, Canada, Afghanistan, Central African Republic, 
Congo-Brazzaville, Congo-Kinshasa, Cuba, Eritrea, Guinea-Bissau, Iran,
Iraq, Lebanon, Libya, North Korea, Namibia, Mali, Somalia, South Sudan, 
Sudan, Venezuela, Yemen, Syria, Tajikistan

Welcome to StarLaunch! These terms and conditions (the "Agreement") should be read
by you (the "User" or "you") in its entirety prior to your use of StarLaunch's service or
products. Be aware that this Agreement constitutes a legally binding agreement
between you and StarLaunch (referred to herein as "StarLaunch", "us" or "we") which
owns and operates the website on the Internet and the Service (the "Service") at
www.StarLaunch.com (“Site”). You consent to adhering to all the terms set forth in this
Agreement when/if you are provided with an “I Agree” button and clicking on it or by
using or accessing the .

1. GENERAL USE AND GRANT OF LICENSE
1.1. StarLaunch grants the User a non-exclusive, personal, non-transferable right to
use the Service on your personal computer or other device that accesses the
Internet in order to access and use the services available and described on the
StarLaunch.com website (the website and all its functionality together being the
"Service"), subject to the terms and conditions contained herein.
1.2. The Service is not for use by (i) minors and individuals under the age of 18 years,
(ii) individuals who can be considered minors or under the age of majority
adulthood in their specific jurisdiction(s) (iii) individuals accessing or using the
Service from any jurisdiction in which it is illegal to do so. StarLaunch does not
have the ability to verify the legality of the Service in every jurisdiction, therefore it
is entirely up to the User to determine whether or not their use of the Service is
lawful.
1.3. StarLaunch and its licensors are the sole holders of all rights in and to the Service
and code, technology, organization and structure, architecture, including copyright,
trade secrets, intellectual property and other rights. You may not: (a) copy, create
derivative works, distribute, publish, reverse engineer, decompile, disassemble,
modify, or translate the StarLaunch website or the Service; or (b) use the Service 
that in any way is prohibited by applicable laws or regulations (each of the above
herein defined as an "Unauthorized Use").
1.4. StarLaunch reserves any and all rights implied or otherwise, which are not
expressly granted to the User hereunder and retain all rights, title and interest in
and to the Service. You agree that you will be solely liable for any damage, costs
or expenses arising out of or in connection with the commission by you of any
Unauthorized Use. You shall notify StarLaunch immediately upon becoming aware
of the commission by any person of any Unauthorized Use and shall provide
StarLaunch with reasonable assistance with any investigations it conducts in light
of the information provided by you in this respect.
1.5. The term "StarLaunch", its domain names and any other trade marks, or service
marks used by StarLaunch as part of the Service (the "Trade Marks"), are solely
owned by StarLaunch. In addition, all content on the website, including, but not
limited to, the images, pictures, graphics, photographs, animations, videos, music,
audio and text (the "Site Content") belongs to StarLaunch and is protected by
copyright and/or other intellectual property or other rights. You hereby
acknowledge that by using the Service, you obtain no rights in the Site Content
and/or the Trade Marks, or any part thereof. Under no circumstances may you use
the Site Content and/or the Trade Marks without StarLaunch’s prior written
consent. Additionally, you agree not to do anything that will harm or potentially
harm the rights, including the intellectual property rights of StarLaunch.
1.6. Taxes. It is your sole responsibility to determine whether, and to what extent, any
taxes apply to any transactions you conduct through the StarLaunch Services, and
to withhold, collect, report and remit the correct amounts of taxes to the
appropriate tax authorities. Your transaction history is available through your
StarLaunch Account(s).
1.7. No Investment Advice or Brokerage. For the avoidance of doubt, StarLaunch does
not provide investment, tax, or legal advice, nor does StarLaunch broker trades on
your behalf. All StarLaunch trades are executed automatically, based on the
parameters of your order instructions and in accordance with posted Trade
execution procedures, and you are solely responsible for determining whether any
investment, investment strategy or related transaction is appropriate for you based
on your personal investment objectives, financial circumstances and risk
tolerance. You should consult your legal or tax professional regarding your specific
situation. StarLaunch may provide educational information about Digital Currency,
as well as Digital Currency not supported by StarLaunch, in order to assist users in
learning more about such Digital Currency. Information may include, but is not
limited to, blog posts, articles, links to third-party content, news feeds, tutorials,
and videos. The information provided on this website or any third-party sites does
not constitute investment advice, financial advice, trading advice, or any other sort
of advice, and you should not treat any of the website's content as such.
StarLaunch does not recommend that any Digital Currency should be bought, 
earned, sold, or held by you. Before making the decision to buy, sell or hold any
Digital Currency, you should conduct your own due diligence and consult your
financial advisors before making any investment decision. StarLaunch will not be
held responsible for the decisions you make to buy, sell, or hold Digital Currency
based on the information provided by StarLaunch.

2. NO WARRANTIES
StarLaunch disclaims any and all warranties, expressed or implied, in connection with
the Service which is provided to you "as is" and we provide you with no warranty or
representation whatsoever regarding its quality, fitness for purpose, completeness or
accuracy. Regardless of StarLaunch’s efforts, StarLaunch makes no warranty that the
service will be uninterrupted, timely or error-free, or that defects will be corrected.

3. OPERATION OF DIGITAL CURRENCY PROTOCOLS.
We do not own or control the underlying software protocols which govern the operation
of Digital Currency reference or purchased though the Site or Service. Generally, the
underlying protocols are open source, and anyone can use, copy, modify, and distribute
them. We assume no responsibility for the operation of the underlying protocols and we
are not able to guarantee the functionality or security of network operations. In
particular, the underlying protocols may be subject to sudden changes in operating rules
(including “forks”). Any such material operating changes may materially affect the
availability, value, functionality of the Digital Currency you acquire. StarLaunch does not
control the timing and features of these material operating changes. It is your
responsibility to make yourself aware of upcoming operating changes and you must
carefully consider available information in determining whether to continue to use a
Service or Site for the affected Digital Currency. In the event of any such operational
change. You acknowledge and accept the risks of operating changes to Digital Currency
protocols and agree that StarLaunch is not responsible for such operating changes and
not liable for any loss of value you may experience as a result of such changes in
operating rules. You acknowledge and accept that StarLaunch has sole discretion to
determine its response to any operating change and that we have no responsibility to
assist you with currencies or protocols.

4. AUTHORITY / TERMS OF SERVICE
You agree to the rules of the Service provided and described on the StarLauch.com
website. StarLaunch retains all authority over the issuing, maintenance, and closing of the
Service. The decision of StarLaunch’s management, concerning any use of the Service, or
dispute resolution, is final and shall not be open to review or appeal.
5. YOUR REPRESENTATIONS AND WARRANTIES
5.1. there is a risk of losing cryptocurrency & other funds of value when using the
Service and StarLaunch has no responsibility to you for any such loss;
5.2. your use of the Service is at your sole option, discretion and risk;
5.3. you are solely responsible for any applicable taxes which may be payable on
cryptocurrency traded or transacted by you through your using the Service;
5.4. the telecommunications networks, blockchain networks (such as Solana and
Ethereum) and Internet access services required for you to access and use the
Service are entirely beyond the control of StarLaunch and StarLaunch shall have
no liability whatsoever for any outages, slowness, capacity constraints or other
deficiencies affecting the same; and
5.5. you are (i) aged 18 or over, (ii) you are of the age of majority in your jurisdiction,
(iii) you are accessing the Service from a jurisdiction in which it is legal to do so,
(iv) you are not a Politically Exposed Person (PEP) nor are you on any U.S.,
Canada or EU Sanctions lists or terrorism finance watchlists, and (v) that you will
inform StarLaunch immediately if you enter onto one of these lists or change your
residence to a prohibited jurisdiction.
5.6. You warrant that should you discover a bug or error that may lead to an exploit of
the Service or other loss of funds from StarLaunch, you have the responsibility to
notify StarLaunch at info@StarLaunch.com in a timely manner. Furthermore, any
attempt by you to use such a discovered exploit or bug for illicit financial gain is
illegal and strictly prohibited under this Agreement. StarLaunch reserves the right
to prosecute to the fullest extent of the law anyone who exploits or attempts to
exploit the Service in an unlawful manner. Such exploits or bugs should be
disclosed in a responsible manner and in strict confidence
with info@StarLaunch.com and no other entity.
6. PROHIBITED USES
Prior to your use of the Service and on an ongoing basis you represent, warrant,
covenant and agree that:
6.1. PERSONAL USE. The Service is intended solely for the User’s personal use. The
User is only allowed to use the Service for personal use, and may not create
multiple accounts, including for the purpose of collusion and/or abuse of service.
6.2. EXPLOITS & BUGS. The Service is provided as is, and any exploitation of the
Service or errors in program logic and/or code (bugs) constitutes a Prohibited Use
and a material breach of this Agreement. Any funds the user may misappropriate
in this manner pursuant to this clause shall be considered void and the immediate
property of StarLaunch, without limit.
6.3. JURISDICTIONS. Persons located in or residents of the United States, North
Korea, Iran, Venezuela or any other jurisdiction in which it is prohibited from using
the Service (the ”Prohibited Jurisdictions”) are not permitted to make use of the
Service. For the avoidance of doubt, the foregoing restrictions on Service from
Prohibited Jurisdictions applies equally to residents and citizens of other nations
while located in a Prohibited Jurisdiction. Any attempt to circumvent the restrictions 
on usage by any persons located in a Prohibited Jurisdiction or Restricted
Jurisdiction, is a breach of this Agreement. An attempt at circumvention includes,
but is not limited to, manipulating the information used by StarLaunch to identify
your location and providing StarLaunch with false or misleading information
regarding your location or place of residence.
Any and all monies (including cryptocurrencies) of a person located in a Prohibited
Jurisdiction on the Service are VOID, and can be confiscated or may be returned to the
person at StarLaunch’s sole discretion.

7. KNOW YOUR CUSTOMER (“KYC”) AND ANTI-MONEY LAUNDERING (“AML”)
POLICY
7.1. StarLaunch reserves the right, at any time, to ask for any KYC documentation it
deems necessary to determine the identity and location of a User. StarLaunch
reserves the right to restrict service and payment until identity is sufficiently
determined.
7.2. StarLaunch further reserves the right to share submitted KYC information and
documentation to 3rd parties to verify the authenticity of submitted information,
and the end user (you) agree to this by using the Service.
7.3. StarLaunch reserves the right to confiscate any and all funds that are found to be
in violation of relevant and applicable anti-money laundering (AML) and countering
terrorism financing (CFT) laws and regulations, and to cooperate with the
competent authorities when and if necessary.
AML & SANCTIONS COMPLIANCE.
StarLaunch expressly prohibits and rejects the use of the Service for any form of illicit
activity, including money laundering, terrorist financing or trade sanctions violations,
consistent with various jurisdictions’ laws, regulations and norms. To that end, the Service
is not offered to individuals or entities on any Politically Exposed Persons (PEP) lists, or
subject to any United States, European Union, or other global sanctions or watch lists. By
using the Service, end users represent and warrant that they are not on any such lists.

8. BREACH
8.1. Without prejudice to any other rights, if a User breaches in whole or in part any
provision contained herein, StarLaunch reserves the right to take such action as it
sees fit, including terminating this Agreement or any other agreement in place with
the User and/or taking legal action against such User.
8.2. You agree to fully indemnify, defend and hold harmless StarLaunch and its
shareholders, directors, agents and employees from and against all claims,
demands, liabilities, damages, losses, costs and expenses, including legal fees
and any other charges whatsoever, howsoever caused, that may arise as a result 
of: (i) your breach of this Agreement, in whole or in part; (ii) violation by you of any
law or any third party rights; and (iii) use by you of the Service.

9. LIMITATION OF LIABILITY; NO WARRANTY.
9.1. IN NO EVENT SHALL STARLAUNCH, ITS AFFILIATES AND SERVICE
PROVIDERS, OR ANY OF THEIR RESPECTIVE OFFICERS, DIRECTORS,
AGENTS, JOINT VENTURERS, EMPLOYEES OR REPRESENTATIVES, BE
LIABLE (A) FOR ANY AMOUNT GREATER THAN THE VALUE OF THE
STARLAUNCH DIGITAL CURRENCY ON DEPOSIT IN YOUR STARLAUNCH
ACCOUNT(S) OR (B) FOR ANY LOST PROFITS, DIMINUTION IN VALUE OR
BUSINESS OPPORTUNITY, ANY LOSS, DAMAGE, CORRUPTION OR BREACH
OF DATA OR ANY OTHER INTANGIBLE PROPERTY OR ANY SPECIAL,
INCIDENTAL, INDIRECT, INTANGIBLE, OR CONSEQUENTIAL DAMAGES,
WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY,
OR OTHERWISE, ARISING OUT OF OR IN CONNECTION WITH AUTHORIZED
OR UNAUTHORIZED USE OF THE STARLAUNCH SITE OR THE STARLAUNCH
SERVICES, OR THIS AGREEMENT, EVEN IF AN AUTHORIZED
REPRESENTATIVE OF STARLAUNCH HAS BEEN ADVISED OF OR KNEW OR
SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES, AND
NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF
ITS ESSENTIAL PURPOSE, EXCEPT TO THE EXTENT OF A FINAL JUDICIAL
DETERMINATION THAT SUCH DAMAGES WERE A RESULT OF
STARLAUNCH’S GROSS NEGLIGENCE, FRAUD, WILLFUL MISCONDUCT OR
INTENTIONAL VIOLATION OF LAW. THIS MEANS, BY WAY OF EXAMPLE
ONLY (AND WITHOUT LIMITING THE SCOPE OF THE PRECEDING
SENTENCE), THAT IF YOU CLAIM THAT STARLAUNCH FAILED TO PROCESS
A BUY OR SELL TRANSACTION PROPERLY, YOUR DAMAGES ARE LIMITED
TO NO MORE THAN THE PURCHASE PRICE OF YOUR DIGITAL CURRENCY
AT ISSUE IN THE TRANSACTION, AND THAT YOU MAY NOT RECOVER FOR
LOST PROFITS, LOST BUSINESS OPPORTUNITIES, DIMINUTION IN VALUE
OR OTHER TYPES OF SPECIAL, INCIDENTAL, INDIRECT, INTANGIBLE,
EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES IN EXCESS OF THE
VALUE OF THE STARLAUNCH DIGITAL CURRENCY AT ISSUE IN THE
TRANSACTION. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE
ABOVE LIMITATION MAY NOT APPLY TO YOU.
9.2. THE STARLAUNCH SERVICES ARE PROVIDED ON AN "AS IS" AND "AS
AVAILABLE" BASIS WITHOUT ANY REPRESENTATION OR WARRANTY,
WHETHER EXPRESS, IMPLIED OR STATUTORY. TO THE MAXIMUM EXTENT
PERMITTED BY APPLICABLE LAW, STARLAUNCH SPECIFICALLY DISCLAIMS
ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A
PARTICULAR PURPOSE AND/OR NON-INFRINGEMENT. STARLAUNCH DOES
NOT MAKE ANY REPRESENTATIONS OR WARRANTIES THAT ACCESS TO
THE SITE, ANY PART OF THE STARLAUNCH SERVICES, OR ANY OF THE 
M AT E R I A L S C O N TA I N E D T H E R E I N , W I L L B E C O N T I N U O U S ,
UNINTERRUPTED, TIMELY, OR ERROR-FREE. STARLAUNCH DOES NOT
GUARANTEE THAT ANY ORDER WILL BE EXECUTED, ACCEPTED,
RECORDED OR REMAIN OPEN. EXCEPT FOR THE EXPRESS STATEMENTS
SET FORTH IN THIS AGREEMENT, YOU HEREBY ACKNOWLEDGE AND
AGREE THAT YOU HAVE NOT RELIED UPON ANY OTHER STATEMENT OR
UNDERSTANDING, WHETHER WRITTEN OR ORAL, WITH RESPECT TO YOUR
USE AND ACCESS OF THE STARLAUNCH SERVICES AND STARLAUNCH
SITE. WITHOUT LIMITING THE FOREGOING, YOU HEREBY UNDERSTAND
AND AGREE THAT STARLAUNCH WILL NOT BE LIABLE FOR ANY LOSSES OR
DAMAGES ARISING OUT OF OR RELATING TO: (A) ANY INACCURACY,
DEFECT OR OMISSION OF DIGITAL CURRENCY PRICE DATA, (B) ANY ERROR
OR DELAY IN THE TRANSMISSION OF SUCH DATA, OR (C) INTERRUPTION IN
ANY SUCH DATA.
9.3. StarLaunch makes no representations about the accuracy, order, timeliness or
completeness of historical Digital Currency price data available on the Site.
StarLaunch will make reasonable efforts to ensure that requests for electronic debits
and credits involving bank accounts, credit cards, and check issuances are
processed in a timely manner but StarLaunch makes no representations or
warranties regarding the amount of time needed to complete processing which is
dependent upon many factors outside of our control.

10. COMPLAINTS; DISPUTES
10.1. If a User wishes to make a complaint, please contact StarLaunch’s customer
service team at info@StarLauch.com. Should any dispute not be resolved to your
satisfaction you may pursue remedies in the governing law jurisdiction set forth
below.
10.2. CLASS ACTION WAIVER: TO THE EXTENT PERMISSIBLE BY LAW, ALL
CLAIMS MUST BE BROUGHT IN A PARTY’S INDIVIDUAL CAPACITY, AND NOT
AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS,
COLLECTIVE ACTION, OR REPRESENTATIVE PROCEEDING (COLLECTIVELY
“CLASS ACTION WAIVER”). THE ARBITRATOR MAY NOT CONSOLIDATE
MORE THAN ONE PERSON'S CLAIMS OR ENGAGE IN ANY CLASS
ARBITRATION. YOU ACKNOWLEDGE THAT, BY AGREEING TO THESE
TERMS, YOU AND STARLAUNCH ARE EACH WAIVING THE RIGHT TO A
TRIAL BY JURY AND THE RIGHT TO PARTICIPATE IN A CLASS ACTION.
10.3. Governing Law. The Agreement and any matters relating hereto shall be governed
by, and construed in accordance with, the laws of the British Virgin Islands. You
irrevocably agree that, subject as provided below, the courts of the British Virgin
Islands shall have exclusive jurisdiction in relation to any claim, dispute or difference
concerning the Agreement and any matter arising therefrom and irrevocably waive
any right that it may have to object to an action being brought in those courts, or to
claim that the action has been brought in an inconvenient forum, or that those courts 
do not have jurisdiction. Nothing in this clause shall limit the right of StarLaunch to
take proceedings against you in any other court of competent jurisdiction, nor shall
the taking of proceedings in any one or more jurisdictions preclude the taking of
proceedings in any other jurisdictions, whether concurrently or not, to the extent
permitted by the law of such other jurisdiction.

11. GENERAL PROVISIONS
11.1. Computer Viruses. We shall not bear any liability, whatsoever, for any damage or
interruptions caused by any computer viruses or other malicious code that may
affect your computer or other equipment, or any phishing, spoofing or other attack.
We advise the regular use of a reputable and readily available virus screening and
prevention software. You should also be aware that SMS and email services are
vulnerable to spoofing and phishing attacks and should use care in reviewing
messages purporting to originate from StarLaunch. Always log into your
StarLaunch Account(s) through the StarLaunch Site to review any transactions or
required actions if you have any uncertainty regarding the authenticity of any
communication or notice.
11.2. Release of StarLaunch; Indemnification. If you have a dispute with one or more
users of the StarLaunch Services, you release StarLaunch, its affiliates and service
providers, and each of their respective officers, directors, agents, joint venturers,
employees and representatives from any and all claims, demands and damages
(actual and consequential) of every kind and nature arising out of or in any way
connected with such disputes. You agree to indemnify and hold StarLaunch, its
affiliates and Service Providers, and each of its or their respective officers,
directors, agents, joint venturers, employees and representatives, harmless from
any claim or demand (including attorneys' fees and any fines, fees or penalties
imposed by any regulatory authority) arising out of or related to your breach of this
Agreement or your violation of any law, rule or regulation, or the rights of any third
party.
11.3. Limitation of Liability; No Warranty. IN NO EVENT SHALL STARLAUNCH, ITS
AFFILIATES AND SERVICE PROVIDERS, OR ANY OF THEIR RESPECTIVE
OFFICERS, DIRECTORS, AGENTS, JOINT VENTURERS, EMPLOYEES OR
REPRESENTATIVES, BE LIABLE (A) FOR ANY AMOUNT GREATER THAN THE
VALUE OF THE SUPPORTED DIGITAL CURRENCY ON DEPOSIT IN YOUR
STARLAUNCH ACCOUNT(S) OR (B) FOR ANY LOST PROFITS, DIMINUTION IN
VALUE OR BUSINESS OPPORTUNITY, ANY LOSS, DAMAGE, CORRUPTION
OR BREACH OF DATA OR ANY OTHER INTANGIBLE PROPERTY OR ANY
SPECIAL, INCIDENTAL, INDIRECT, INTANGIBLE, OR CONSEQUENTIAL
DAMAGES, WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT
LIABILITY, OR OTHERWISE, ARISING OUT OF OR IN CONNECTION WITH
AUTHORIZED OR UNAUTHORIZED USE OF THE STARLAUNCH SITE OR THE
STARLAUNCH SERVICES, OR THIS AGREEMENT, EVEN IF AN AUTHORIZED
REPRESENTATIVE OF STARLAUNCH HAS BEEN ADVISED OF OR KNEW OR 
SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES, AND
NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF
ITS ESSENTIAL PURPOSE, EXCEPT TO THE EXTENT OF A FINAL JUDICIAL
DETERMINATION THAT SUCH DAMAGES WERE A RESULT OF
STARLAUNCH’S GROSS NEGLIGENCE, FRAUD, WILLFUL MISCONDUCT OR
INTENTIONAL VIOLATION OF LAW. THIS MEANS, BY WAY OF EXAMPLE
ONLY (AND WITHOUT LIMITING THE SCOPE OF THE PRECEDING
SENTENCE), THAT IF YOU CLAIM THAT STARLAUNCH FAILED TO PROCESS
A BUY OR SELL TRANSACTION PROPERLY, YOUR DAMAGES ARE LIMITED
TO NO MORE THAN THE VALUE OF THE SUPPORTED DIGITAL CURRENCY
AT ISSUE IN THE TRANSACTION, AND THAT YOU MAY NOT RECOVER FOR
LOST PROFITS, LOST BUSINESS OPPORTUNITIES, DIMINUTION IN VALUE
OR OTHER TYPES OF SPECIAL, INCIDENTAL, INDIRECT, INTANGIBLE,
EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES IN EXCESS OF THE
VALUE OF THE SUPPORTED DIGITAL CURRENCY AT ISSUE IN THE
TRANSACTION. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE
ABOVE LIMITATION MAY NOT APPLY TO YOU.
11.4. THE STARLAUNCH SERVICES ARE PROVIDED ON AN "AS IS" AND "AS
AVAILABLE" BASIS WITHOUT ANY REPRESENTATION OR WARRANTY,
WHETHER EXPRESS, IMPLIED OR STATUTORY. TO THE MAXIMUM EXTENT
PERMITTED BY APPLICABLE LAW, STARLAUNCH SPECIFICALLY DISCLAIMS
ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A
PARTICULAR PURPOSE AND/OR NON-INFRINGEMENT. STARLAUNCH DOES
NOT MAKE ANY REPRESENTATIONS OR WARRANTIES THAT ACCESS TO
THE SITE, ANY PART OF THE STARLAUNCH SERVICES, OR ANY OF THE
M AT E R I A L S C O N TA I N E D T H E R E I N , W I L L B E C O N T I N U O U S ,
UNINTERRUPTED, TIMELY, OR ERROR-FREE. STARLAUNCH DOES NOT
GUARANTEE THAT ANY ORDER WILL BE EXECUTED, ACCEPTED,
RECORDED OR REMAIN OPEN. EXCEPT FOR THE EXPRESS STATEMENTS
SET FORTH IN THIS AGREEMENT, YOU HEREBY ACKNOWLEDGE AND
AGREE THAT YOU HAVE NOT RELIED UPON ANY OTHER STATEMENT OR
UNDERSTANDING, WHETHER WRITTEN OR ORAL, WITH RESPECT TO
YOUR USE AND ACCESS OF THE STARLAUNCH SERVICES AND
STARLAUNCH SITE. WITHOUT LIMITING THE FOREGOING, YOU HEREBY
UNDERSTAND AND AGREE THAT STARLAUNCH WILL NOT BE LIABLE FOR
ANY LOSSES OR DAMAGES ARISING OUT OF OR RELATING TO: (A) ANY
INACCURACY, DEFECT OR OMISSION OF DIGITAL CURRENCY PRICE DATA,
(B) ANY ERROR OR DELAY IN THE TRANSMISSION OF SUCH DATA, OR (C)
INTERRUPTION IN ANY SUCH DATA.
11.5. Entire Agreement. This Agreement, the Privacy Policy, E-Sign Consent, and
Appendices incorporated by reference herein comprise the entire understanding
and agreement between you and StarLaunch as to the subject matter hereof, and
supersedes any and all prior discussions, agreements and understandings of any 
kind (including without limitation any prior versions of this Agreement), and every
nature between and among you and StarLaunch. Section headings in this
Agreement are for convenience only and shall not govern the meaning or
interpretation of any provision of this Agreement.
11.6. Amendments. We may amend or modify this Agreement by posting on the
StarLaunch Site or emailing to you the revised Agreement, and the revised
Agreement shall be effective at such time. If you do not agree with any such
modification, your sole and exclusive remedy is to terminate your use of the
Services and close your account. You agree that we shall not be liable to you or
any third party for any modification or termination of the StarLaunch Services, or
suspension or termination of your access to the StarLaunch Services, except to
the extent otherwise expressly set forth herein. If the revised Agreement includes a
material change, we will endeavor to provide you advanced notice via our website
and/or email before the material change becomes effective.
11.7. Assignment. You may not assign any rights and/or licenses granted under this
Agreement. We reserve the right to assign our rights without restriction, including
without limitation to any StarLaunch affiliates or subsidiaries, or to any successor
in interest of any business associated with the StarLaunch Services. Any
attempted transfer or assignment in violation hereof shall be null and void. Subject
to the foregoing, this Agreement will bind and inure to the benefit of the parties,
their successors and permitted assigns.
11.8. Severability. If any provision of this Agreement shall be determined to be invalid or
unenforceable under any rule, law, or regulation of any local, state, or federal
government agency, such provision will be changed and interpreted to accomplish
the objectives of the provision to the greatest extent possible under any applicable
law and the validity or enforceability of any other provision of this Agreement shall
not be affected.
11.9. Change of Control. In the event that StarLaunch is acquired by or merged with a
third party entity, we reserve the right, in any of these circumstances, to transfer or
assign the information we have collected from you as part of such merger,
acquisition, sale, or other change of control.
11.10.Survival. All provisions of this Agreement which by their nature extend beyond the
expiration or termination of this Agreement, including, without limitation, sections
pertaining to suspension or termination, StarLaunch Account cancellation, debts
owed to StarLaunch, general use of the StarLaunch Site, disputes with
StarLaunch, and general provisions, shall survive the termination or expiration of
this Agreement.
11.11.Governing Law. You agree that the laws of the State of California, without regard
to principles of conflict of laws, will govern this Agreement and any claim or dispute
that has arisen or may arise between you and StarLaunch, except to the extent
governed by federal law.
11.12.Force Majeure. We shall not be liable for delays, failure in performance or
interruption of service which result directly or indirectly from any cause or condition
beyond our reasonable control, including but not limited to, significant market
volatility, any delay or failure due to any act of God, act of civil or military
authorities, act of terrorists, civil disturbance, war, strike or other labor dispute, fire,
interruption in telecommunications or Internet services or network provider
services, failure of equipment and/or software, other catastrophe or any other
occurrence which is beyond our reasonable control and shall not affect the validity
and enforceability of any remaining provisions.
11.13.Non-Waiver of Rights. This agreement shall not be construed to waive rights that
cannot be waived under applicable state money transmission laws in the state
where you are located.

15. Cryptocurrency
• CRYPTOCURRENCY VALUES CAN FLUCTUATE GREATLY IN VALUE
DEPENDING ON MARKET CONDITIONS.
• THE USER WARRANTS THAT IT IS AWARE OF THE VOLATILE NATURE OF
CRYPTOCURRENCIES, AND HOLDS STARLAUNCH HARMLESS FOR ANY
LOSS OR DAMAGES ARISING FROM SUCH VOLATILITY.`}
            </pre>
        </div>
    )
}
