import * as CONSTANTS from "../constants/constants";
import { Program, web3 } from '@project-serum/anchor';
import idoIdl from '../web3/idl/ido.json';
import nodeIdoIdl from '../web3/idl/star_node.json';
import devIdoIdl from '../web3/dev-idl/ido.json';
import vaultIdl from '../web3/idl/vault.json';
import devVaultIdl from '../web3/dev-idl/vault.json';
import cadetIdl from '../web3/idl/cadet.json';
import cadetOldIdl from '../web3/idl/cadetOld.json';
import devCadetIdl from '../web3/dev-idl/cadet.json';
import devCadetOldIdl from '../web3/dev-idl/cadetOld.json';
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, Token } from '@solana/spl-token';
import {Buffer} from 'buffer';

import { Connection, PublicKey } from "@solana/web3.js";
import { Metadata } from "@metaplex-foundation/mpl-token-metadata";

let env = CONSTANTS.PRODUCTION;
export const getEnvironment = () => {
  const { host } = window.location;

  if (
  //  host.indexOf("localhost") !== -1 ||
    host.indexOf("dev-stars.starlaunch.com") !== -1
  ) {
    env = CONSTANTS.DEVELOPMENT;
  }
  return env;
}

getEnvironment(); // Update Environment

const getTokenSupply = async (mintPubkey) => {
  let rpcUrl = getRpcUrl();
  const connection = new Connection(rpcUrl);
  const tokenmeta = await connection.getTokenSupply(new PublicKey(mintPubkey));
  return tokenmeta?.value?.uiAmount;
}

export const getHydrazineSupply = async (token) => {
  let mintPubkey =  env === CONSTANTS.PRODUCTION ? CONSTANTS.PROD_HYDRAZINE_MINT_ADDRESS : CONSTANTS.DEV_HYDRAZINE_MINT_ADDRESS;
  return await getTokenSupply(mintPubkey)
}

export const doesURLInclude = (href) => {
  return window.location.href.includes(href);
}

export const getApiUrl = () => {
    return env === CONSTANTS.PRODUCTION ? CONSTANTS.PROD_API_URL : CONSTANTS.DEV_API_URL;
};

export const getRpcUrl = () => {
    return env === CONSTANTS.PRODUCTION ? CONSTANTS.PROD_RPC_NODE_URL : CONSTANTS.DEV_RPC_NODE_URL;
};

export const getFractalUrl = () => {
  return env === CONSTANTS.PRODUCTION ? CONSTANTS.PROD_FRACTAL_URL : CONSTANTS.DEV_FRACTAL_URL;
};

export const getVaultProgramName = () => {
    return env === CONSTANTS.PRODUCTION ? CONSTANTS.PROD_VAULT_PROGRAM_NAME : CONSTANTS.DEV_VAULT_PROGRAM_NAME;
}

export const getIdoProgramName = () => {
    return env === CONSTANTS.PRODUCTION ? CONSTANTS.PROD_IDO_PROGRAM_NAME : CONSTANTS.DEV_IDO_PROGRAM_NAME;
}

export const getNodeIdoProgramName = () => {
    return env === CONSTANTS.PRODUCTION ? CONSTANTS.PROD_NODE_IDO_PROGRAM_NAME : CONSTANTS.DEV_NODE_IDO_PROGRAM_NAME;
}

export const getStarsMintAddress = () => {
    return env === CONSTANTS.PRODUCTION ? new web3.PublicKey(CONSTANTS.PROD_STARS_MINT_ADDRESS) : new web3.PublicKey(CONSTANTS.DEV_STARS_MINT_ADDRESS);
}

export const getHydrazineMintAddress = () => {
    return env === CONSTANTS.PRODUCTION ? new web3.PublicKey(CONSTANTS.PROD_HYDRAZINE_MINT_ADDRESS) : new web3.PublicKey(CONSTANTS.DEV_HYDRAZINE_MINT_ADDRESS);
}

export const getUsdcMintAddress = () => {
    return env === CONSTANTS.PRODUCTION ? new web3.PublicKey(CONSTANTS.PROD_USDC_MINT_ADDRESS) : new web3.PublicKey(CONSTANTS.DEV_USDC_MINT_ADDRESS);
}

export const getCadetProgramAddress = () => {
    return env === CONSTANTS.PRODUCTION ? CONSTANTS.PROD_CADET_PROGRAM_ADDREESS : CONSTANTS.DEV_CADET_PROGRAM_ADDREESS;
}

export const getVaultProgramAddress = () => {
    return env === CONSTANTS.PRODUCTION ? CONSTANTS.PROD_VAULT_PROGRAM_ADDREESS : CONSTANTS.DEV_VAULT_PROGRAM_ADDREESS;
}

export const getIdoProgramAddress = () => {
    return env === CONSTANTS.PRODUCTION ? CONSTANTS.PROD_IDO_PROGRAM_ADDREESS : CONSTANTS.DEV_IDO_PROGRAM_ADDREESS;
}

export const getNodeIdoProgramAddress = () => {
    return env === CONSTANTS.PRODUCTION ? CONSTANTS.PROD_NODE_IDO_PROGRAM_ADDRESS : CONSTANTS.DEV_NODE_IDO_PROGRAM_ADDRESS;
}

export const getCadetProgramId = () => {
    return new web3.PublicKey(getCadetProgramAddress());
}

export const getVaultProgramId = () => {
    return new web3.PublicKey(getVaultProgramAddress());
}

export const getIdoProgramId =  () => {
    return new web3.PublicKey(getIdoProgramAddress());
}

export const getNodeIdoProgramId =  () => {
    return new web3.PublicKey(getNodeIdoProgramAddress());
}

export const getCadetSeedBuffer = () => {
    const seed = env === CONSTANTS.PRODUCTION ? CONSTANTS.PROD_CADET_SEED : CONSTANTS.DEV_CADET_SEED;
    return Buffer.from(seed);
}

export const getRegisterCadetSeedBuffer = () => {
    const seed = env === CONSTANTS.PRODUCTION ? CONSTANTS.PROD_REGISTER_CADET_SEED : CONSTANTS.DEV_REGISTER_CADET_SEED;
    return Buffer.from(seed);
}

export const getVaultSeedBuffer = () => {
    const seed = env === CONSTANTS.PRODUCTION ? CONSTANTS.PROD_VAULT_SEED : CONSTANTS.DEV_VAULT_SEED;
    return Buffer.from(seed);
}

export const getPoolUserBuffer = () => {
    const seed = env === CONSTANTS.PRODUCTION ? CONSTANTS.PROD_POOL_USER_SEED : CONSTANTS.DEV_POOL_USER_SEED;
    return Buffer.from(seed);
}

export const getVaultProgramNameBuffer = () => {
    return Buffer.from(getVaultProgramName());
}

export const getIdoProgramNameBuffer = () => {
    return Buffer.from(getIdoProgramName());
}

export const getNodeIdoProgramNameBuffer = () => {
    return Buffer.from(getNodeIdoProgramName());
}

export const getIdoIdl =  () => {
    return env === CONSTANTS.PRODUCTION ? idoIdl : devIdoIdl;
}

export const getIdoProgram = (provider) => {
    // const programId = getIdoProgramId();
    // const idl = getIdoIdl();
    const programId = new web3.PublicKey("7im9orzthW5w58Thf3d7RjhPCPpqGMGhgtZ11tQfgLxp");
    const idl = idoIdl;
    return new Program(idl, programId, provider);
}

export const getNodeProgram = (provider) => {
    const programId = env === CONSTANTS.PRODUCTION ?
      new web3.PublicKey("A6VqF5jf9dgtpPso8a8ohmfewSyyFa4NdNHErYh3Q6YS") :
      new web3.PublicKey("HooLKTEpSVFB5UspmTzc6FVgskNppZFd6Cx5BKCEX4dV");  //HooLKTEpSVFB5UspmTzc6FVgskNppZFd6Cx5BKCEX4dV

    const idl = nodeIdoIdl;
    return new Program(idl, programId, provider);
}

export const getVaultIdl = () => {
    return env === CONSTANTS.PRODUCTION ? vaultIdl : devVaultIdl;
}

export const getVaultProgram = (provider) => {
    const programId = getVaultProgramId();
    const idl = getVaultIdl();
    return new Program(idl, programId, provider);
}

export const getCadetIdl = () => {
    return env === CONSTANTS.PRODUCTION ? cadetIdl : devCadetIdl;
}

export const getCadetProgram = (provider) => {
    const programId = getCadetProgramId();
    const idl = getCadetIdl();
    return new Program(idl, programId, provider);
}

export const findATA =  async (wallet, mint) => {
    const ata = await web3.PublicKey.findProgramAddress([
                wallet.toBuffer(),
                TOKEN_PROGRAM_ID.toBuffer(),
                mint.toBuffer()
            ],
            ASSOCIATED_TOKEN_PROGRAM_ID);
    return ata[0];
}

export const getCadetInfo = async (provider, cadetAcct) => {
  try { 
    const cadetProgram = await getCadetProgram(provider);
    const cadetInfo = await cadetProgram.account.cadetAccount.fetch(cadetAcct);
    return cadetInfo;  
  } catch (e) { 
    // For Old programs
    const programId = getCadetProgramId();
    const cadetProgram = new Program(cadetOldIdl, programId, provider);
    const cadetInfo = await cadetProgram.account.cadetAccount.fetch(cadetAcct);
    return cadetInfo;  
  }
}

export const  getAddys = async (provider, poolName, isNode) => {
    const cadetProgramID = getCadetProgramId();
    const vaultProgramID = getVaultProgramId();
    const idoProgramID = getIdoProgramId();
    const nodeIdoProgramID = getNodeIdoProgramId();
    let pool = null;
    let poolBump = null;
    let nodePoolBump = null;
    let nodePool = null;
    let ppt = null;
    let pptBump = null;
    let nodePpt = null;
    let nodePptBump = null;
    let refund_ppt = null;
    let refund_pptBump = null;

    const [mainVault, mainVaultBump] = await web3.PublicKey.findProgramAddress(
      [
        getVaultProgramNameBuffer()
      ],
      vaultProgramID
    );

    const [vaultStars, vaultStarsBump] = await web3.PublicKey.findProgramAddress(
      [
        getVaultProgramNameBuffer(), 
        Buffer.from("vault_stars")
      ],
      vaultProgramID
    );

    const [vaultHydrazine, vaultHydrazineBump] = await web3.PublicKey.findProgramAddress(
      [
        getVaultProgramNameBuffer(), 
        Buffer.from("vault_hydrazine")
      ],
      vaultProgramID
    );

    const [mainData, mainDataBump] = await web3.PublicKey.findProgramAddress(
      [
        getIdoProgramNameBuffer()
      ],
      idoProgramID
    );

    const [cadetAcct, cadetAcctBump] = await web3.PublicKey.findProgramAddress(
      [
        provider.wallet.publicKey.toBuffer(),
        getCadetSeedBuffer(),
        getRegisterCadetSeedBuffer()
    ],
      cadetProgramID
    );

    const [idoAcct, idoAcctBump] = await web3.PublicKey.findProgramAddress(
        [
          provider.wallet.publicKey.toBuffer(), 
          getIdoProgramNameBuffer(), 
          getPoolUserBuffer()
        ],
        idoProgramID
      );

    const [nodeIdoAcct, nodeIdoAcctBump] = await web3.PublicKey.findProgramAddress(
        [
            provider.wallet.publicKey.toBuffer(),
            getNodeIdoProgramNameBuffer(),
            getPoolUserBuffer()
        ],
        nodeIdoProgramID
    );

    const [vaultAccount, vaultAccountBump] = await web3.PublicKey.findProgramAddress(
      [
        provider.wallet.publicKey.toBuffer(), 
        getVaultProgramNameBuffer(), 
        getVaultSeedBuffer()
    ],
      vaultProgramID
    );

    if(poolName) {
        [pool, poolBump] = await web3.PublicKey.findProgramAddress(
            [
            Buffer.from(poolName)
            ],
            idoProgramID
        );

        [ppt, pptBump] = await web3.PublicKey.findProgramAddress(
            [
              provider.wallet.publicKey.toBuffer(), 
              Buffer.from(poolName), 
              Buffer.from("pool_participant")
            ],
            idoProgramID
          );
        
        [refund_ppt, refund_pptBump] = await web3.PublicKey.findProgramAddress(
            [
              provider.wallet.publicKey.toBuffer(), 
              Buffer.from(poolName), 
              Buffer.from("pool_refund_participant")
            ],
            idoProgramID
          );

        if (isNode) {

          [nodePool, nodePoolBump] = await web3.PublicKey.findProgramAddress(
            [
              Buffer.from(poolName)
            ],
            nodeIdoProgramID
          );

          [nodePpt, nodePptBump] = await web3.PublicKey.findProgramAddress(
            [
              provider.wallet.publicKey.toBuffer(),
              Buffer.from(poolName),
              Buffer.from("pool_participant")
            ],
            nodeIdoProgramID
          );
        }
    }

    return {
        cadetAcct, 
        cadetAcctBump, 
        idoAcct, 
        idoAcctBump,
        nodeIdoAcct,
        nodeIdoAcctBump,
        mainData,
        mainVault,
        vaultStars,
        vaultHydrazine,
        mainVaultBump,
        nodePool,
        nodePoolBump,
        pool,
        poolBump,
        ppt,
        pptBump,
        nodePpt,
        nodePptBump,
        refund_ppt,
        refund_pptBump,
        vaultAccount, 
        vaultAccountBump,
    }
  }

export const openURL = (url) => {
    window.open(url);
};
export const KYC_STATUS_PENDING = 0;
export const KYC_STATUS_APPROVED = 1;
export const KYC_STATUS_NOT_APPROVED = 2;
export const FRACTAL_LINK = 'https://fractal.id/authorize?client_id=b5kR1YtTwLjdoWNkat9gxgj8HEKZIIj8pDBgnpCIVY0&redirect_uri=https%3A%2F%2Fapp.starlaunch.com%2F&response_type=code&scope=contact%3Aread%20verification.plus%3Aread%20verification.plus.details%3Aread%20verification.liveness%3Aread%20verification.liveness.details%3Aread%20verification.wallet-sol:read%20verification.wallet-sol.details:read%20verification.telegram:read%20verification.telegram.details:read'
