import "./Leftnav.scss";
import React, { useState } from "react";
import { useLocation } from "react-router";
import RocketIcon from '../../images/svg/rocket.svg'
import RocketActiveIcon from '../../images/svg/rocket-active.svg'
import FusionReactorIcon from '../../images/svg/fusionReactor.svg'
import FusionReactorActiveIcon from '../../images/svg/fusionReactor-active.svg'
import PlayerDashboardIcon from '../../images/svg/playerDashboard.svg'
import PlayerDashboardActiveIcon from '../../images/svg/playerDashboard-active.svg'
import ClaimsPageIcon from '../../images/svg/claimsPage.svg'
import ClaimsPageActiveIcon from '../../images/svg/claimsPage-active.svg'
import IdoPageIcon from '../../images/svg/idoPage.svg'
import IdoPageActiveIcon from '../../images/svg/idoPage-active.svg'
import SwapIcon from '../../images/svg/swap-icon.svg'
import SwapActiveIcon from '../../images/svg/swap-icon-active.svg'
import InformationIcon from '../../images/svg/information-outline.svg'
import InformationActiveIcon from '../../images/svg/information-outline-active.svg'
import HelpIcon from '../../images/svg/help-outline.svg'
import HelpActiveIcon from '../../images/svg/help-outline-active.svg'
import CloseIcon from '../../images/svg/close.svg'
import SoundIcon from '../../images/svg/soundIcon.svg'
import SoundActiveIcon from '../../images/svg/soundIcon-active.svg'
import SoundIconMute from '../../images/svg/soundIconMute.svg'
import SoundIconActiveMute from '../../images/svg/soundIconMute-active.svg'
import AethirLogo from '../../images/svg/aethir_logo.svg';
import VideoOnIcon from '../../images/svg/animationsOn.svg'
import VideoOffIcon from '../../images/svg/animationsOff.svg'
import LeftNavHover from '../../Sounds/main-menu-hover.mp3'
import LeftNavClick from '../../Sounds/main-menu-click.mp3'
import { Tooltip } from 'react-tooltip'
import ButtonClick from "../../Sounds/general-button-click.mp3"
import BackgroundTracks from '../../Sounds/background-tracks-mix.mp3';
import {
  PATH_CLAIMS_PAGE,
  PATH_HOME,
  PATH_IDO_DETAILS,
  PATH_IDO_PAGE,
  PATH_MISSION_DASHBOARD, PATH_NODE_SALE_DETAILS,
  PATH_PLAYER_DASHBOARD,
  PATH_SWAP_PAGE,
  PATH_VAULT
} from '../../App'

import {
  useHistory
} from "react-router-dom";

import { Modal, Button } from "react-bootstrap";
import HomeInformation from "../../screens/Home/HomeInformation";
import HomeTutorial from "../../screens/Home/HomeTutorial";
import VaultTutorial from "../../screens/Vault/VaultTutorial";
import {mute, toggleMute, toggleVideo} from './sound';
import { useDispatch, useSelector } from "react-redux";
import PlayerDashboardTutorial from "../../screens/PlayerDashboard/PlayerDashboardTutorial";
import { useEffect, useRef, useCallback } from "react";
import { ButtonClickAudio } from "../Button/Button";
import {
  hover,
  SHIP_PART_REACTOR,
  SHIP_PART_PLAYER_DASHBOARD,
  SHIP_PART_IDO_PAGE,
  SHIP_PART_SWAP_PAGE,
  unHover,
  setDisableHover,
  SHIP_PART_CLAIMS_PAGE
} from "../../screens/Home/shipParts";
import IdoPageTutorial from "../../screens/IdoPage/IdoPageTutorial";
import { toggleShowInformation, toggleShowTutorial } from "./modal";
import Icon from "../Icon/Icon";
import {doesURLInclude} from "../../Helpers/helper";

const BackgroundTracksAudio = new Audio(BackgroundTracks);
export const LeftNavClickAudio = new Audio(LeftNavClick);
const LeftNavHoverAudio = new Audio(LeftNavHover);

function NavContent() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const isMuted = useSelector((state) => state.sound.mute);
  const videoOff = useSelector((state) => state.sound.videoOff);

  const reactorHover = useSelector((state) => state.shipParts[SHIP_PART_REACTOR])
  const claimsPageHover = useSelector((state) => state.shipParts[SHIP_PART_CLAIMS_PAGE])
  const playerDashboardHover = useSelector((state) => state.shipParts[SHIP_PART_PLAYER_DASHBOARD])
  const idoPageHover = useSelector((state) => state.shipParts[SHIP_PART_IDO_PAGE])
  const swapPageHover = useSelector((state) => state.shipParts[SHIP_PART_SWAP_PAGE])

  const showTutorial = useSelector((state) => state.modal.showTutorial);
  const showInformation = useSelector((state) => state.modal.informationState.show);
  const showWelcomeModal = useSelector((state) => state.modal.showWelcomeModal);

  const [forceShowTutorial, setForceShowTutorial] = useState(false);
  const [showInformationOutline, setShowInformationOutline] = useState(false);

  const reactorLinkRef = useRef();
  const playerDashboardLinkRef = useRef();
  const claimsPageLinkRef = useRef();
  const idoLinkRef = useRef();
  const swapPageLinkRef = useRef();

  const playBackgroundTrack = useCallback(() => {
    try {
      if (!isMuted) {
        BackgroundTracksAudio.play();
        BackgroundTracksAudio.loop = true;
      } else {
        BackgroundTracksAudio.pause();
      }
    } catch (error) {
      console.error(error);
    }

  }, [isMuted, BackgroundTracksAudio])

  useEffect(() => {
    const soundMuted = localStorage.getItem('soundMuted');
    if (soundMuted === 'true' && isMuted === false) {
      dispatch(mute())
    }
  }, [])

  useEffect(() => {
    playBackgroundTrack();

    window.addEventListener('mousedown', playBackgroundTrack)

    return () => {
      window.removeEventListener('mousedown', playBackgroundTrack)
    }
  }, [isMuted])

  const handleRedirect = useCallback((path, shipPart) => {
    if (!isMuted) {
      LeftNavClickAudio.play()
    }
    if (shipPart) {
      dispatch(unHover(shipPart));
    }
    history.push(path);
  }, [history, isMuted, dispatch]);

  const _toggleShowInformation = useCallback(() => {
    if (!isMuted && !showInformation) {
      ButtonClickAudio.play();
    }

    dispatch(toggleShowInformation())
  }, [isMuted, ButtonClickAudio, dispatch, showInformation]);

  const _toggleShowTutorial = useCallback(() => {
    if (!isMuted) {
      ButtonClickAudio.play();
    }

    dispatch(toggleShowTutorial())
    if (showInformationOutline) {
      setShowInformationOutline(false);
    }
  }, [isMuted, showInformationOutline, ButtonClickAudio, dispatch]);

  const _toggleMute = () => {
    localStorage.setItem('soundMuted', !isMuted);
    dispatch(toggleMute())
  }

  const isHome = location.pathname === PATH_HOME;
  const isVault = location.pathname === PATH_VAULT;
  const isPlayerDashboard = location.pathname === PATH_PLAYER_DASHBOARD;
  const isAnyIdoPage = location.pathname === PATH_IDO_PAGE || location.pathname === PATH_IDO_DETAILS;
  const isSwapPage = location.pathname === PATH_SWAP_PAGE;

  const hoverPart = (shipPart) => {
    //if (isHome) {
      dispatch(hover(shipPart))
      //}
  }

  const unhoverPart = (shipPart) => {
    dispatch(unHover(shipPart))
  }

  useEffect(() => {
    if (reactorHover || playerDashboardHover || claimsPageHover || idoPageHover || swapPageHover) {
      if (!isMuted) {
        LeftNavHoverAudio.play()
      }
    }

  }, [reactorHover, playerDashboardHover, claimsPageHover, idoPageHover, swapPageHover,isMuted, isHome])


  return(<ul
            id="collapseExample1"
            className="collapse show list-group list-group-flush list-group-mine"
          > 
          {showTutorial && isHome && 
            <HomeTutorial 
              closeTutorial={_toggleShowTutorial} 
              setShowTutorialIcon={setForceShowTutorial}
              setShowInformationOutline={setShowInformationOutline} />}
          {showTutorial && isVault && 
            <VaultTutorial closeTutorial={_toggleShowTutorial} />}
          {showTutorial && isPlayerDashboard &&
            <PlayerDashboardTutorial closeTutorial={_toggleShowTutorial} />}
          {showTutorial && isAnyIdoPage && 
            <IdoPageTutorial closeTutorial={_toggleShowTutorial} />}
    
          
      
            <li 
            id="Home"
            onClick={() => handleRedirect(PATH_HOME)} 
            className={`list-group-item ${location.pathname === "/" ? "active" : ""}`} >
              {location.pathname === "/" ? <RocketActiveIcon className={"leftNavIconActive"} /> : <RocketIcon className={`leftNavIcon`} />}
              <span className="d-block d-sm-none item-text">SS StarLaunch</span>
            </li>
            <Tooltip place="right"  content={"SS StarLaunch"} anchorSelect="#Home"  />
              <li
              id="FusionVaultNav"
              ref={reactorLinkRef}
              onClick={() => handleRedirect(PATH_VAULT, SHIP_PART_REACTOR)}
              className={`list-group-item ${location.pathname === PATH_VAULT ? "active" : ""}`}
              onMouseEnter={() => {
                hoverPart(SHIP_PART_REACTOR)
              }} 
              onMouseLeave={() => {
                unhoverPart(SHIP_PART_REACTOR)
              }} 
              >
                {isVault || reactorHover ?  <FusionReactorActiveIcon className="leftNavIconActive" /> : <FusionReactorIcon className={`leftNavIcon`} />}
                <span className="d-block d-sm-none item-text">Fusion Vault</span>
              </li>
              <Tooltip place="right"  content={"Fusion Vault"} anchorSelect="#FusionVaultNav" isOpen={reactorHover} />
              <li
              id="PlayerDashboardNav"
              ref={playerDashboardLinkRef}
              onClick={() => handleRedirect(PATH_PLAYER_DASHBOARD, SHIP_PART_PLAYER_DASHBOARD)} 
              className={`list-group-item ${isPlayerDashboard ? "active" : ""}`}
              onMouseEnter={() => {
                hoverPart(SHIP_PART_PLAYER_DASHBOARD)
              }} 
              onMouseLeave={() => {
                unhoverPart(SHIP_PART_PLAYER_DASHBOARD)
              }}
              >
                {isPlayerDashboard || playerDashboardHover ? <PlayerDashboardActiveIcon className="leftNavIconActive" /> : <PlayerDashboardIcon className="leftNavIcon" /> }
                <span className="d-block d-sm-none item-text">Player Dashboard</span>
              </li>
              <Tooltip place="right"  content={"Player Dashboard"} anchorSelect="#PlayerDashboardNav" isOpen={playerDashboardHover} />
              <li 
              id="IDOPageNav"
              ref={idoLinkRef}
              onClick={() => handleRedirect(PATH_IDO_PAGE, SHIP_PART_IDO_PAGE)} 
              className={`list-group-item ${isAnyIdoPage ? "active" : ""}`}
              onMouseEnter={() => {
                hoverPart(SHIP_PART_IDO_PAGE)
              }} 
              onMouseLeave={() => {
                unhoverPart(SHIP_PART_IDO_PAGE)
              }} 
              >
                {isAnyIdoPage || idoPageHover ? <IdoPageActiveIcon className="leftNavIconActive" /> : <IdoPageIcon className="leftNavIconActive" />}
                <span className="d-block d-sm-none item-text">IDOs</span>
              </li>
              <Tooltip place="right"  content={"IDO Page"} anchorSelect="#IDOPageNav" isOpen={idoPageHover} />
              <li
                id="ClaimsPageNav"
                ref={claimsPageLinkRef}
                onClick={() => handleRedirect(PATH_CLAIMS_PAGE, SHIP_PART_CLAIMS_PAGE)}
                className={`list-group-item ${location.pathname === "/claims" ? "active" : ""}`}
                onMouseEnter={() => {
                  hoverPart(SHIP_PART_CLAIMS_PAGE)
                }}
                onMouseLeave={() => {
                  unhoverPart(SHIP_PART_CLAIMS_PAGE)
                }}
              >
                {location.pathname === "/claims" || claimsPageHover ? <ClaimsPageActiveIcon className="leftNavIconActive"/> :
                  <ClaimsPageIcon className="leftNavIconActive"/>}
                <span className="d-block d-sm-none item-text">Mission Dashboard</span>
              </li>
            <Tooltip place="right"  content={"Claims Page"} anchorSelect="#ClaimsPageNav" isOpen={claimsPageHover} />

            <li
              id="SwapPageNav"
              onMouseEnter={() => {
                hoverPart(SHIP_PART_SWAP_PAGE)
              }}
              onMouseLeave={() => {
                unhoverPart(SHIP_PART_SWAP_PAGE)
              }}
              onClick={() => handleRedirect(PATH_SWAP_PAGE, SHIP_PART_SWAP_PAGE)}
              className={`list-group-item ${isSwapPage ? "active" : ""}`}
            >
              {isSwapPage ? <SwapActiveIcon className="leftNavIconActive"/> : <SwapIcon className="leftNavIconActive"/>}
              <span className="d-block d-sm-none item-text">Swap</span>
            </li>
            <Tooltip place="right"  content={"Swap page"} anchorSelect="#SwapPageNav" isOpen={swapPageHover} />
            <div className="flexDivider"/>
            <div className="infoButtonsContainer">
              <div
                className="infoButton"
                data-tip="Toggle sound" 
                data-for="main" 
                onClick={_toggleMute}>
                 {isMuted && <Icon Icon={SoundIconMute} IconActive={SoundIconActiveMute} className="infoButtonIcon" /> }
                 {!isMuted && <Icon Icon={SoundIcon} IconActive={SoundActiveIcon} className="infoButtonIcon" />  }
                </div >
              {showInformation &&
              <div
                className="infoCloseIconContainer"
                data-tip="Close Information" 
                data-for="main"
                onClick={_toggleShowInformation} >
                 
                <CloseIcon className="infoCloseIcon" />
              </div> }
              {!showInformation &&
              <div
                className={`infoButton ${showInformationOutline ? "infoOutline" : ""}`}
                data-tip="Information" 
                data-for="main" 
                onClick={_toggleShowInformation}>
                  <Icon Icon={InformationIcon} IconActive={InformationActiveIcon}  />
                </div> }
              {showTutorial && !forceShowTutorial &&
              <div
                className="helpCloseIconContainer"
                data-tip="Close Help"
                data-for="main"
                onClick={_toggleShowTutorial} >
                <CloseIcon className="infoCloseIcon" />
              </div> }
             {(!showTutorial || forceShowTutorial) && <div
                className={`infoButton ${forceShowTutorial ? "infoOutline" : ""}`}
                data-tip="Help" 
                data-for="main"
                onClick={_toggleShowTutorial}>
                <Icon Icon={HelpIcon} IconActive={HelpActiveIcon} className="infoButtonIcon" />
              </div>}
            </div>
          </ul>)
}

function Leftnav() {
  const [show, setShow] = useState(false);
  const handleToggle = () => {
    setShow(!show);
  };

  return (
    <div className="sidebar" onClick={e => e.stopPropagation()}>
      <nav
        id="sidebarMenu"
        className="d-none d-sm-block sidebar sidebar-menu"
      >
        <div className="d-block d-md-none">
          <Modal
            show={show}
            onHide={() => setShow(false)}
            className="sidebar-modal"
          >
            <Modal.Header>
              Star Launch
            </Modal.Header>
            <Modal.Body>
              <NavContent />
            </Modal.Body>
          </Modal>
        </div>
        <div className="position-sticky left-nav">
          <div className="list-group list-group-flush ">
            <NavContent />

          </div>
        </div>
      </nav>
    </div>
  );
}

export default Leftnav;
