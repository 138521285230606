import React, {useCallback, useEffect, useState} from "react";
import styles from "./TokenModalSelect.module.scss";
import Modal from "../Modal/Modal";
import TextInput from "../TextInput/TextInput";
import Bottom from '../../images/svg/bottom.svg'
import {hasValue, useDebouncedEffect} from "../../Helpers/helperFunctions";
import {
    NO_TOKENS_RESPONSE,
    solanaDefaultToken,
    starsDefaultToken,
    usdcDefaultToken
} from "../../screens/Swap/swapConstants";
import {getApiUrl} from "../../Helpers/helper";
import {PublicKey} from "@solana/web3.js";

function TokenModalSelect(props) {
    const {
        value,
        setValue,
        options,
        solanaBalance,
        tokenAccs,
        connection,
        fetchToken,
        disabled = false,
    } = props;

    const pinnedTokens = [ solanaDefaultToken, starsDefaultToken, usdcDefaultToken ];
    const pinnedTokenMints = [ solanaDefaultToken.value, starsDefaultToken.value, usdcDefaultToken.value];

    const [modalOpen, setModalOpen] = useState(false);
    const [searchValue, setSearchValue] = useState();
    const [inWallet, setInWallet] = useState([]);
    const [pinnedTokensState, setPinnedTokensState] = useState();
    const [tokenSearchMessage, setTokenSearchMessage] = useState();

    const apiUrl = getApiUrl();

    useEffect(() => {
        const fetchTokensInWallet = async () => {
            let finalResult = [];
            let missingTokens = [];
            for (let i = 0; i < tokenAccs.length; i++) {
                const acc = tokenAccs[i];

                const token = options.official.find(opt => opt.value === acc.accountInfo.mint.toBase58());
                if (token) {
                    //const accBalance = await connection.getTokenAccountBalance(acc.pubkey);

                    //if (accBalance.value.uiAmount !== 0) {
                        if (!pinnedTokens.find(t => t.value === token.value)) {
                            token.balance = 0//accBalance.value.uiAmount;
                            finalResult.push(token)
                        }
                    //}
                } else {
                    //const accBalance = await connection.getTokenAccountBalance(acc.pubkey);
                    //if (accBalance.value.uiAmount !== 0) {
                        missingTokens.push({ mint: acc.accountInfo.mint.toBase58(), acc: acc.pubkey, balance: 0});
                   // }

                }
            }

            let pinnedTokenResult = [];
            for (let i = 0; i < pinnedTokens.length; i++) {
                const pinToken = pinnedTokens[i];

                const acc = tokenAccs.find(acc => acc.accountInfo.mint.toBase58() === pinToken.value )
                if (acc) {
                    console.log(acc.pubkey)
/*                    const accBalance = await connection.getTokenAccountBalance(acc.pubkey);

                    if (accBalance.value.uiAmount !== 0) {
                        pinToken.balance = accBalance.value.uiAmount;
                    }*/
                    pinnedTokenResult.push(pinToken);
                }
            }

            const solToken = options.official.find(opt => opt.value === solanaDefaultToken.value);
            if (solanaBalance != null && solanaBalance > 0) {
                if (solToken) {
                    solToken.balance = solanaBalance.toFixed(4);
                    pinnedTokenResult.push(solToken)
                }
            } else {
                pinnedTokenResult.push(solToken)
            }
            if (missingTokens.length > 0) {
                const result = await fetchMintTokens(missingTokens);
                setInWallet(finalResult.concat(result))
            } else {
                setInWallet(finalResult)
            }
            setPinnedTokensState(pinnedTokenResult);

        }
        if (modalOpen === true && inWallet.length === 0) {
            fetchTokensInWallet();
        }

    }, [modalOpen]);
    console.log(inWallet)
    useDebouncedEffect(async () => {
        if (hasValue(searchValue)) {
            const result = await fetchToken(searchValue);
            if (result === NO_TOKENS_RESPONSE) {
                setTokenSearchMessage('NO TOKENS FOUND')
            }
        }
    }, 700, [searchValue]);

    useEffect(() => {
        if (hasValue(tokenSearchMessage)) setTokenSearchMessage('');
    }, [searchValue]);

    const fetchMintTokens = async (value) => {
        const valueString = value.map(obj => obj.mint).join(',');

        const result = await fetch(`${apiUrl}/tokenMintsParam/${valueString}`)
          .then(r => r.json())
          .then((response)  => {
              return response.data.map(  token => {
                  const accObj = value.find(obj => obj.mint === token.mint);


                  return {
                      name: token.name,
                      key: token.symbol,
                      icon: token.icon,
                      value: token.mint,
                      decimals: token.decimals,
                      balance: accObj.balance,
                  };
              });
          })
        return result;
    }

    const toggleModalOpen = () => setModalOpen(prev => !prev);

    const setToken = useCallback((token) => {
        if (setValue) {
            setValue(token);
        }
        toggleModalOpen();
    }, [setValue]);

    const renderOptions = useCallback((tokenList, removePinned = false, useSearchFilter = false) => {
        if (!tokenList) {
            return;
        }

        return tokenList
            .filter(opt => {
                if (removePinned === true) {
                    return !pinnedTokenMints.includes(opt.value)
                }

                if (useSearchFilter === true) {
                    return searchValue?.toUpperCase().includes(opt.name.toUpperCase());
                }

                return true;
            })
            .map(option =>

        <div
            onClick={() => setToken(option)}
            className={value?.value === option.value ? styles.tokenOptionActive : styles.tokenOption}
            key={option.value}>
            <img className={styles.tokenIcon} src={option.icon} alt={option.name} />
            {option.key + ' - ' + option.name}
            <div style={{flexGrow: 1}} />
            <span className={styles.tokenOptionBalance}>{hasValue(option.balance) ? '' : ''}</span>
        </div>)
    }, [searchValue])

    return (
    <>
        <div className={styles.modalButtonContainer} onClick={toggleModalOpen}>
            {value.icon && <img className='tokenIcon' src={value.icon}/>}
            {value.key}
            <Bottom/>
        </div>
        {modalOpen &&
          <Modal
            show={modalOpen}
                setShow={toggleModalOpen}>
                <>
                    <div className={styles.searchContainer}>
                        <TextInput value={searchValue} setValue={setSearchValue} placeholder={'Search, Use name or mint'}  />
                    </div>
                    <div className={styles.tokenList}>
                        <div className={styles.tokenInWalletText}>Pinned</div>
                        {renderOptions(pinnedTokensState)}
                        {!hasValue(searchValue) && inWallet.length > 0 && <div className={styles.tokenInWalletText}>In wallet</div>}
                        {!hasValue(searchValue) && inWallet.length > 0 && renderOptions(inWallet)}
                        {hasValue(searchValue) && <div className={styles.tokenInWalletText}>Result</div>}
                        {hasValue(searchValue) && hasValue(tokenSearchMessage) && <div className={styles.tokenSearchMessage}>{tokenSearchMessage}</div>}
                        {hasValue(searchValue) && renderOptions(options.searchedTokens, true)}
                        {!hasValue(searchValue) && <div className={styles.tokenInWalletText}>Official</div>}
                        {!hasValue(searchValue) && renderOptions(options.official, true)}
                    </div>
                </>

          </Modal>}
    </>);
}

export default TokenModalSelect;
