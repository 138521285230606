import React, {useState, useEffect, useMemo, useCallback} from 'react';

import { Program, AnchorProvider, web3, BN } from '@project-serum/anchor';
// import anchor  from '@project-serum/anchor';
import toast from 'react-hot-toast';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import BigNumber from "bignumber.js";
import styles from "./IdoDetails.module.scss";
import IDOPageVideo from '../../images/idoPageBackground.webm';
import IDOPageVideoMobile from '../../images/idoPageBackgroundMobile.webm';
import IDOPageVideoMobileMp4 from '../../images/idoPageBackgroundMobile.mp4';
import BackButton from "../../images/svg/backIcon.svg"
import TelegramIcon from '../../images/svg/telegram.svg'
import TwitterIcon from '../../images/svg/twitter.svg'
import WebsiteIcon from '../../images/svg/website.svg'
import DiscordIcon from '../../images/svg/discord.svg'
import CaretForward from '../../images/svg/caretForward.svg';
import AccountAddIcon from '../../images/svg/account-add.svg';
import WalletNotConnectedIcon from '../../images/svg/walletNotConnected.svg';
import starlaunchBannerThumbnail from '../../images/sl_video_thumb.png';
import { useHistory, useParams } from 'react-router-dom';
import { PATH_IDO_PAGE, PATH_PLAYER_DASHBOARD } from '../../App';
import Button, {
  ProgressButtonClickAudio,
  ProgressButtonHoverAudio
} from '../../Components/Button/Button';
import { useSelector, useDispatch } from 'react-redux';
import { authInfo, authTitle, connectWalletText, connectWalletTitle } from '../Vault/Vault';
import InfoCard from '../../Components/InfoCard/InfoCard';
import { useRef } from 'react';
import useMobileDetect from 'use-mobile-detect-hook';
import LoadingSpinner, {
  LOADING_SPINNER_LARGE,
  LOADING_SPINNER_MEDIUM
} from '../../Components/LoadingSpinner/LoadingSpinner';
import * as helpers from '../../Helpers/helper';
import {
  getNodeIdoProgramId,
  KYC_STATUS_APPROVED,
  openURL
} from "../../Helpers/helper";

import {getTokenAccountsByOwner} from "../../utils";
import {
  BLOCKCHAIN_ARBITRUM, BLOCKCHAIN_AVALANCHE, BLOCKCHAIN_BASE, BLOCKCHAIN_DYMENSION,
  BLOCKCHAIN_ETH,
  BLOCKCHAIN_POLYGON, BLOCKCHAIN_SKALE,
  BLOCKCHAIN_SOLANA, BLOCKCHAIN_SUI, BLOCKCHAIN_SUPRA, calculateBlockchain,
  hasValue, isSUIAddress
} from "../../Helpers/helperFunctions";
import {
  createIdoAccount, createNodeIdoAccount, createNodeParticipantAccount,
  getAccountData, getNodeSaleStage, hasSaleEnded, IDO_NODE_SALE, IDO_STAGE_CLAIM_OPEN, IDO_STAGE_FCFS_SALE,
  IDO_STAGE_HYDRAZINE_CONTRIBUTION, IDO_STAGE_REFUNDS_OPEN,
  IDO_STAGE_SETTING_ALLOCATION, IDO_STAGE_UPCOMING,
  IDO_STAGE_USDC_CONTRIBUTION,
  isSaleOngoing, SALE_TYPE_LAUNCH_VOTE
} from "../../Helpers/idoHelper";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import IdoSaleStage from "./IdoSaleStage";
import starlaunchBanner from "../../images/starlaunch-banner.mp4";
import MediumIcon from "../../images/svg/medium.svg";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IdoSalesCard from "./IdoSalesCard";
import {styled} from "@mui/material";
import TokenInformation from "./TokenInformation";
import draftToHtml from 'draftjs-to-html';
import {IDO_NODE_NAME, PROGRAM_NAME} from "./IdoNodeSaleDetails";
import IdoNodeSaleStage from "./IdoNodeSaleStage";
import {errorToast, successToast} from "../../Helpers/toastCSS";
import {setShowCreateIdoAccount, setShowCreateNodeIdoAccount} from "../../Components/Header/statistics";
import {isAddress} from "web3-validator";
import TokenClaimTab from "./TokenClaimTab";
import moment from "moment";
import {SystemProgram, SYSVAR_RENT_PUBKEY} from "@solana/web3.js";

const TAB_PRODUCT_INFORMATION = 0;
const TAB_TOKEN_SALE = 1;
const TAB_SALE_INFORMATION = 2;
const TAB_CLAIMS_PAGE = 3;

export const NODE_OG_SALE_STAGE = 'OG Round';
export const NODE_STAKERS_SALE_STAGE = 'Stakers Round';
export const NODE_FCFS_SALE_STAGE = 'Public Round'

function IdoDetails(props) {
  const {
    provider
  } = props;

  const { connection } = useConnection();
  const wallet = useWallet();
  const history = useHistory();
  const dispatch = useDispatch();
  const detectMobile = useMobileDetect();
  const params = useParams();
  const idoName = params.idoName;;

  const [isLoading, setIsLoading] = useState(true);
  const [isSendingTransaction, setIsSendingTransaction] = useState(false);
  const [saleStage, setSaleStage] = useState(IDO_STAGE_UPCOMING);
  const [idoKYC, setIDOKYC] = useState();
  const [tab, setTab] = useState(0);

  const [idoInfo, setIdoInfo] = useState();
  const [poolData, setPoolData] = useState();
  const [walletBalance, setWalletBalance] = useState({ usdc: 0, hydrazine: 0 })
  const [participantInfo, setParticipantInfo] = useState(null);
  const [refundParticipantInfo, setRefundParticipantInfo] = useState(null);
  const [fcfsInfo, setFcfsInfo] = useState()
  const [vestedAmount, setVestedAmount] = useState();
  const [allocationInfo, setAllocationInfo] = useState({
    index: 0,
    amt: null,
    proof: null,
    allocation: null,
  })

  const idoContainer = useRef();

  const isMuted = useSelector((state) => state.sound.mute);
  const hasCadetAccount = !useSelector((state) => state.statistics.showCreateAccount);
  const hasIdoAccount = !useSelector((state) => state.statistics.showCreateIdoAccount);
  const hasNodeIdoAccount = !useSelector((state) => state.statistics.showCreateNodeIdoAccount);
  const personallyStakedStars = useSelector((state) => state.statistics.personalCryptoStats.personallyStakedStars);
  const KYCStatus = useSelector((state) => state.statistics.userStats.KYCStatus);
  const allIDOs = useSelector((state) => state.idoData.allIDOs);
  const cadetStats = useSelector((state) => state.statistics.cadetStats);

  const hydrazineMint = helpers.getHydrazineMintAddress();
  const usdcMint = helpers.getUsdcMintAddress();
  const poolname = hasValue(idoInfo?.poolname) ? idoInfo?.poolname : idoInfo?.devPoolname;
  const blockchain = calculateBlockchain(idoInfo);
  const isSaleOngoingBool = isSaleOngoing(idoInfo);

  const primaryContainerRef = useRef();

  useEffect(() => {
    if (!idoInfo && allIDOs.length > 0) {
      const currentIDO = allIDOs.find(ido => idoName.toLowerCase() === ido.name.replace(/\s/g,'').toLowerCase())

      setIdoInfo(currentIDO);
    }
  }, [idoInfo, allIDOs]);

  useEffect(() => {
    if (provider && provider.wallet.connected && idoInfo && hasValue(poolname)) {
      fetchTokensBalance();
      fetchContractData();
    }
    if (idoInfo) {
      if (idoInfo.isNodeSale) {
        const stage = getNodeSaleStage(idoInfo);
        setSaleStage(stage);
      } else {
      }
    }
  }, [provider, idoInfo, hasNodeIdoAccount]);

  useEffect(() => {
    if (isSaleOngoingBool) {
      openAndScrollToSale();
    }
  }, [isSaleOngoingBool]);

  useEffect(() => {
    const interval = setInterval(async () => {
      await fetchContractData();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const fetchContractData = async () => {
    if (idoInfo) {
      if (idoInfo.isNodeSale) {
        const stage = getNodeSaleStage(idoInfo);
        setSaleStage(stage);
       await fetchNodeIdoInfo();
      } else {
       await fetchIdoInfo();
      }
    }
  }

  const onCountdownComplete = async () => {
      if (poolData?.poolInfo) {
        window.location.reload();
      }
  }

  const fetchNodeIdoInfo = async () => {
    if (hasNodeIdoAccount) {

      const nodeIdoProgram = helpers.getNodeProgram(provider);

      const accts = await helpers.getAddys(provider, poolname, true);

      const poolInfo = await nodeIdoProgram.account.pool.fetch(accts.nodePool);

      setPoolData({ poolInfo });
      const pptInit = await connection.getBalance(accts.nodePpt);

      if (pptInit > 0) {

        const pptInfo = await nodeIdoProgram.account.participant.fetch(accts.nodePpt);
        setParticipantInfo(pptInfo);
      }
    }
  }

  const fetchIdoInfo = async () => {
    const {
      mainData,
      poolInfo,
      stage,
      participantInfo,
      vestedAmount,
      refundParticipantInfo,
      saleKYC,
      initialPurchase,
      maxPurchase,
      allocationInfo,
    } = await getAccountData(provider, poolname, connection, hasCadetAccount, dispatch);
    setPoolData({ mainData, poolInfo });
    console.log(stage)
    setSaleStage(stage);
    setParticipantInfo(participantInfo);
    setRefundParticipantInfo(refundParticipantInfo);
    setFcfsInfo({initialPurchase, maxPurchase})
    setAllocationInfo(allocationInfo);
    setVestedAmount(vestedAmount);

    if (hasValue(saleKYC)) {
      setIDOKYC(saleKYC);
    } else {
      setIDOKYC(KYCStatus);
    }
  }

  async function createUser() {
    try {
      setIsSendingTransaction(true);
      await createNodeIdoAccount(provider, connection, poolname);
      toast("NODE IDO Account Created", successToast);
      dispatch(setShowCreateNodeIdoAccount(false));
      await fetchContractData();
    } catch (e) {
      console.warn("Failed", e);
      toast(e.message, errorToast);
    } finally {
      setIsSendingTransaction(false);
    }
  }

  async function makeIdoAccount() {
    try {
      const accts = await helpers.getAddys(provider, idoName);
      const idoProgram = helpers.getIdoProgram(provider);
      const transaction = new web3.Transaction();

      transaction.add(
        idoProgram.instruction.createUser(
          accts.idoAcctBump, {
            accounts: {
              userAuthority: provider.wallet.publicKey,
              idoUser: accts.idoAcct,
              mainData: accts.mainData,
              systemProgram: SystemProgram.programId,
              rent: SYSVAR_RENT_PUBKEY,
            },
          })
      );
      // }
      const signature = await provider.wallet.sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'processed');
      toast("IDO Account Created", successToast);
      dispatch(setShowCreateIdoAccount(false));
    } catch (e) {
      console.warn("Failed", e);
      toast(e.message, errorToast);
    }
  }

  const fetchTokensBalance = async () => {
    const tokenAccs = await getTokenAccountsByOwner(connection, wallet.publicKey);

    const hydrazine = tokenAccs.filter(acc => acc.accountInfo.mint.toBase58() === hydrazineMint.toBase58())
    const usdc = tokenAccs.filter(acc => acc.accountInfo.mint.toBase58() === usdcMint.toBase58())

    let hydrazineReturn = 0;
    let usdcReturn = 0;

    if (hydrazine !== null && hydrazine.length !== 0) {

      const hydrazineBalance = await connection.getTokenAccountBalance(hydrazine[0].pubkey);

      hydrazineReturn = hydrazineBalance.value.uiAmount || 0;
    }

    if (usdc !== null && usdc.length !== 0) {
      const usdcBalance = await connection.getTokenAccountBalance(usdc[0].pubkey);

      usdcReturn = usdcBalance.value.uiAmount || 0;
    }

    return setWalletBalance({usdc: usdcReturn, hydrazine: hydrazineReturn });
  }

  const saleEnded = hasSaleEnded({ poolInfo: poolData?.poolInfo, idoInfo });

  const showWalletMissingContainer = useMemo(() => {
    if (saleEnded) return false;

    if (blockchain === BLOCKCHAIN_SOLANA) {
      return false;
    }
    if (blockchain === BLOCKCHAIN_ETH && isAddress(cadetStats.cadetEthereumWallet)) return false
    if (blockchain === BLOCKCHAIN_POLYGON && isAddress(cadetStats.cadetPolygonWallet)) return false
    if (blockchain === BLOCKCHAIN_ARBITRUM && isAddress(cadetStats.cadetArbitrumWallet)) return false
    if (blockchain === BLOCKCHAIN_SUPRA && isAddress(cadetStats.cadetSupraWallet)) return false
    if (blockchain === BLOCKCHAIN_SUI && isSUIAddress(cadetStats.cadetSUIWallet)) return false
    if (blockchain === BLOCKCHAIN_AVALANCHE && isAddress(cadetStats.cadetAvalancheWallet)) return false
    if (blockchain === BLOCKCHAIN_DYMENSION && isAddress(cadetStats.cadetDymensionWallet)) return false
    if (blockchain === BLOCKCHAIN_SKALE && isAddress(cadetStats.cadetSkaleWallet)) return false
    if (blockchain === BLOCKCHAIN_BASE && isAddress(cadetStats.cadetBaseWallet)) return false


    return true;
  }, [blockchain, cadetStats]);

  const backButtonClick = () => {
    if (!isMuted) {
      ProgressButtonClickAudio.play();
    }
    history.push(PATH_IDO_PAGE);
  }

  const _onHover = () => {
    if (!isMuted) {
      ProgressButtonHoverAudio.play();
    }
  }

  const saleInfoRender = useMemo(() => {
    if (idoInfo?.HTMLDescription) {

      const htmlData = draftToHtml(idoInfo.HTMLDescription);

      return (
        <>
          <pre className={styles.htmlContent} dangerouslySetInnerHTML={{__html: htmlData}}>
          </pre>
        </>
      )
    }

    return (
      <>
        <div className={styles.contentSubTitle}>IDO Card description</div>
        <div className={styles.contentText}>
          {idoInfo?.description}
        </div>
      </>
    )
  }, [idoInfo]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const carouselContent = useMemo(() => {
    const finalsource = [];

    if (idoInfo?.bannerVideos) {
      idoInfo?.bannerVideos.forEach(video => finalsource.push({ isVideo: true, url: video }));
    }

    if (idoInfo?.bannerImages) {
      idoInfo?.bannerImages.forEach(image => finalsource.push({ isVideo: false, url: image}));
    }

    finalsource.push({ isVideo: true, url: starlaunchBanner})

    return finalsource.map(source => {
      if (source.isVideo === false) {
        return (
          <div>
            <img src={source.url} alt="banner" className={styles.idoBannerImage} />
          </div>
        )
      }
      return (
        <div>
          <video
            className={styles.idoDetailsVideoBanner}
            autoPlay
            loop
            playsInline
            width="100%"
            muted={true}>

            <source src={source.url}
                    type='video/mp4'/>
          </video>
        </div>
      )
    })
  }, [idoInfo])

  const carouselThumb = (children) =>
   children.map(child => {
     if (child.props.children.type === 'img') {
       return (
           <img className={styles.videoThumbnail} src={child.props.children.props.src}/>
       );
     }

     return (
         <img className={styles.videoThumbnail} src={starlaunchBannerThumbnail}/>
     );
   })

  const nodeSaleStages = () => {
    const stages = [];

    if (idoInfo?.nodeTiers?.ogTier?.active) {
      stages.push(<IdoNodeSaleStage
        stage={NODE_OG_SALE_STAGE}
        {...saleStageProps}
      />)
    }

    if (idoInfo?.nodeTiers?.stakerTier?.active) {
      stages.push(<IdoNodeSaleStage
        stage={NODE_STAKERS_SALE_STAGE}
        {...saleStageProps}
      />)
    }
    if (idoInfo?.nodeTiers?.fcfsTier?.active) {
      stages.push(<IdoNodeSaleStage
        stage={NODE_FCFS_SALE_STAGE}
        {...saleStageProps}
      />)
    }
    if (hasValue(saleStage)) {
      const stageToIndex = {
        [NODE_OG_SALE_STAGE]: 0,
        [NODE_STAKERS_SALE_STAGE]: 1,
        [NODE_FCFS_SALE_STAGE]: 2,
      };


      stages.unshift(stages.splice(stageToIndex[saleStage], 1)[0]);
    }

    return stages
  }
  const idoSaleStages = () => {
    const stages = [];

    stages.push(<IdoSaleStage
      stage={IDO_STAGE_HYDRAZINE_CONTRIBUTION}
      {...saleStageProps}
    />)

    stages.push( <IdoSaleStage
      stage={IDO_STAGE_USDC_CONTRIBUTION}
      {...saleStageProps}
    />)

    stages.push( <IdoSaleStage
      stage={IDO_STAGE_FCFS_SALE}
      {...saleStageProps}
    />)

    if (hasValue(saleStage)) {
      const stageToIndex = {
        [IDO_STAGE_HYDRAZINE_CONTRIBUTION]: 0,
        [IDO_STAGE_USDC_CONTRIBUTION]: 1,
        [IDO_STAGE_FCFS_SALE]: 2,
      };


      stages.unshift(stages.splice(stageToIndex[saleStage], 1)[0]);
    }

    return stages
  }

  const openAndScrollToSale = () => {
     setTab(TAB_TOKEN_SALE);

     if (idoContainer.current) {
       idoContainer.current.scrollBy({top:400, behavior: 'smooth'});
     }
  }

  const saleStageProps = {
    currentStage: saleStage,
    poolData,
    participantInfo,
    setParticipantInfo,
    allocationInfo,
    provider,
    walletBalance,
    idoInfo,
    setIsSendingTransaction,
  }

  const showClaimsTab = saleStage === IDO_STAGE_CLAIM_OPEN || saleStage === IDO_STAGE_REFUNDS_OPEN;
  const isLaunchVote = idoInfo?.saleType === SALE_TYPE_LAUNCH_VOTE;

  return (
    <main key="IdoNodeSaleDetails"
          className={styles.idoNodeSaleDetailsMain}>
      {isSendingTransaction && <div className={styles.transactionPendingOverlay}>
        <span
          className={styles.transactionPendingText}>blockchain transaction is pending, do not close this window</span>
        <LoadingSpinner/>
      </div>}
      <div className='idoOverlay'/>
      <div className={styles.idoContainer}>

        <div className={wallet.publicKey ? styles.backButton : styles.backButtonPosition} onClick={backButtonClick}
             onMouseEnter={_onHover}>
          <BackButton className={styles.backButtonIcon}/>
          <span>BACK</span>
        </div>

        {idoInfo &&
          <div className={styles.idoNodeSaleDetails} style={isSaleOngoingBool ? { paddingTop: '50px'} : {}}>
            {isSaleOngoingBool && <div onClick={openAndScrollToSale} className={styles.saleOpenBanner}>THE SALE IS NOW LIVE! CLICK HERE TO JOIN!</div>}
            <div className={styles.idoTitleRow}>
              <div className={styles.idoLogo}>
                <img className={styles.idoLogoPicture} src={idoInfo?.icon} alt=""/>
              </div>
              <div className={styles.saleInfoContentHeader}>
                <h4 className={styles.contentTitle}>{idoInfo.name}</h4>
                <span className={styles.contentHeaderSubTitle}>{idoInfo.headerDescription}</span>
                <div className={styles.socialMediaRow}>
                  {idoInfo.medium && <div className={styles.socialMediaBox} onClick={() => openURL(idoInfo.medium)}>
                    <MediumIcon className={styles.socialMediaIcon}/>
                  </div>}
                  {idoInfo.twitter && <div className={styles.socialMediaBox} onClick={() => openURL(idoInfo.twitter)}>
                    <TwitterIcon className={styles.socialMediaIcon}/>
                  </div>}
                  {idoInfo.telegram && <div className={styles.socialMediaBox} onClick={() => openURL(idoInfo.telegram)}>
                    <TelegramIcon className={styles.socialMediaIcon}/>
                  </div>}
                  {idoInfo.discord && <div className={styles.socialMediaBox} onClick={() => openURL(idoInfo.discord)}>
                    <DiscordIcon className={styles.socialMediaIcon}/>
                  </div>}
                  {idoInfo.website && <div className={styles.socialMediaBox} onClick={() => openURL(idoInfo.website)}>
                    <WebsiteIcon className={styles.socialMediaIcon}/>
                  </div>}
                </div>
              </div>

            </div>
            <div className={styles.idoInnerContainerRow} ref={idoContainer}>
              <div className={styles.primaryContainersColumn}>
                <div className={styles.carouselContainer}>
                  <Carousel renderThumbs={carouselThumb} thumbWidth={160}>
                    {carouselContent}
                  </Carousel>
                </div>
                <div className={styles.primaryContainerCard} ref={primaryContainerRef}>
                  {!isLaunchVote && <div className={styles.tabContainer}>
                    {detectMobile.isMobile() ?
                      <AntTabs
                        centered
                        value={tab}
                        onChange={handleTabChange}
                        aria-label="basic tabs example">
                        <AntTabMobile label="Project Information" {...a11yProps(TAB_PRODUCT_INFORMATION)} />
                        <AntTabMobile
                          label={idoInfo.isNodeSale ? 'Node Sale' : 'Token Sale'} {...a11yProps(TAB_TOKEN_SALE)} />
                        <AntTabMobile label="Sale Information" {...a11yProps(TAB_SALE_INFORMATION)} />
                        {showClaimsTab && <AntTab label="Token Claim" {...a11yProps(TAB_CLAIMS_PAGE)} />}
                      </AntTabs>
                      : <AntTabs
                        centered
                        value={tab}
                        onChange={handleTabChange}
                        aria-label="basic tabs example">
                        <AntTab label="Project Information" {...a11yProps(TAB_PRODUCT_INFORMATION)} />
                        <AntTab
                          label={idoInfo.isNodeSale ? 'Node Sale' : 'Token Sale'} {...a11yProps(TAB_TOKEN_SALE)} />
                        <AntTab label="Sale Information" {...a11yProps(TAB_SALE_INFORMATION)} />
                        {showClaimsTab && <AntTab label="Token Claim" {...a11yProps(TAB_CLAIMS_PAGE)} />}
                      </AntTabs>}
                  </div>}
                  {tab === TAB_PRODUCT_INFORMATION && saleInfoRender}
                  {tab === TAB_TOKEN_SALE &&
                    <>
                      {!wallet.publicKey &&
                        <div className={styles.walletKYCText}>
                          <br/>
                          PLEASE CONNECT YOUR WALLET TO CONTINUE
                          <br/>
                        </div>}
                      {!hasCadetAccount && wallet.publicKey &&
                        <div className={styles.walletKYCText}>
                          PLEASE CREATE A CADET ACCOUNT IN THE PLAYER DASHBOARD TO PARTICIPATE IN THE SALE.
                          CLICK HERE TO PROCEED:
                          <br/>
                          <br/>
                          <Button text="PLAYER DASHBOARD" onClick={() => history.push(PATH_PLAYER_DASHBOARD)}/>
                        </div>}
                      {wallet.publicKey && hasCadetAccount && showWalletMissingContainer &&
                    <div className={styles.walletKYCText}>
                    <div className='idoBlockchainInfo'>
                          This project is launching on
                          <span className={styles.idoBlockchainInfoChain}
                                onClick={() => history.push(PATH_PLAYER_DASHBOARD)}>{' ' + blockchain + ' '}</span>
                          to participate in the sale, please add
                          <span className={styles.idoBlockchainInfoChain}
                                onClick={() => history.push(PATH_PLAYER_DASHBOARD)}> {' ' + blockchain + ' '}</span>
                          wallet in your cadet dashboard
                        </div>
                      </div>}
                      {wallet.publicKey && hasCadetAccount && KYCStatus !== KYC_STATUS_APPROVED && <div className={styles.walletKYCText}>
                        YOU ARE NOT KYCed, PLEASE PRESS THE "KYC INFO" BUTTON IN THE HEADER TO PROCEED
                      </div>}
                      {!showWalletMissingContainer && KYCStatus === KYC_STATUS_APPROVED && wallet.publicKey && hasCadetAccount &&
                        <div className={styles.salesInnerContainer}>
                          <div>
                            <h4 className={styles.contentTitle}>Sale Stages</h4>
                          </div>
                          {idoInfo.isNodeSale && <>
                            {!hasNodeIdoAccount && idoInfo.isNodeSale &&
                              <div className={styles.createUserButtonContainer}>
                                <span className={styles.createUserText}>TO VIEW OR PARTICIPATE IN THIS NODE SALE, PLEASE CREATE A NODE IDO ACCOUNT</span>
                                <Button text="CREATE NODE IDO ACCOUNT" onClick={createUser}/>
                              </div>}

                            {hasNodeIdoAccount &&
                              <>
                                {nodeSaleStages()}
                              </>
                            }
                          </>}
                          {!idoInfo.isNodeSale &&
                            <>
                              {!hasIdoAccount ?
                                <div className={styles.createUserButtonContainer}>
                                  <span className={styles.createUserText}>TO VIEW OR PARTICIPATE IN THIS IDO SALE, PLEASE CREATE A IDO ACCOUNT</span>
                                  <Button text="CREATE IDO ACCOUNT" onClick={makeIdoAccount}/>
                                </div> :
                              idoSaleStages()}
                            </>}
                        </div>}
                    </>}
                  {tab === TAB_SALE_INFORMATION && <TokenInformation idoInfo={idoInfo} poolData={poolData}/>}
                  {tab === TAB_CLAIMS_PAGE &&
                    <TokenClaimTab
                      idoInfo={idoInfo}
                      poolData={poolData}
                      provider={provider}
                      participantInfo={participantInfo}
                      allocationInfo={allocationInfo}
                      vestedAmount={vestedAmount}
                      currentStage={saleStage}/>}
                </div>
              </div>
              <div className={styles.secondaryContainer}>
                <div className={styles.secondaryContainerCard}>
                  {idoInfo && <IdoSalesCard
                    idoInfo={idoInfo}
                    poolData={poolData}
                    fetchContractData={onCountdownComplete}
                    currentStage={saleStage}/>}

                </div>
              </div>
            </div>

          </div>}
      </div>
      {!detectMobile.isMobile() &&
        <video
          className='backgroundVideo'
          autoPlay
          loop
          muted
          playsInline
          onLoadedData={() => setIsLoading(false)}>
          <source src={IDOPageVideo} type='video/webm'/>
        </video>}
      {detectMobile.isMobile() && !detectMobile.isIos() &&
        <video
          className='backgroundVideo'
          autoPlay
          loop
          muted
          playsInline
          onLoadedData={() => setIsLoading(false)}>
          <source src={IDOPageVideoMobile} type='video/webm'/>
          <source src={IDOPageVideoMobileMp4} type='video/mp4'/>
        </video>}
      {detectMobile.isMobile() && detectMobile.isIos() &&
        <img className='backgroundVideo' src={IDOPageVideoMobileMp4} alt="" onLoad={() => setIsLoading(false)}/>}
      {isLoading && <LoadingSpinner/>}
      {/*  {videoOff && <img className='backgroundVideo' src={IDOPageImage} alt="" />} */}

    </main>
  );
}

export default IdoDetails;

const AntTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  minWidth: 0,
  fontSize: '16px',
  [theme.breakpoints.down('xl')]: {
    minWidth: 0,
    fontSize: '14px'
  },
  color: '#FF9900',
  '&:hover': {
    color: '#fffff',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#FF9900',
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const AntTabMobile = styled((props) => <Tab {...props} />)(({ theme }) => ({
  minWidth: 0,
  fontSize: '16px',
  [theme.breakpoints.down('xl')]: {
    minWidth: 0,
    fontSize: '10px',
    padding: '4px',
  },
  color: '#FF9900',
  '&:hover': {
    color: '#fffff',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#FF9900',
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const AntTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#FF9900',
  },
});
