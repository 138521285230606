import React, {useEffect, useMemo, useState} from "react";
import styles from "./IdoPurchaseAllocation.module.scss";
import {
  burnHydrazine,
  buyAllocation, buyFCFSAllocation, createNodeParticipantAccount,
  getAllocationPerHydrazine,
  getEstimatedAllocation, getIndividualAllocation, getMaxContributedUSDC,
  getProgressBarInformation, getTotalContributedUSDC,
  getTotalHydrazineContributed,
  getTotalUserContributedHydrazine,
  getUserAllocation,
  getUserFCFSContribution, getUserUSDCContribution,
  IDO_STAGE_FCFS_SALE,
  IDO_STAGE_HYDRAZINE_CONTRIBUTION,
  IDO_STAGE_USDC_CONTRIBUTION, purchaseNode, toBytes32Array
} from "../../Helpers/idoHelper";
import TextInput from "../../Components/TextInput/TextInput";
import Button from "../../Components/Button/Button";
import * as helpers from "../../Helpers/helper";
import {BN, web3} from "@project-serum/anchor";
import toast from "react-hot-toast";
import {errorToast, successToast} from "../../Helpers/toastCSS";
import BigNumber from "bignumber.js";
import {Buffer} from "buffer";
import {ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID} from "@solana/spl-token";
import {getErrorMessage} from "../../utils/utils";
import {useConnection} from "@solana/wallet-adapter-react";
import {useParams} from "react-router-dom";
import {SystemProgram, SYSVAR_RENT_PUBKEY} from "@solana/web3.js";
import {Tooltip} from "react-tooltip";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import {hasValue} from "../../Helpers/helperFunctions";
import {getNodeIdoProgramId, openURL} from "../../Helpers/helper";
import {useSelector} from "react-redux";
import {PRODUCTION} from "../../constants/constants";
import {PROGRAM_NAME} from "./IdoNodeSaleDetails";
import {NODE_FCFS_SALE_STAGE, NODE_OG_SALE_STAGE, NODE_STAKERS_SALE_STAGE} from "./IdoDetails";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";

const IdoPurchaseAllocation = (props) => {
  const {
    idoInfo,
    merkelProof,
    poolData,
    currentStage,
    participantInfo,
    setParticipantInfo,
    setIsSendingTransaction,
    allocationInfo,
    provider,
    walletBalance,
    stage,
  } = props;

  const { connection } = useConnection();
  
  const [hydrazineContributionInput, setHydrazineContributionInput] = useState();
  const [usdcContributionInput, setUsdcContributionInput] = useState();
  const [transactionTimedOutError, setTransactionTimedOutError] = useState({ message: '', url: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [nodeAmountQuantity, setNodeAmountQuantity] = useState(1)

  const personalCryptoStats = useSelector((state) => state.statistics.personalCryptoStats);
  const personallyStakedStars = personalCryptoStats.personallyStakedStars;
  const poolname = hasValue(idoInfo?.poolname) ? idoInfo?.poolname : idoInfo?.devPoolname;

  const hydrazineMint = helpers.getHydrazineMintAddress();
  const usdcMint = helpers.getUsdcMintAddress();

  const totalHydrazineSpent =  getTotalHydrazineContributed(poolData?.poolInfo, idoInfo);
  const currentAllocationPerHydrazine =  getAllocationPerHydrazine(poolData?.poolInfo, idoInfo);
  const totalUserContributedHydrazine = getTotalUserContributedHydrazine(participantInfo);
  const currentEstimatedAllocation = getEstimatedAllocation({
    poolInfo: poolData?.poolInfo,
    idoInfo,
    participantInfo,
    userHydrazineInputValue: hydrazineContributionInput,
    })

  const userAllocation = getIndividualAllocation(poolData?.poolInfo, stage);

  const userNodePurchased =  participantInfo?.bought ? parseInt(participantInfo?.bought?.toString()) : 0;
  const nodesSold = poolData?.poolInfo?.nodesSold ? poolData?.poolInfo.nodesSold.toString() : 0;

  const getNodePrice = () => {
    if (!idoInfo?.isNodeSale) {
      return { hydrazine: 0, usdc: 0};
    }
    if (userAllocation === userNodePurchased) {
      return {
        hydrazine: 0,
        usdc: 0, tier: 0,
        disabled: true,
        reason: 'YOU HAVE PURCHASED THE MAX AMOUNT OF NODES FOR THIS STAGE',
      };
    }
    if (stage === NODE_OG_SALE_STAGE) {
      return { hydrazine: 0, usdc: 200, tier: 1};
    }

    if (stage === NODE_FCFS_SALE_STAGE) {
      return { hydrazine: 0, usdc: 230, tier: 3};
    }

    if (personallyStakedStars >= 1500 ) {
      return { hydrazine: 0, usdc: 200, tier: 2};
    }

    if (personallyStakedStars === null) {
      return {
        hydrazine: 0,
        usdc: 0,
        tier: 0,
        disabled: true,
        reason: 'FETCHING STARS STAKED AMOUNT FROM VAULTS...'
      };
    }

    return {
      hydrazine: 0,
      usdc: 0,
      tier: 0,
      disabled: true,
      reason: 'You need to stake more than 1500 STARS to be able to purchase a NODE, you are currently staking ' + personallyStakedStars
    };
  }

  const nodePrice = getNodePrice();
  async function createParticipant() {
    try {
      setIsSendingTransaction(true);
      if (idoInfo.isNodeSale) {
        await createNodeParticipantAccount(provider, connection, poolname);
      } else {
        //TODO Add normal IDO Participant account
      }

      toast("Participation Account Created", successToast);

    } catch (e) {
      console.warn("Failed", e);
      toast(e.message, errorToast);
    } finally {
      setIsSendingTransaction(false);
      window.location.reload();
    }
  }
  const saveOrClearErrorMessage = (message, url) => {
    if (!hasValue(message)) {
      if (hasValue(errorMessage)) setErrorMessage('');
      if (hasValue(transactionTimedOutError)) setTransactionTimedOutError({ message: ''})
    }
  }

  const contributeUSDC = async () => {
    if (hasValue(errorMessage)) setErrorMessage('');
    if (hasValue(transactionTimedOutError.message)) setTransactionTimedOutError({ message: '', url: ''});

    let successLocal = true;
    let errorMessageLocal = '';
    let urlLocal = '';

    setIsSendingTransaction(true);

    if (stage !== IDO_STAGE_FCFS_SALE) {
      const {
        success, errorMessage, url
      } = await buyAllocation({
        provider, idoName: poolname, connection, allocationInfo, buyAmount: usdcContributionInput,
      });

      successLocal = success;
      errorMessageLocal = errorMessage;
      urlLocal = url;

    } else {
      const {
        success, errorMessage, url
      } = await buyFCFSAllocation({
        provider, idoName: poolname, connection, allocationInfo, buyAmount: usdcContributionInput,
      });

      successLocal = success;
      errorMessageLocal = errorMessage;
      urlLocal = url;
    }

    setIsSendingTransaction(false);

    if (!successLocal) {
      if (hasValue(urlLocal)) {
        setTransactionTimedOutError({ message: errorMessageLocal, url: urlLocal})
      } else {
        setErrorMessage(errorMessageLocal);
      }
    }
  }

  const contributeHydrazine = async () => {
    try {
      setIsSendingTransaction(true);
      await burnHydrazine({
        provider,
        idoName: poolname,
        connection,
        allocationInfo,
        burnAmount: hydrazineContributionInput
      });
    } catch (e) {
      console.warn("Failed", e);
      toast(e.message, errorToast);
    } finally {
      setIsSendingTransaction(false);
    }
  }

  const purchaseNodeTransaction = async () => {
    try {
      setIsSendingTransaction(true);
      await purchaseNode({ provider, connection, stage, poolname, merkelProof, nodePrice, nodeAmountQuantity });
      window.location.reload();

    } catch (e) {
      console.warn("Failed", e);
      toast(e.message, errorToast);
    } finally {
      setIsSendingTransaction(false);
    }
  }

  const finalAllocation = () => {
    if (!hasValue(allocationInfo.amount)) return 0;

    return (parseInt(allocationInfo.amount) / 1000000).toLocaleString();
  }

  if (stage === IDO_STAGE_HYDRAZINE_CONTRIBUTION) {
    return (
      <div className={styles.idoHydrazineStagePurchaseAllocationContainer}>
        <div className={styles.hydrazineContributionContainer}>
          <div className={styles.contributionValueRow}>
            <span className={styles.contributionValueRowLabel}>YOUR HYDRAZINE BALANCE</span>
            <span className={styles.contributionValueUserValue}>{walletBalance.hydrazine.toLocaleString()}</span>
          </div>
          <div className={styles.contributionValueRow}>
            <span className={styles.contributionValueRowLabel}>AMOUNT TO CONTRIBUTE</span>
            <TextInput
              overrideContainerClassName={styles.allocationInputContainer}
              value={hydrazineContributionInput}
              setValue={setHydrazineContributionInput}
              placeholder="INPUT HYDRAZINE"
            />
          </div>
          <Button text="CONTRIBUTE" className={styles.contributeButton} onClick={contributeHydrazine} />
            <Tooltip id="estimatedAllocation">
            <span>
              This is an estimate and <u>not your final allocation.</u><br/>
              Your final allocation will depend on the total hydrazine contributed and will be confirmed once the window closes.
            </span>
          </Tooltip>
          <div className={styles.contributionValueRow}>
            <span className={styles.contributionValueRowLabel}>YOUR CONTRIBUTION</span>
            <span className={styles.contributionValueUserValue}>{totalUserContributedHydrazine.toLocaleString() + ' HYDRAZINE'}</span>
          </div>
          <div className={styles.contributionValueRow} data-tooltip-id="estimatedAllocation">
            <span className={styles.contributionValueRowLabel}>CURRENT ESTIMATED ALLOCATION</span>
            <span className={styles.contributionValueUserValue}>{currentEstimatedAllocation.toLocaleString()+ ' USDC'} </span>
          </div>
        </div>
        <div className={styles.contributionInfoContainer}>
          <div className={styles.contributionValueRow}>
            <span className={styles.contributionValueRowLabel}>TOTAL CONTRIBUTION:</span>
            <span
              className={styles.contributionValueRowValue}>{totalHydrazineSpent.toLocaleString() + ' HYDRAZINE'}</span>
          </div>
          <div className={styles.contributionValueRow}>
            <span className={styles.contributionValueRowLabel}>CURRENT ALLO PER HYDRAZINE:</span>
            <span
              className={styles.contributionValueRowValue}>{currentAllocationPerHydrazine.toLocaleString() + ' USDC'}</span>
          </div>
        </div>
      </div>
    )
  }

  if (idoInfo.isNodeSale) {

    const purchaseNodeButtonDisabled = userNodePurchased >= userAllocation || nodePrice.disabled

    const selectInputMapping = () => {
      if (stage === NODE_OG_SALE_STAGE) {
        return (<option>1</option>);
      }

      const limit = Array.from(Array((userAllocation - userNodePurchased) + 1).keys());

      return limit.map(i => {
        if (i === 0 ) return ;
        return (
          <option>{i}</option>
        )
      })
    }

    return (<div className={styles.idoUSDCStagePurchaseAllocationContainer}>
        <div className={styles.saleAllocationContainer}>
          <div className={styles.saleAllocationTitleRow}>
            {nodePrice?.disabled ?
              <div className={styles.saleEstimatedAllocationColumn}>
                <span>YOUR NODE PRICE:</span>
                <span
                  className={styles.estimatedAllocationError}>{nodePrice.reason}</span>
              </div> :
              <div className={styles.saleEstimatedAllocationColumn}>
                <span>YOUR NODE PRICE:</span>
                <span
                  className={styles.estimatedAllocationValue}>{nodePrice.hydrazine * nodeAmountQuantity + ' HYDRAZINE + ' + nodePrice.usdc * nodeAmountQuantity + ' USDC'}</span>
                {stage === NODE_STAKERS_SALE_STAGE && <span
                  className={styles.estimatedAllocationValueGreySmall}>{'BASED ON ' + personallyStakedStars + ' STARS STAKED'}</span>}
              </div>}
            <div className={styles.divider}/>
            <div className={styles.saleEstimatedAllocationColumn}>
              <span>YOUR ALLOCATION:</span>
              <span
                className={styles.estimatedAllocationValue}>{userAllocation + ' NODE(S)'}</span>
            </div>


          </div>
        </div>
        <div className={styles.idoPurchaseNodeContributionContainer}>
          <div className={styles.contributionInputRow}>
            {participantInfo && !purchaseNodeButtonDisabled && <select
              poolname="numberOfNodes"
              value={nodeAmountQuantity}
              className={styles.nodeSelectInput}
              onChange={(e) => setNodeAmountQuantity(e.target.value)}>
              {selectInputMapping()}

            </select>}
            {participantInfo && <Button text="PURCHASE NODE" disabled={purchaseNodeButtonDisabled} className={styles.contributeButton} onClick={purchaseNodeTransaction} /> }
            {!participantInfo && <Button text="CREATE PARTICIPATION ACCOUNT" onClick={createParticipant} />}
          </div>
          <div className={styles.infoRow}>
            <div className={styles.contributionNodeBox}>
              <div className={styles.contributionNodeValueRow}>
                <span className={styles.contributionValueRowLabel}>YOUR USDC BALANCE:</span>
                <span className={styles.contributionValueUserValue}>{walletBalance.usdc.toLocaleString()}</span>
              </div>
              <div className={styles.contributionNodeValueRow}>
                <span className={styles.contributionValueRowLabel}>YOUR HYDRAZINE BALANCE:</span>
                <span className={styles.contributionValueUserValue}>{walletBalance.hydrazine.toLocaleString()}</span>
              </div>

            </div>
            <div className={styles.contributionNodeBox}>
              <div className={styles.contributionNodeValueRow}>
                <span className={styles.contributionValueRowLabel}>YOUR NODES FUNDED:</span>
                <span className={styles.contributionValueUserValue}>{hasValue(userNodePurchased) ? userNodePurchased : 0}</span>
              </div>
              <div className={styles.contributionNodeValueRow}>
                <span className={styles.contributionValueRowLabel}>TOTAL NODES FUNDED:</span>
                <span className={styles.contributionValueUserValue}>{nodesSold}</span>
              </div>

            </div>
          </div>
        </div>
        {hasValue(transactionTimedOutError.url) &&
          <div className={styles.transactionTimedOutError} onClick={() => openURL(transactionTimedOutError.url)}>
            <span className={styles.transactionTimedOutError}>{transactionTimedOutError.message}</span>
          </div>}
        {hasValue(errorMessage) && <div className={styles.contributionValueRow}>
          <span className={styles.contributionValueRowError}>{errorMessage}</span>
        </div>}
      </div>
    )
  }

  const userContribution = stage === IDO_STAGE_FCFS_SALE ?
    getUserFCFSContribution(participantInfo, poolData?.poolInfo) :
    getUserUSDCContribution(participantInfo, poolData?.poolInfo);
  const saleStageInfo = getProgressBarInformation(stage, poolData?.poolInfo, idoInfo)

  const maxContributedUSDC = getMaxContributedUSDC(poolData.poolInfo);
  const totalContributedUSDC = getTotalContributedUSDC(poolData.poolInfo);

  return (
    <div className={styles.idoUSDCStagePurchaseAllocationContainer}>
      <div className={styles.saleAllocationContainer}>
        {currentStage !== IDO_STAGE_FCFS_SALE && <div className={styles.saleEstimatedAllocationColumn}>
          <span>YOUR GUARANTEED ALLOCATION:</span>
          <span className={styles.estimatedAllocationValue}>{finalAllocation() + ' USDC'}</span>
        </div>}
      </div>
      <div className={styles.idoPurchaseContributionContainer}>
        <div className={styles.contributionBox}>
          <div className={styles.contributionValueRow}>
            <span className={styles.contributionValueRowLabel}>YOUR USDC BALANCE</span>
            <span className={styles.contributionValueUserValue}>{walletBalance.usdc.toLocaleString()}</span>
          </div>
          <div className={styles.contributionInputRow}>
            <span className={styles.contributionValueRowLabel}>AMOUNT TO CONTRIBUTE</span>
            <TextInput
              overrideContainerClassName={styles.allocationInputContainer}
              value={usdcContributionInput}
              setValue={setUsdcContributionInput}
              placeholder="INPUT USDC"
            />
          </div>
          <Button text="PURCHASE" className={styles.contributeButton} onClick={contributeUSDC} />
        </div>
        <div className={styles.contributionBox}>
          <div className={styles.contributionValueRow}>
            <span className={styles.contributionValueRowLabel}>YOUR CONTRIBUTION</span>
            <span className={styles.contributionValueUserValue}>{userContribution + ' USDC'}</span>
          </div>
           <div className={styles.contributionValueRow}>
            <span className={styles.contributionValueRowLabel}>TOTAL CONTRIBUTION</span>
            <span className={styles.contributionValueUserValue}>{saleStageInfo?.value.toLocaleString() + ' USDC'}</span>
          </div>
{/*          <div className={styles.progressBarContainer}>
            <div
              className={styles.progressBarLabel}>{`${totalContributedUSDC.toLocaleString()} USDC CONTRIBUTED OF ${maxContributedUSDC.toLocaleString()} MAX`}</div>
            <ProgressBar percentage={(totalContributedUSDC / maxContributedUSDC) * 100} className={styles.progressBar}/>
          </div>*/}

        </div>

      </div>
      {hasValue(transactionTimedOutError.url) &&
        <div className={styles.transactionTimedOutError} onClick={() => openURL(transactionTimedOutError.url)}>
          <span className={styles.transactionTimedOutError}>{transactionTimedOutError.message}</span>
        </div>}
      {hasValue(errorMessage) && <div className={styles.contributionValueRow}>
        <span className={styles.contributionValueRowError}>{errorMessage}</span>
      </div>}
    </div>
  )

}

export default IdoPurchaseAllocation;
